import { Divider, Stack } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ICourse } from '../api/CourseApi';
import { DialogApi } from '../api/DialogApi';
import { DialogPrompt, DialogPromptApi } from '../api/DialogPromptApi';
import { LessonApi } from '../api/LessonApi';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';
import { DialogPromptComponentFactory } from './DialogPage';

interface SettingsPageProps {
    course: ICourse;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ course }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [dialogPromptList, setDialogPromptList] = React.useState<DialogPrompt[]>([]);

    const [promptInputComponent, setPromptInputComponent] = React.useState<React.ReactNode>(null);

    const chatBubbleContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        LessonApi.getAll(course.id)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to LessonApi.getAll() " + response.status)
            })
            .then(list => {
                const visibleList = list.filter(lesson => lesson.is_hidden === false);
                DialogApi.getAll(visibleList[0].id)
                    .then(response => {
                        if (response.status < 300) {
                            return response.data;
                        }
                        throw new Error("Unable to DialogApi.getAll() " + response.status)
                    })
                    .then(list => {
                        const visibleList = list.filter(dialog => dialog.is_hidden === false);
                        DialogPromptApi.getAll(visibleList[0].id)
                            .then(response => {
                                if (response.status < 300) {
                                    return response.data;
                                }
                                throw new Error("Unable to DialogPromptApi.getAll() " + response.status)

                            })
                            .then(list => {
                                const visibleList = list.filter(dialogPrompt => dialogPrompt.is_hidden === false);
                                setDialogPromptList(visibleList)
                                setCurrentDialogPromptIndex(1)
                            })
                            .catch(err => {
                                handleErrorMessage(err, setErrorMessage, history, location);
                            })
                    })
                    .catch(err => {
                        handleErrorMessage(err, setErrorMessage, history, location);
                    })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }, []);

    const [currentDialogPromptIndex, setCurrentDialogPromptIndex] = React.useState(1);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentDialogPromptIndex]);

    const setDialogPromptComplete = () => {
        setCurrentDialogPromptIndex(prevIndex => prevIndex + 1);
    }

    const addDialog = (dialogId: number) => {
        DialogPromptApi.getAll(dialogId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getAll() " + response.status)

            })
            .then(newList => {
                setDialogPromptList((prevList) => [...prevList, ...newList]);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }

    const selectTeacherIndex = dialogPromptList.findIndex(
        (dialogPrompt) => dialogPrompt.class_name == "DialogPromptSelectTeacherModel"
    );

    /** handle height */
    const innerStackRef = React.useRef<HTMLDivElement>(null);
    const [innerHeight, setInnerHeight] = React.useState(255); // Default minHeight

    // Effect to measure innerStack height and update the middle stack
    React.useEffect(() => {
        if (innerStackRef.current) {
            // console.log("innerStackRef.current.clientHeight = " + innerStackRef.current.clientHeight);
            const extraHeight = 250;
            const clientHeight = innerStackRef.current.clientHeight > 0 ? innerStackRef.current.clientHeight : 60;
            setInnerHeight(clientHeight + extraHeight);
        }
    }, [promptInputComponent]); // Re-run when the component updates

    return (
        <Stack spacing={2} sx={{ margin: '0 auto', pt: 2, width: '90%' }}>
            <div style={{ height: `calc(100vh - ${innerHeight}px)`, overflowY: 'auto' }}>
                {dialogPromptList.slice(0, currentDialogPromptIndex).map((dialogPrompt, index) => {
                    const showAvatar = index > selectTeacherIndex;
                    return DialogPromptComponentFactory(dialogPrompt, setDialogPromptComplete, setPromptInputComponent, chatBubbleContainerRef, showAvatar, true)
                })}
                <div ref={chatBubbleContainerRef} />
            </div>
            <Divider sx={{ my: '1em' }} />
            <div ref={innerStackRef} style={{
                marginBottom: '1em',
                display: 'flex',           // Use flexbox
                justifyContent: 'center',  // Center content horizontally
                alignItems: 'center',      // (Optional) Center content vertically if needed }}>
            }}>
                {promptInputComponent}
            </div>

        </Stack >
    );
};

export default SettingsPage;
