// ShareDialog.tsx
import { Box, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, RedditIcon, RedditShareButton, TumblrIcon, TumblrShareButton, TwitterIcon, TwitterShareButton, WeiboIcon, WeiboShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { ScoreApi } from "../api/ScoreApi";
import ShareLinkIcon from '../asset/icon-share-link.png';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';

interface ShareDialogProps {
    isOpen: boolean;
    onClose: () => void;
    sentenceScoreId: number;
}

const ShareDialog: React.FC<ShareDialogProps> = ({ isOpen, onClose, sentenceScoreId }: ShareDialogProps) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [shareUrl, setShareUrl] = React.useState('');

    React.useEffect(() => {
        getShareUrl(sentenceScoreId);
    }, [])

    // Function to get the share URL from ScoreApi
    const getShareUrl = async (sentenceScoreId: number) => {
        // You would typically make an API call here to get the URL
        ScoreApi.getScoreUrl(sentenceScoreId)
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Scoring audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                setShareUrl(response.data);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleCopyShareUrlToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            // Show feedback to the user that the text was copied.
            alert("Link copied to clipboard!"); // Consider using a more user-friendly feedback mechanism
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogContent>
                <Stack spacing={1}>
                    <Typography variant="h4">Share with Friends</Typography>
                    <Box sx={{ padding: 2 }}>You can share your progress with your friends!</Box>
                    <Stack direction="row" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <FacebookShareButton
                            url={shareUrl}
                            quote={'Listen to my chinese'}
                            hashtag={'#shihoueri'}
                        >
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <RedditShareButton
                            url={shareUrl}
                            title={'Listen to my chinese'}
                        >
                            <RedditIcon size={40} round={true} />
                        </RedditShareButton>
                        <TumblrShareButton
                            url={shareUrl}
                            title={'Listen to my chinese'}
                            tags={['shihoueri', 'chinese', 'languagelearning']}
                        >
                            <TumblrIcon size={40} round={true} />
                        </TumblrShareButton>
                        <TwitterShareButton
                            url={shareUrl}
                            title={'Listen to my chinese'}
                            hashtags={['shihoueri', 'chinese', 'languagelearning']}
                        >
                            <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>

                        <WeiboShareButton
                            url={shareUrl}
                            title={'Listen to my chinese'}
                        >
                            <WeiboIcon size={40} round={true} />
                        </WeiboShareButton>

                        <WhatsappShareButton
                            url={shareUrl}
                            title={'Listen to my chinese'}
                        >
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>

                        <IconButton aria-label="Link" onClick={handleCopyShareUrlToClipboard}>
                            <img src={ShareLinkIcon} alt="Link" style={{ maxWidth: '42px', maxHeight: '42px' }} />
                        </IconButton>
                    </Stack>                </Stack>
            </DialogContent>
            <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                {/* Close icon */}
            </IconButton>
        </Dialog>
    );
};

export default ShareDialog;
