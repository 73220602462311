import { Link, Typography } from '@mui/material';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { LoginApi } from '../api/LoginApi';
import background from '../asset/background-login.png';

export default function ValidateEmail() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code'); // Get the 'code' query parameter
    const [success, setSuccess] = React.useState(false);

    React.useEffect(() => {
        if (code != null) {
            LoginApi.validateEmail(code)
                .then(response => {
                    // check if the response was successful
                    setSuccess(response.status < 300)
                })
                .then(blob => {
                    setSuccess(true)
                })
                .catch(err => {
                    setSuccess(false)
                })
        }
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${background})`, // Replace 'path_to_your_image.png' with the actual path to your image
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'auto 100%',
                backgroundPosition: 'right',
                minHeight: 'calc(100vh - 107px)', // Set the container's minimum height to fill the entire viewport, minus menu
                width: '100vw', // Adjusted width to fill the entire viewport horizontally
                padding: 0,
            }}
        >
            <div
                style={{
                    width: '480px' // Set the fixed width
                }}
            >
                {success ? (<>
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: 'left', // Align the header text to the left
                            color: (theme) => theme.palette.primary.main,
                            mb: 4, // Add some margin at the bottom for spacing
                        }}
                    >
                        Valiation Successful
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'left', // Ensure the text is left-aligned
                            margin: 0, // Remove the default margin for consistency in styling
                            fontWeight: 400
                        }}
                    >
                        Your new email address has been fully verified.
                        Please{' '}<Link href="/login">Login</Link>{' '} to continue.
                    </Typography>
                </>) : (<>
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: 'left', // Align the header text to the left
                            color: (theme) => theme.palette.primary.main,
                            mb: 4, // Add some margin at the bottom for spacing
                        }}
                    >
                        Update Failed
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'left', // Ensure the text is left-aligned
                            margin: 0, // Remove the default margin for consistency in styling
                            fontWeight: 400
                        }}
                    >
                        Sorry, unable to validate email.
                        Please{' '}<Link href="/login">Login</Link>{' '} to update your information or resend the validation email.
                    </Typography>
                </>)}
            </div>
        </div>
    )
}
