import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { shuffle } from 'lodash'; // Importing the shuffle function from a library like Lodash
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ScoreGrade, sentenceScoreAllGood, sentenceScoreAnyFailed } from '../../Score';
import { DialogPromptImageSelectSpeak } from '../../api/DialogPromptApi';
import { ISentenceScore, ScoreApi } from '../../api/ScoreApi';
import { IVocabularyWord } from '../../api/VocabularyWordApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';
import { SentenceScoreMediumResult, SentenceScoreTryAgain, SentenceScoreTryAgainNo, SentenceScoreTryAgainYes, getAudioByText } from './DialogPromptComponent';

import { useLocation } from 'react-router-dom';
import { AudioApi } from '../../api/AudioApi';
import { CustomSentenceApi } from '../../api/CustomSentenceApi';
import AddSelfStudyBlueIcon from '../../asset/icon-add-self-study-blue.png';
import MicrophoneBlueIcon from '../../asset/icon-microphone-blue.png';
import RightArrowBlueIcon from '../../asset/icon-right-arrow-blue.png';
import SpeakerBlueIcon from '../../asset/icon-speaker-blue.svg';
import AudioWaveIcon from '../../asset/icons8-audio-wave.gif';
import { useKaiTiFontStyles } from '../../font/KaiTiFont';


interface DialogPromptImageSelectSpeakComponentProps {
    dialogPrompt: DialogPromptImageSelectSpeak;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

const DialogPromptImageSelectSpeakComponent: React.FC<DialogPromptImageSelectSpeakComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings = false }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const kaiTiFontClass = useKaiTiFontStyles();

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [chatBubbleTeacherChinesePromptDone, setChatBubbleTeacherChinesePromptDone] = React.useState(false);
    const [selectedVocabularyWord, setSelectedVocabularyWord] = React.useState<IVocabularyWord | undefined>(undefined)
    const [vocabularyWordImageDone, setVocabularyWordImageDone] = React.useState(false)
    const [chatBubbleStudentResponsePromptDone, setChatBubbleStudentResponsePromptDone] = React.useState(false);
    const [chatBubbleTeacherEnglishPromptDone, setChatBubbleTeacherEnglishPromptDone] = React.useState(false);

    const imageRef = React.useRef<HTMLImageElement>(null);

    const [answerList, setAnswerList] = React.useState<React.JSX.Element[]>([])

    const studentAvatar = localStorage.getItem('studentAvatar');
    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [teacherChinesePromptAudio, setTeacherChinesePromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [yesResponseAudio, setYesResponseAudio] = React.useState<Blob | null | undefined>(undefined);
    const [noResponseAudio, setNoResponseAudio] = React.useState<Blob | null | undefined>(undefined);

    const [sentenceScoreMediumResultAudio, setSentenceScoreMediumResultAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainAudio, setSentenceScoreTryAgainAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainYesAudio, setSentenceScoreTryAgainYesAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainNoAudio, setSentenceScoreTryAgainNoAudio] = React.useState<Blob | null | undefined>(undefined);


    const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
    let audioData: Blob | null = null;

    const [isRecording, setIsRecording] = React.useState(false);
    const [isScoring, setIsScoring] = React.useState(false);

    const [sentenceScoreList, setSentenceScoreList] = React.useState<ISentenceScore[]>([]);
    const [chatBubbleSentenceScoreDone, setChatBubbleSentenceScoreDone] = React.useState<boolean[]>([]);
    const [chatBubbleTeacherResponseDone, setChatBubbleTeacherResponseDone] = React.useState<boolean[]>([]);
    const [tryAgainClickedList, setTryAgainClickedList] = React.useState<(boolean | null)[]>([]);

    const [chatBubbleTryAgainDone, setChatBubbleTryAgainDone] = React.useState(false);
    const [chatBubbleContinueDone, setChatBubbleContinueDone] = React.useState(false);


    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.teacher_chinese_prompt, setTeacherChinesePromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.yes_response, setYesResponseAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.no_response, setNoResponseAudio)


        getAudioByText(history, setErrorMessage, SentenceScoreMediumResult, setSentenceScoreMediumResultAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgain, setSentenceScoreTryAgainAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgainYes, setSentenceScoreTryAgainYesAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgainNo, setSentenceScoreTryAgainNoAudio)

    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            if (dialogPrompt.teacher_chinese_prompt && dialogPrompt.teacher_chinese_prompt.trim().length > 0) {
                // do nothing, chatBubble below will set flag
            } else {
                setChatBubbleTeacherChinesePromptDone(true)
            }
        }
    }, [chatBubbleDone])

    React.useEffect(() => {
        if (chatBubbleTeacherChinesePromptDone) {


            const wordAnswerList = dialogPrompt.vocabulary_word_list.map((vocabularyWordOption, index) => (
                <Stack key={vocabularyWordOption.id} direction="row" justifyContent="space-evenly" alignItems="center" sx={{ height: '125px' }}>
                    {vocabularyWordOption.image_url ?
                        <img
                            src={vocabularyWordOption.image_url}
                            alt={vocabularyWordOption.word}
                            key={vocabularyWordOption.id}
                            onClick={() => handleAnswerClick(vocabularyWordOption.id)}
                            style={{ maxHeight: '125px', maxWidth: '125px', height: 'auto', width: 'auto', objectFit: 'contain' }}
                        />
                        :
                        <>{/* create empty child so foundAnswer.props.children[0] works */}</>
                    }
                </Stack>
            ));
            const shuffledWordAnswerList = shuffle(wordAnswerList.filter(Boolean)); // Shuffle the non-null components
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="space-evenly" spacing={2}>
                    {shuffledWordAnswerList}
                </Stack>
            )
            setAnswerList(shuffledWordAnswerList)
        }
    }, [chatBubbleTeacherChinesePromptDone])

    React.useEffect(() => {
        // scroll after image answers have been rendered
        if (answerList && answerList.length > 0) {
            // use requestAnimationFrame or MutationObserver here
            requestAnimationFrame(() => {
                if (chatBubbleContainerRef.current) {
                    chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
        }
    }, [answerList])

    const handleAnswerClick = (vocabularyWordId: number) => {
        const vocabularyWord = dialogPrompt.vocabulary_word_list.find(word => word.id === vocabularyWordId);
        setSelectedVocabularyWord(vocabularyWord);

    }

    React.useEffect(() => {
        if (selectedVocabularyWord) {
            // make sure to scroll after the image is loaded.
            const handleImageLoad = () => {
                if (chatBubbleContainerRef.current) {
                    chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                setVocabularyWordImageDone(true)
            };

            if (imageRef.current) {
                if (imageRef.current.complete && imageRef.current.naturalHeight !== 0) {
                    // If image is already loaded
                    handleImageLoad();
                } else {
                    // Image is not yet loaded, attach event listener
                    imageRef.current.addEventListener('load', handleImageLoad);
                }
            }

            return () => {
                if (imageRef.current) {
                    imageRef.current.removeEventListener('load', handleImageLoad);
                }
            };
        }
    }, [selectedVocabularyWord])

    React.useEffect(() => {
        if (selectedVocabularyWord && vocabularyWordImageDone) {
            if (selectedVocabularyWord.student_response_prompt && selectedVocabularyWord.student_response_prompt.trim().length > 0 && selectedVocabularyWord.student_response_prompt != "<Answer/>") {
                // do nothing, chatBubble below will set flag
            } else {
                setChatBubbleStudentResponsePromptDone(true)
            }
        }

    }, [vocabularyWordImageDone])

    React.useEffect(() => {
        if (selectedVocabularyWord && chatBubbleStudentResponsePromptDone) {
            if (selectedVocabularyWord.teacher_chinese_prompt && selectedVocabularyWord.teacher_chinese_prompt.trim().length > 0) {
                // do nothing, chatBubble below will set flag
            } else {
                setChatBubbleTeacherEnglishPromptDone(true)
            }
        }

    }, [chatBubbleStudentResponsePromptDone])


    const handleAnswerSentenceSpeakerClick = () => {
        if (selectedVocabularyWord) {
            const audioPromise = AudioApi.getTeacherSentence(selectedVocabularyWord.audio_sentence_id)

            audioPromise
                .then(response => {
                    // check if the response was successful
                    if (response.status >= 300) {
                        throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                    }
                    return response.data;
                })
                .then(blob => {
                    // create a new Audio object and set the source to the blob URL
                    const audio = new Audio(URL.createObjectURL(blob));
                    audio.play();
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history, location);
                })
        }

    };


    /**
     * speaking stuff
     */
    React.useEffect(() => {
        if (chatBubbleStudentResponsePromptDone && chatBubbleTryAgainDone == false) {
            if (sentenceScoreList.length == 0 || sentenceScoreList[sentenceScoreList.length - 1].passing_grade == ScoreGrade.Bad || tryAgainClickedList[sentenceScoreList.length - 1]) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        {/* Left-aligned X icon */}
                        <IconButton onClick={handleCancelClick}>
                            <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                        </IconButton>
                        <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center', flexGrow: 1 }} spacing={2}>
                            {/* <IconButton onClick={handleSpeakerClick}>
                                <VolumeUpRoundedIcon fontSize="large" />
                            </IconButton> */}
                            <IconButton onClick={isRecording ? stopRecording : startRecording}>
                                {isRecording ? <img src={AudioWaveIcon} alt="Recording" style={{ width: '32px', height: '32px' }} />
                                    : isScoring ? <CircularProgress size={24} sx={{ width: '24px', height: '24px' }} />
                                        : <img src={MicrophoneBlueIcon} style={{ width: '36px', height: '36px' }} />}
                            </IconButton>
                        </Stack>
                        {selectedVocabularyWord && selectedVocabularyWord.audio_sentence_id >= 0 && (
                            <Tooltip title="Add to Pronunciation" arrow>
                                <IconButton onClick={handleAddSentenceClick} sx={{ alignSelf: 'center' }}>
                                    <img src={AddSelfStudyBlueIcon} style={{ width: '36px', height: '36px' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                )
            }
        }
    }, [chatBubbleStudentResponsePromptDone, sentenceScoreList, isRecording, isScoring, chatBubbleTryAgainDone, tryAgainClickedList]);


    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                // console.log("startRecording() 1")
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.addEventListener('dataavailable', handleAudioDataAvailable);
                mediaRecorderRef.current.addEventListener('stop', handleAudioDone);

                mediaRecorderRef.current.start();

                setIsRecording(true);
                // console.log("startRecording() 2")
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            });
    };
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            // console.log("stopRecording() 1")
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.removeEventListener('dataavailable', handleAudioDataAvailable);
            mediaRecorderRef.current.removeEventListener('stop', handleAudioDone);
            // console.log("stopRecording() 2")
        }

        setIsRecording(false);
    };

    const handleAudioDataAvailable = (event: BlobEvent) => {
        // console.log("handleAudioDataAvailable() 1")
        if (event.data.size > 0) {
            // console.log("event.data.size > 0")
            audioData = event.data;
        }
    };

    const handleAudioDone = () => {
        // console.log("handleAudioDone() 1")
        if (audioData && selectedVocabularyWord) {
            // console.log("handleAudioDone() has audio data 1")
            setIsScoring(true)
            const formData = new FormData();
            formData.append('audio', audioData);

            ScoreApi.getScore(selectedVocabularyWord.audio_sentence_id, formData)
                .then(response => {
                    setIsScoring(false)
                    // check if the response was successful
                    if (response.status >= 300) {
                        throw new Error('Scoring audio was not ok statuscode=' + response.status + " " + response.statusText);
                    }
                    return response.data;
                })
                .then(responseJson => {
                    responseJson.audioData = audioData;
                    setSentenceScoreList((previous) => [...previous, responseJson]);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history, location);
                })
        }
    }


    React.useEffect(() => {
        if (chatBubbleTryAgainDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {/* Left-aligned X icon */}
                    <IconButton onClick={handleCancelClick}>
                        <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>

                    {/* Center-aligned buttons */}
                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }} spacing={2}>
                        <Button variant="contained" onClick={handleNextWordClick} className="secondaryButton" >
                            No, Continue On
                        </Button>
                        <Button variant="contained" onClick={handleTryAgainClick} >
                            Yes, Try Again
                        </Button>
                    </Stack>
                </Stack>

            )
        }
    }, [chatBubbleTryAgainDone]);

    const handleNextWordClick = () => {
        setChatBubbleTryAgainDone(false)
        updateTryAgainClickedList(false)
    }
    const handleTryAgainClick = () => {
        setChatBubbleTryAgainDone(false)
        updateTryAgainClickedList(true)
    }

    const updateTryAgainClickedList = (tryAgain: boolean) => {
        const index = sentenceScoreList.length - 1;
        setTryAgainClickedList(previous => {
            const updatedArray = [...previous];
            updatedArray[index] = tryAgain;
            return updatedArray;
        })
    }

    /**
     * cancel dialog
     */

    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

    const handleCancelClick = () => {
        setOpenCancelDialog(true);
    };

    const handleCancelDialogConfirm = () => {
        setOpenCancelDialog(false);
        setChatBubbleContinueDone(true);
    };

    const handleCancelDialogCancel = () => {
        setOpenCancelDialog(false);
    };

    React.useEffect(() => {
        if (chatBubbleContinueDone) {
            setPromptInputComponent(<div />)
            setDialogPromptComplete(dialogPrompt.id)

        }
    }, [chatBubbleContinueDone])


    /**
     * add custom sentence
     */


    const [addSentenceDialogOpen, setAddSentenceDialogOpen] = React.useState(false);
    const [addSentenceSuccessMessage, setAddSentenceSuccessMessage] = React.useState('');

    const handleAddSentenceClick = () => {
        setAddSentenceDialogOpen(true);
    };

    const handleAddSentenceConfirm = () => {
        if (selectedVocabularyWord) {
            const sentenceId = selectedVocabularyWord.audio_sentence_id
            CustomSentenceApi.createBySentenceId(sentenceId)
                .then(response => {
                    if (response.status < 300) {
                        return response.data;
                    }
                    throw new Error('Unable to create sentence statuscode=' + response.status + " " + response.statusText);
                })
                .then(list => {
                    // Update success message
                    setAddSentenceSuccessMessage('Sentence added successfully!');
                    // Don't Close the dialog, allow user to see success message
                    // setAddSentenceDialogOpen(false);
                })
                .catch(err => {
                    if (err.response.status == 490) {
                        setAddSentenceSuccessMessage("Please validate your email before adding sentences. You can validate your email in your profile.");
                    } else if (err.response.status == 491) {
                        setAddSentenceSuccessMessage(err.response['data']);
                    } else if (err.response.status == 492) {
                        setAddSentenceSuccessMessage("This user is read only.");
                    } else {
                        console.error(err);
                        setAddSentenceSuccessMessage(err.response.data.message);
                    }
                })
        }

    };

    const handleAddSentenceClose = () => {
        setAddSentenceDialogOpen(false);
        setAddSentenceSuccessMessage('')
    };

    return (
        <Stack spacing={2} sx={{ mx: 2 }}>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                messageAudio={promptAudio ? promptAudio : null}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {/* {componentList.map((component, index) => (
                <Box key={index} component="div">
                    {component}
                </Box>
            ))} */}
            {chatBubbleDone &&
                <ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.teacher_chinese_prompt}
                    messageAudio={teacherChinesePromptAudio ? teacherChinesePromptAudio : null}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleTeacherChinesePromptDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            }
            {selectedVocabularyWord != null &&
                <img
                    ref={imageRef}
                    src={selectedVocabularyWord.image ? selectedVocabularyWord.image : (selectedVocabularyWord.image_url ? selectedVocabularyWord.image_url : "broken")}
                    alt={selectedVocabularyWord.word}
                    style={{
                        maxHeight: '400px', maxWidth: '400px',
                        height: 'auto', width: 'auto',
                        margin: '0 auto', // Add this line to center the image horizontally
                        display: 'block', // Add this line to ensure the margin works correctly
                    }}
                />
            }
            {selectedVocabularyWord != null && vocabularyWordImageDone && selectedVocabularyWord.student_response_prompt != "<Answer/>" &&
                <ChatBubble
                    isTeacher={false}
                    message={selectedVocabularyWord.student_response_prompt}
                    // messageAudio={teacherChinesePromptAudio ? teacherChinesePromptAudio : null}
                    messageAudio={null}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleStudentResponsePromptDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            }
            {selectedVocabularyWord != null && chatBubbleStudentResponsePromptDone &&
                <ChatBubble
                    isTeacher={true}
                    message={selectedVocabularyWord.teacher_english_prompt}
                    // messageAudio={teacherChinesePromptAudio ? teacherChinesePromptAudio : null}
                    messageAudio={null}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleTeacherEnglishPromptDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            }
            {selectedVocabularyWord != null && chatBubbleTeacherChinesePromptDone &&
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: 2 }}>
                    <Stack direction="row" alignItems="center" justifyContent="center" flexWrap="wrap" className="border-dark" style={{ marginBottom: '10px' }}
                        sx={{ pt: 1, pb: 1, pl: 2, pr: 3, display: 'inline-flex', width: '90%' }}>
                        <IconButton onClick={handleAnswerSentenceSpeakerClick}>
                            <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px', marginRight: '8px' }} />
                        </IconButton>
                        {/* <div className={kaiTiFontClass.customFont} style={{ fontSize: '48px', margin: 0, padding: 0, lineHeight: 0.9 }} >
                            <span>{selectedVocabularyWord.audio_sentence}</span>
                        </div> */}
                        {selectedVocabularyWord.audio_sentence_pinyin && selectedVocabularyWord.audio_sentence_pinyin.map((pinyinElement, index) => (
                            <Stack key={index} spacing={1} alignItems="center">
                                <span
                                    style={{ marginLeft: '0.5em', marginRight: '0.5em', fontSize: '32px' }}>
                                    {pinyinElement}
                                </span>
                                <div className={kaiTiFontClass.customFont} style={{ fontSize: '48px', margin: 0, padding: 0, lineHeight: 0.9 }} >
                                    <span>{selectedVocabularyWord.audio_sentence[index]}</span>
                                </div>
                            </Stack>
                        ))}

                    </Stack>
                </Box>
            }
            {sentenceScoreList.map((sentenceScore, index) => (
                <div key={index}>
                    <ChatBubble
                        isTeacher={false}
                        message={sentenceScore.text}
                        messageAudio={null} // audio will be found through sentenceScore
                        sentenceScore={sentenceScore}
                        showAvatar={showAvatar}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                        setChatBubbleDone={(done) => setChatBubbleSentenceScoreDone(previous => {
                            console.log("sentene score updated index " + index)
                            const updatedArray = [...previous];
                            updatedArray[index] = done;
                            return updatedArray;
                        })}
                    />
                    {chatBubbleSentenceScoreDone[index] && (<>
                        {sentenceScoreAllGood(sentenceScore) ? (
                            <ChatBubble
                                isTeacher={true}
                                message={dialogPrompt.yes_response}
                                messageAudio={yesResponseAudio ? yesResponseAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleContinueDone}
                            />
                        ) : sentenceScoreAnyFailed(sentenceScore) ? (
                            <ChatBubble isTeacher={dialogPrompt.is_teacher}
                                message={dialogPrompt.no_response}
                                messageAudio={noResponseAudio ? noResponseAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={(done) => {
                                    setChatBubbleTryAgainDone(false);
                                    // set null, to skip this index
                                    setTryAgainClickedList((previous) => [...previous, null]);
                                }}
                            />
                        ) : (
                            <ChatBubble isTeacher={dialogPrompt.is_teacher}
                                message={SentenceScoreMediumResult}
                                messageAudio={sentenceScoreMediumResultAudio ? sentenceScoreMediumResultAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={(done) => setChatBubbleTeacherResponseDone(previous => {
                                    const updatedArray = [...previous];
                                    updatedArray[index] = done;
                                    return updatedArray;
                                })}
                            />
                        )}
                        {chatBubbleTeacherResponseDone[index] && (
                            <ChatBubble
                                isTeacher={true}
                                message={SentenceScoreTryAgain}
                                messageAudio={sentenceScoreTryAgainAudio ? sentenceScoreTryAgainAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleTryAgainDone}
                            />
                        )}
                        {tryAgainClickedList[index] === true && (
                            <ChatBubble
                                isTeacher={false}
                                message={SentenceScoreTryAgainYes}
                                messageAudio={null} // no audio for student
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                            />
                        )}
                        {tryAgainClickedList[index] === false && (
                            <ChatBubble
                                isTeacher={false}
                                message={SentenceScoreTryAgainNo}
                                messageAudio={null} // no audio for student
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleContinueDone}
                            />
                        )}
                    </>)}
                </div>
            ))}
            {/* Confirmation Dialog */}
            <Dialog open={openCancelDialog} onClose={handleCancelDialogCancel}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirm Skip</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Are you sure you want to skip this exercise?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleCancelDialogCancel} color="primary" sx={{ color: '#787878' }}>
                        Keep trying
                    </Button>
                    <Button onClick={handleCancelDialogConfirm} color="primary" sx={{ color: '#787878' }}>
                        Skip
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Add Sentence Confirmation Dialog */}
            <Dialog open={addSentenceDialogOpen} onClose={handleAddSentenceClose}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirmation</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Do you want to add this sentence to Self-Study?
                        <p>{selectedVocabularyWord ? selectedVocabularyWord.audio_sentence : ""}</p>
                    </DialogContentText>
                    {/* Display success message inside the Dialog */}
                    {addSentenceSuccessMessage && <p>{addSentenceSuccessMessage}</p>}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleAddSentenceClose} color="primary" sx={{ color: '#787878' }}>
                        {addSentenceSuccessMessage.length == 0 ? "Cancel" : "Close"}
                    </Button>
                    <Button onClick={handleAddSentenceConfirm} color="primary" disabled={addSentenceSuccessMessage.length > 0} sx={{ color: '#787878' }}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default DialogPromptImageSelectSpeakComponent;
