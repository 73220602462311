import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CustomSentenceApi, ICustomSentence } from "../api/CustomSentenceApi";
import AddIcon from '../asset/icon-add-black.png';
import ChatIcon from '../asset/icon-chat.png';
import DeleteIcon from '../asset/icon-delete.png';
import CustomSentenceListContext from "../context/CustomSentenceListContext";
import ErrorMessageContext, { handleErrorMessage } from "../context/ErrorMessageContext";
import SelectedCustomSentenceContext from "../context/SelectedCustomSentenceContext";

export default function CustomSentenceMenu() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const { selectedSentence, setSelectedSentence } = React.useContext(SelectedCustomSentenceContext)
    const { customSentenceList, setCustomSentenceList } = React.useContext(CustomSentenceListContext)

    const [openDialog, setOpenDialog] = React.useState(false);
    const [deleteSentence, setDeleteSentence] = React.useState<ICustomSentence | null>(null);

    // Function to refresh CustomSentenceList
    const refreshCustomSentenceList = () => {

        const inProcessingQueue = customSentenceList.some(sentence => sentence.processing_queue && sentence.processing_queue > 0);

        if (inProcessingQueue) {
            CustomSentenceApi.getAll()
                .then(response => {
                    if (response.status < 300) {
                        return response.data;
                    }
                    console.error(response)
                    handleErrorMessage(response, setErrorMessage, history, location);
                    return [];
                })
                .then(list => {
                    setCustomSentenceList(list);
                })
        }
    };

    // Use a ref to store the timeoutId for clearing it later if needed
    const timeoutIdRef = React.useRef<number | null>(null);

    React.useEffect(() => {
        // Clear any existing timeout to avoid multiple calls
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        // Set a new timeout when customSentenceList changes
        timeoutIdRef.current = setTimeout(() => {
            refreshCustomSentenceList();
        }, 30000); // 30000 ms = 30 seconds

        // Cleanup on unmount or when customSentenceList changes again before the timeout finishes
        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [customSentenceList]);

    const handleDelete = async (id: number) => {
        CustomSentenceApi.delete(id)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return []
            })
            .then(customSentence => {
                CustomSentenceApi.getAll().then(response => {
                    setCustomSentenceList(response.data)
                })
            })
            .catch(err => {
                if (err.response.status == 492) {
                    setErrorMessage("This user is read only and cannot delete.");
                } else {
                    handleErrorMessage(err, setErrorMessage, history, location);
                }
            })
    };

    const handleListSelect = (sentence: ICustomSentence) => {
        setSelectedSentence(sentence)
    }

    const handleAddSentence = () => {
        setSelectedSentence({ id: -1, text: 'AddSentence' })
    }

    const handleOpenDialog = (sentence: ICustomSentence) => {
        setDeleteSentence(sentence);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const confirmDelete = async () => {
        if (deleteSentence !== null) {
            await handleDelete(deleteSentence.id);
            handleCloseDialog(); // Close the dialog after deletion
        }
    };

    return (
        <Box>
            <Box display="flex">
                <Typography variant="h2" color="secondary" sx={{ pt: 1, pb: 1 }}>
                    Prior Sentences
                </Typography>
            </Box>
            <div style={{ height: "calc(100vh - 224px)", overflowY: "auto" }}>
                <Box display="flex" flexDirection="column">
                    <List>
                        {customSentenceList.map((sentence) => (
                            <ListItem
                                key={sentence.id}
                                sx={{ p: 0, my: 2, width: "100%", maxWidth: "600px" }}
                                secondaryAction={
                                    <div onClick={() => handleOpenDialog(sentence)}>
                                        <IconButton edge="end" aria-label="delete">
                                            <img src={DeleteIcon} alt="Delete" style={{ width: "24px", height: "24px" }} />
                                        </IconButton>
                                    </div>
                                }
                            >
                                <ListItemButton sx={{ p: 0 }} onClick={() => handleListSelect(sentence)}>
                                    <ListItemIcon>
                                        <img src={ChatIcon} alt="Chat" style={{ width: "24px", height: "24px" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Box display="flex" alignItems="center">
                                                <span>{sentence.text}</span>
                                                {sentence.processing_queue && sentence.processing_queue > 0 && (
                                                    <Tooltip title={`Sentence ${sentence.processing_queue} in queue`}>
                                                        <Badge
                                                            badgeContent={sentence.processing_queue}
                                                            max={999}
                                                            color="secondary"
                                                            sx={{
                                                                ".MuiBadge-badge": {
                                                                    backgroundColor: "#FFE299",
                                                                    color: "#2A2459",
                                                                },
                                                                marginLeft: 3,
                                                            }}
                                                        >
                                                            <div></div>
                                                        </Badge>
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
            <Button
                variant="contained"
                sx={{ width: "100%", p: "12px", mb: 2, mt: 2 }}
                className="secondaryButton"
                onClick={handleAddSentence}
            >
                <img src={AddIcon} alt="Chat" style={{ width: "24px", height: "24px", marginRight: "16px" }} />
                Add a new sentence
            </Button>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h4" sx={{ color: "#383838" }}>
                        {"Delete Sentence?"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: "#8D8D8D" }}>
                        Are you sure you want to delete this sentence?<br />
                        {deleteSentence && deleteSentence.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between", "& .MuiButton-root": { flex: 1 } }}>
                    <Button onClick={handleCloseDialog} sx={{ color: "#787878" }}>
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} autoFocus variant="contained" color="error" sx={{ color: "#fff7f7" }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}