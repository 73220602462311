import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CourseApi, ICourse } from '../../api/CourseApi';
import { DialogApi, IDialog } from '../../api/DialogApi';
import { DialogPrompt, DialogPromptApi, DialogPromptConversationListen, DialogPromptFactory, DialogPromptImage, DialogPromptIntroduceWord, DialogPromptLearnWithCharacter, DialogPromptListenSpeak, DialogPromptListenSpeakNoGrade, DialogPromptMatchWordPicture, DialogPromptScoringLevel } from '../../api/DialogPromptApi';
import { DialogPromptConversationApi, IDialogPromptConversation } from '../../api/DialogPromptConversationApi';
import { ILesson, LessonApi } from '../../api/LessonApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import { DialogPromptConversationListenForm } from './DialogPromptConversationListenForm';
import { DialogPromptConversationRelatedForm } from './DialogPromptConversationRelatedForm';
import { DialogPromptImageSelectSpeakForm } from './DialogPromptImageSelectSpeakForm';
import { DialogPromptMatchWordPictureForm, arrayBufferToBase64 } from './DialogPromptMatchWordPictureForm';
import { DialogPromptQuizQuestionAudioForm } from './DialogPromptQuizQuestionAudioForm';
import { DialogPromptQuizQuestionForm } from './DialogPromptQuizQuestionForm';
import { DialogPromptQuizWordForm } from './DialogPromptQuizWordForm';
import { DialogPromptSelectNextDialogForm } from './DialogPromptSelectNextDialogForm';
import { DialogPromptSpeakNoGradeForm } from './DialogPromptSpeakNoGradeForm';
import { DialogPromptSpeakWithGradeForm } from './DialogPromptSpeakWithGradeForm';
import { DialogPromptTypeSpeakForm } from './DialogPromptTypeSpeakForm';

interface DialogPromptFormProps {
    dialogPromptId: number;
    onSubmit: (updatedDialogPrompt: DialogPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete: (dialogPromptId: number) => void;
}

export function dialogPromptClassNameContainsProp(className: string, prop: string) {
    if (className == null || className.length <= 0) return false;
    // don't need to include all fields, just getting DialogPrompt object
    const selectedDialogPrompt = DialogPromptFactory(
        className,
        -1,
        -1,
        -1,
        true,
        "ERROR-dialogPromptClassNameContainsProp",
        true, false);
    return prop in selectedDialogPrompt
}

export function getClassNameDescription(className: String) {
    if (className === "DialogPromptModel") return "Dialog Only";
    if (className === "DialogPromptImageModel") return "Dialog w/ Image";
    if (className === "DialogPromptIntroduceWordModel") return "Dialog w/ Character Listen";
    if (className === "DialogPromptListenSpeakModel") return "Dialog w/ Listen & Speak";
    if (className === "DialogPromptListenSpeakNoGradeModel") return "Dialog w/ Listen & Speak Anything, No Grade";
    if (className === "DialogPromptImageSelectSpeakModel") return "Dialog w/ Image Select & Speak";

    if (className === "DialogPromptQuizWordModel") return "Quiz: Character Recognition";
    if (className === "DialogPromptMatchWordPictureModel") return "Quiz: Matching on Vocabulary";
    if (className === "DialogPromptQuizQuestionModel") return "Quiz: Select One";
    if (className === "DialogPromptQuizQuestionAudioModel") return "Quiz: Select One Audio";
    if (className === "DialogPromptTypeSpeakModel") return "Prompt, Type, Speak";
    if (className === "DialogPromptSpeakNoGradeModel") return "Prompt, Speak Anything, No Grade";
    if (className === "DialogPromptSpeakWithGradeModel") return "Prompt, Speak With Grade";

    if (className === "DialogPromptConversationListenModel") return "Conversation: Listen";
    if (className === "DialogPromptConversationListenWithoutCharacterModel") return "Conversation: Listen, without Character";
    if (className === "DialogPromptConversationListenSpeakModel") return "Conversation: Listen then Speak";
    if (className === "DialogPromptConversationReadSpeakModel") return "Conversation: Read then Speak";
    if (className === "DialogPromptConversationReadWithBlankSpeakModel") return "Conversation: Read w/ Blank then Speak";
    if (className === "DialogPromptConversationSpeakOnlyModel") return "Conversation: Speak Only";

    if (className === "DialogPromptSelectNextDialogModel") return "Select next dialog";
    if (className === "DialogPromptStartNextLessonModel") return "Start next lesson";

    if (className === "DialogPromptSelectLengthTimeModel") return "Enter length of time";
    if (className === "DialogPromptEnterNameModel") return "Enter Name";
    if (className === "DialogPromptSelectAvatarModel") return "Select Avatar";
    if (className === "DialogPromptSelectTeacherModel") return "Select Teacher";
    if (className === "DialogPromptLearnWithCharacterModel") return "Learn Characters";
    if (className === "DialogPromptScoringLevelModel") return "Pronunciation Scoring Level";
    if (className === "DialogPromptStartClassModel") return "Start First Lesson";

    throw new Error("Unknown class name : " + className);
}

const DialogPromptForm: React.FC<DialogPromptFormProps> = ({ dialogPromptId: oldDialogPromptId, onSubmit, onDelete }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    // Initial values reset by useEffect below
    const [id, setId] = React.useState(-1);
    const [dialogId, setDialogId] = React.useState(-1);
    const [courseDictionary, setCourseDictionary] = React.useState<{ [key: number]: ICourse; }>({}); // State to store course options
    const [lessonDictionary, setLessonDictionary] = React.useState<{ [key: number]: ILesson; }>({});
    const [dialogList, setDialogList] = React.useState<IDialog[]>([]);

    const [className, setClassName] = React.useState('');
    const [isTeacher, setIsTeacher] = React.useState(true);
    const [prompt, setPrompt] = React.useState('');
    const [isNoteToStudent, setIsNoteToStudent] = React.useState(Boolean);
    const [isHidden, setIsHidden] = React.useState(false);
    const [orderIndex, setOrderIndex] = React.useState(-1);

    const [imageSource, setImageSource] = React.useState("");
    const [imageError, setImageError] = React.useState(false);
    const [imageSourceUrl, setImageSourceUrl] = React.useState("");

    const [newWord, setNewWord] = React.useState("");
    const [yesButtonText, setYesButtonText] = React.useState("");
    const [noButtonText, setNoButtonText] = React.useState("");
    const [maybeButtonText, setMaybeButtonText] = React.useState("");
    const [yesResponse, setYesResponse] = React.useState("");
    const [noResponse, setNoResponse] = React.useState("");
    const [maybeResponse, setMaybeResponse] = React.useState("");

    const [newDialogPrompt, setNewDialogPrompt] = React.useState<DialogPrompt | null>(null);
    const [newDialogPromptConversation, setNewDialogPromptConversation] = React.useState<IDialogPromptConversation | null>(null);

    const [sentenceId, setSentenceId] = React.useState(-1)

    const [isSaving, setIsSaving] = React.useState(false); // Add saving state

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    React.useEffect(() => {
        const fetchCourseList = async () => {
            try {
                const response = await CourseApi.getAll();
                const courseList = response.data

                const courseDictionary = courseList.reduce((accumulator, currentCourse) => {
                    accumulator[currentCourse.id] = currentCourse;
                    return accumulator;
                }, {} as { [key: number]: typeof courseList[number] }); // Using "typeof courseList[number]" to infer course type
                setCourseDictionary(courseDictionary)

            } catch (err: any) {
                handleErrorMessage(err, setErrorMessage, history, location);
            }
        };
        const fetchLessonList = async () => {
            try {
                const response = await LessonApi.getAll();
                const lessonList = response.data;

                const lessonDictionary = lessonList.reduce((accumulator, currentCourse) => {
                    accumulator[currentCourse.id] = currentCourse;
                    return accumulator;
                }, {} as { [key: number]: typeof lessonList[number] }); // Using "typeof courseList[number]" to infer course type
                setLessonDictionary(lessonDictionary)
            } catch (err: any) {
                handleErrorMessage(err, setErrorMessage, history, location);
            }
        };
        const fetchDialogList = async () => {
            try {
                const response = await DialogApi.getAll();
                setDialogList(response.data);
            } catch (err: any) {
                handleErrorMessage(err, setErrorMessage, history, location);
            }
        };
        fetchCourseList();
        fetchLessonList();
        fetchDialogList();
    }, []);

    React.useEffect(() => {
        setImageError(false)
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
        DialogPromptApi.getById(oldDialogPromptId)
            .then((response) => {
                const oldDialogPrompt = response.data;
                setDialogPromptFields(oldDialogPrompt);
                // const dialogPromptInQueue = dialogPromptUpdateQueue.some((dialogPrompt) => dialogPrompt.dialogPrompt.id === oldDialogPromptId);
                // setIsSaving(dialogPromptInQueue)
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt)
        //     setIsSaving(true)
        // }
    }, [oldDialogPromptId])

    function setDialogPromptFields(dialogPrompt: DialogPrompt) {
        setId(dialogPrompt.id);
        setDialogId(dialogPrompt.dialog_id);
        setClassName(dialogPrompt.class_name);
        setIsTeacher(dialogPrompt.is_teacher);
        setPrompt(dialogPrompt.prompt);
        setIsNoteToStudent(dialogPrompt.is_note_to_student ? dialogPrompt.is_note_to_student : false);
        setIsHidden(dialogPrompt.is_hidden);
        setOrderIndex(dialogPrompt.order_index);

        if ("image_src" in dialogPrompt || "image_src_url" in dialogPrompt) {
            const dialogPromptImage = dialogPrompt as DialogPromptImage;
            if (dialogPromptImage.image_src) {
                setImageSource(dialogPromptImage.image_src);
            } else {
                setImageSource("");
            }
            if (dialogPromptImage.image_src_url) {
                setImageSourceUrl(dialogPromptImage.image_src_url);
            } else {
                setImageSourceUrl("")
            }
        } else {
            setImageSource("");
            setImageSourceUrl("")
        }
        setImageError(false)
        if ("new_word" in dialogPrompt) {
            setNewWord((dialogPrompt as DialogPromptIntroduceWord).new_word);
        } else {
            setNewWord("")
        }
        if ("yes_button_text" in dialogPrompt) {
            setYesButtonText((dialogPrompt as DialogPromptLearnWithCharacter).yes_button_text)
            setNoButtonText((dialogPrompt as DialogPromptLearnWithCharacter).no_button_text)
        } else {
            setYesButtonText("")
            setNoButtonText("")
        }
        if ("maybe_button_text" in dialogPrompt) {
            setMaybeButtonText((dialogPrompt as DialogPromptScoringLevel).maybe_button_text)
        } else {
            setMaybeButtonText("")
        }
        if ("yes_response" in dialogPrompt) {
            setYesResponse((dialogPrompt as DialogPromptListenSpeakNoGrade).yes_response)
        } else {
            setYesResponse("")
        }
        if ("no_response" in dialogPrompt) {
            setNoResponse((dialogPrompt as DialogPromptListenSpeak).no_response)
        } else {
            setNoResponse("")
        }
        if ("maybe_response" in dialogPrompt) {
            setMaybeResponse((dialogPrompt as DialogPromptScoringLevel).maybe_response)
        } else {
            setMaybeResponse("")
        }
        if ("sentence_id" in dialogPrompt) {
            setSentenceId((dialogPrompt as DialogPromptIntroduceWord).sentence_id)
        } else {
            setSentenceId(-1)
        }
        setIsSaving(false)
        setShowConfirmation(false)
    }

    // React.useEffect(() => {
    //     // update isSaving after anything changes with queue
    //     const foundIndex = dialogPromptUpdateQueue.findIndex((tuple) => tuple.dialogPrompt.id === oldDialogPromptId);
    //     setIsSaving(foundIndex >= 0)

    // }, [dialogPromptUpdateQueue]);

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => { setId(Number(e.target.value)); };
    const handleDialogIdChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDialogId(Number(e.target.value)); };
    const handleClassNameChange = (e: SelectChangeEvent<string>) => { setClassName(e.target.value); };
    const handleIsTeacherChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsTeacher(e.target.value === 'true'); };
    const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setPrompt(e.target.value); };
    const handleIsNoteToStudentChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsNoteToStudent(e.target.checked); };
    const handleHiddenChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsHidden(e.target.checked); };
    const handleOrderIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => { setOrderIndex(Number(e.target.value)); };
    const handleNewWordChange = (e: React.ChangeEvent<HTMLInputElement>) => { setNewWord(e.target.value); };

    const handleYesButtonTextChange = (e: React.ChangeEvent<HTMLInputElement>) => { setYesButtonText(e.target.value); };
    const handleNoButtonTextChange = (e: React.ChangeEvent<HTMLInputElement>) => { setNoButtonText(e.target.value); };
    const handleMaybeButtonTextChange = (e: React.ChangeEvent<HTMLInputElement>) => { setMaybeButtonText(e.target.value); };
    const handleYesResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setYesResponse(e.target.value); };
    const handleNoResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setNoResponse(e.target.value); };
    const handleMaybeResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setMaybeResponse(e.target.value); };

    const handleSentenceIdChange = (e: React.ChangeEvent<HTMLInputElement>) => { setSentenceId(Number(e.target.value)); };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSaving(true); // Set saving state to true

        if (className == "DialogPromptImageModel" && dialogPromptClassNameContainsProp(className, "image_src")) {
            // If the image is empty, set the image error state to true
            if (imageSource.trim() === "" || imageSource.trim() === "broken") {
                if (imageSourceUrl.trim() === "" || imageSourceUrl.trim() === "broken") {
                    setImageError(true);
                    setIsSaving(false); // Set saving state to false
                    return; // Don't proceed with form submission
                }
            }
        }

        if (className == "DialogPromptMatchWordPictureModel") {
            if (newDialogPrompt != null) {
                const vocabList = (newDialogPrompt as DialogPromptMatchWordPicture).vocabulary_word_list;
                const vocabMissingImage = vocabList.some((vocab) => vocab.image == null || vocab.image == '');
                if (vocabMissingImage) {
                    setIsSaving(false);
                    return;
                }
            }
        }
        if (newDialogPromptConversation) {
            if (newDialogPromptConversation.id > 0) {
                DialogPromptConversationApi.update(newDialogPromptConversation)
                    .catch(err => {
                        handleErrorMessage(err, setErrorMessage, history, location);
                    })
                if (newDialogPrompt != null) {
                    onSubmit(newDialogPrompt, setIsSaving); // don't need to wait for update to finish
                }
            } else {
                DialogPromptConversationApi.create(newDialogPromptConversation)
                    .then((response) => {
                        const conversation = response.data;
                        if (newDialogPrompt != null) {
                            if (newDialogPrompt instanceof DialogPromptConversationListen) {
                                newDialogPrompt.dialog_prompt_conversation_id = conversation.id
                            } else {
                                // TODO, figure out why this happens, but for now, ignoring it should be fine
                                // throw `unknown newDialogPrompt class ${typeof(newDialogPrompt)}`
                            }
                            onSubmit(newDialogPrompt, setIsSaving);
                        }
                    })
                    .catch(err => {
                        handleErrorMessage(err, setErrorMessage, history, location);
                    })
            }
        } else if (className == "DialogPromptQuizWordModel" ||
            className == "DialogPromptMatchWordPictureModel" ||
            className == "DialogPromptTypeSpeakModel" ||
            className == "DialogPromptQuizQuestionModel" ||
            className == "DialogPromptQuizQuestionAudioModel" ||
            className == "DialogPromptImageSelectSpeakModel" ||
            className == "DialogPromptConversationListenModel" ||
            className == "DialogPromptConversationListenSpeakModel" ||
            className == "DialogPromptConversationListenWithoutCharacterModel" ||
            className == "DialogPromptConversationReadSpeakModel" ||
            className == "DialogPromptConversationReadWithBlankSpeakModel" ||
            className == "DialogPromptConversationSpeakOnlyModel" ||
            className == "DialogPromptSelectNextDialogModel" ||
            className == "DialogPromptSpeakNoGradeModel" ||
            className == "DialogPromptSpeakWithGradeModel") {
            if (newDialogPrompt != null) {
                onSubmit(newDialogPrompt, setIsSaving);
            }
        } else {
            const thisDialogPrompt = DialogPromptFactory(
                className,
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                isHidden,
                imageSource as string | null,
                imageSourceUrl as string | null,
                newWord as string | null,
                yesButtonText as string | null,
                noButtonText as string | null,
                maybeButtonText as string | null,
                yesResponse as string | null,
                noResponse as string | null,
                maybeResponse as string | null,
                sentenceId as number | null)
            onSubmit(thisDialogPrompt, setIsSaving);
        }
    }

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Clear the image error
        setImageError(false);

        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Code to handle the reader result
                let base64String: string;
                let result = reader.result;
                if (typeof result === 'string') {
                    base64String = result;
                } else if (result instanceof ArrayBuffer) {
                    base64String = arrayBufferToBase64(result)
                } else {
                    base64String = ""
                }
                setImageSource(base64String);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteImage = () => {
        // Set the wordImage state to an empty string to remove the image
        setImageSource('');
        setImageSourceUrl('');
    };

    const handleDeleteConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setShowConfirmation(false);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmation(false);
        onDelete(oldDialogPromptId);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
                <Typography variant="h5">
                    Edit DialogPrompt
                </Typography>
                <input type="hidden" value={id} onChange={handleIdChange} />
                <input type="hidden" value={dialogId} onChange={handleDialogIdChange} />
                <input type="hidden" value={sentenceId as number} onChange={handleSentenceIdChange} />
                <FormControl>
                    <InputLabel id="class-name-label">Class Name</InputLabel>
                    <Select
                        labelId="class-name-label"
                        id="class-name-select"
                        value={className}
                        onChange={handleClassNameChange}
                        required
                    >

                        <MenuItem value="DialogPromptModel">{getClassNameDescription("DialogPromptModel")}</MenuItem>
                        <MenuItem value="DialogPromptImageModel">{getClassNameDescription("DialogPromptImageModel")}</MenuItem>
                        <MenuItem value="DialogPromptIntroduceWordModel">{getClassNameDescription("DialogPromptIntroduceWordModel")}</MenuItem>
                        <MenuItem value="DialogPromptListenSpeakModel">{getClassNameDescription("DialogPromptListenSpeakModel")}</MenuItem>
                        <MenuItem value="DialogPromptListenSpeakNoGradeModel">{getClassNameDescription("DialogPromptListenSpeakNoGradeModel")}</MenuItem>
                        <MenuItem value="DialogPromptImageSelectSpeakModel">{getClassNameDescription("DialogPromptImageSelectSpeakModel")}</MenuItem>
                        <MenuItem value="DialogPromptQuizWordModel">{getClassNameDescription("DialogPromptQuizWordModel")}</MenuItem>
                        <MenuItem value="DialogPromptMatchWordPictureModel">{getClassNameDescription("DialogPromptMatchWordPictureModel")}</MenuItem>
                        <MenuItem value="DialogPromptQuizQuestionModel">{getClassNameDescription("DialogPromptQuizQuestionModel")}</MenuItem>
                        <MenuItem value="DialogPromptQuizQuestionAudioModel">{getClassNameDescription("DialogPromptQuizQuestionAudioModel")}</MenuItem>
                        <MenuItem value="DialogPromptTypeSpeakModel">{getClassNameDescription("DialogPromptTypeSpeakModel")}</MenuItem>
                        <MenuItem value="DialogPromptSpeakNoGradeModel">{getClassNameDescription("DialogPromptSpeakNoGradeModel")}</MenuItem>
                        <MenuItem value="DialogPromptSpeakWithGradeModel">{getClassNameDescription("DialogPromptSpeakWithGradeModel")}</MenuItem>

                        <MenuItem value="DialogPromptConversationListenModel">{getClassNameDescription("DialogPromptConversationListenModel")}</MenuItem>
                        <MenuItem value="DialogPromptConversationListenWithoutCharacterModel">{getClassNameDescription("DialogPromptConversationListenWithoutCharacterModel")}</MenuItem>
                        <MenuItem value="DialogPromptConversationListenSpeakModel">{getClassNameDescription("DialogPromptConversationListenSpeakModel")}</MenuItem>
                        <MenuItem value="DialogPromptConversationReadSpeakModel">{getClassNameDescription("DialogPromptConversationReadSpeakModel")}</MenuItem>
                        <MenuItem value="DialogPromptConversationReadWithBlankSpeakModel">{getClassNameDescription("DialogPromptConversationReadWithBlankSpeakModel")}</MenuItem>
                        <MenuItem value="DialogPromptConversationSpeakOnlyModel">{getClassNameDescription("DialogPromptConversationSpeakOnlyModel")}</MenuItem>

                        <MenuItem value="DialogPromptSelectNextDialogModel">{getClassNameDescription("DialogPromptSelectNextDialogModel")}</MenuItem>
                        <MenuItem value="DialogPromptStartNextLessonModel">{getClassNameDescription("DialogPromptStartNextLessonModel")}</MenuItem>

                        <MenuItem value="DialogPromptSelectLengthTimeModel">{getClassNameDescription("DialogPromptSelectLengthTimeModel")}</MenuItem>
                        <MenuItem value="DialogPromptEnterNameModel">{getClassNameDescription("DialogPromptEnterNameModel")}</MenuItem>
                        <MenuItem value="DialogPromptSelectAvatarModel">{getClassNameDescription("DialogPromptSelectAvatarModel")}</MenuItem>
                        <MenuItem value="DialogPromptSelectTeacherModel">{getClassNameDescription("DialogPromptSelectTeacherModel")}</MenuItem>
                        <MenuItem value="DialogPromptLearnWithCharacterModel">{getClassNameDescription("DialogPromptLearnWithCharacterModel")}</MenuItem>
                        <MenuItem value="DialogPromptScoringLevelModel">{getClassNameDescription("DialogPromptScoringLevelModel")}</MenuItem>
                        <MenuItem value="DialogPromptStartClassModel">{getClassNameDescription("DialogPromptStartClassModel")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>Parent Dialog Name</InputLabel>
                    <Select
                        value={dialogId}
                        label="Parent Dialog Name"
                        onChange={e => setDialogId(Number(e.target.value))}
                        required
                    >
                        <MenuItem key={-1} value={-1} />
                        {dialogList.map((dialog) => (
                            <MenuItem key={dialog.id} value={dialog.id} sx={{ fontWeight: dialog.is_hidden ? 200 : 400, opacity: dialog.is_hidden ? 0.4 : 1 }}>
                                {dialog.is_hidden && "[Hidden]"} {dialog.title}
                                :: {lessonDictionary[dialog.lesson_id] ? lessonDictionary[dialog.lesson_id].name : "Lesson Deleted"} ::
                                {lessonDictionary[dialog.lesson_id] ? (courseDictionary[lessonDictionary[dialog.lesson_id].course_id] ? courseDictionary[lessonDictionary[dialog.lesson_id].course_id].name : "Course Deleted") : "Course Deleted"}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={<Checkbox checked={isHidden} onChange={handleHiddenChange} />}
                    label="Hidden"
                />
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="isTeacher"
                        name="isTeacher"
                        value={isTeacher.toString()}
                        onChange={handleIsTeacherChange}
                    >
                        <FormControlLabel value="true" control={<Radio required />} label="Teacher" />
                        <FormControlLabel value="false" control={<Radio required />} label="Student" />
                    </RadioGroup>
                </FormControl>
                <div>
                    <TextField
                        label="Prompt"
                        value={prompt}
                        onChange={handlePromptChange}
                        multiline
                        rows={3}
                        sx={{ width: '100%' }}
                        inputProps={{
                            style: {
                                resize: 'vertical',
                                overflow: 'hidden',
                            },
                        }}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isNoteToStudent} onChange={handleIsNoteToStudentChange} />}
                        label="Is a Note to the Student"
                    />
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;StudentName/&gt; will be replaced with the student's name
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;TeacherName/&gt; will be replaced with the teacher's name
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Microphone/&gt; will be replaced with the microphone icon for recording
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Speaker/&gt; will be replaced with the speaker icon for listening
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Info/&gt; will be replaced with the info icon for more details
                    </Typography>
                </div>

                {dialogPromptClassNameContainsProp(className, "image_src") && (<>
                    <Box>
                        <InputLabel htmlFor="image-upload">Prompt with Image</InputLabel>
                        <Input
                            type="file"
                            id="image-upload"
                            inputProps={{ accept: "image/*" }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(e)}
                        />
                    </Box>
                    {(imageSource || imageSourceUrl) && (
                        <img
                            src={imageSource ? imageSource : (imageSourceUrl)}
                            alt="Uploaded"
                            style={{
                                maxHeight: '200px', maxWidth: '200px',
                                height: 'auto', width: 'auto',
                                margin: '0 auto', // Add this line to center the image horizontally
                                display: 'block', // Add this line to ensure the margin works correctly
                            }}
                        />
                    )}
                    {/* Add the delete image button */}
                    {((imageSource && imageSource != "broken") || (imageSourceUrl && imageSourceUrl != "broken")) && (
                        <Box display="inline-block">
                            <Button variant="outlined" onClick={handleDeleteImage}>
                                Delete Image
                            </Button>
                        </Box>
                    )}
                    {/* Display the image error message */}
                    {imageError && (
                        <Typography sx={{ color: 'error.main', fontSize: '0.9rem' }}>
                            Image is required.
                        </Typography>
                    )}
                </>
                )}

                {dialogPromptClassNameContainsProp(className, "new_word") && (
                    <TextField
                        label="New Word"
                        value={newWord}
                        onChange={handleNewWordChange}
                        required
                    />
                )}

                {dialogPromptClassNameContainsProp(className, "yes_button_text") && (
                    <TextField
                        label="Yes Button Text"
                        value={yesButtonText}
                        onChange={handleYesButtonTextChange}
                        required
                    />
                )}
                {dialogPromptClassNameContainsProp(className, "maybe_button_text") && (
                    <TextField
                        label="Maybe Button Text"
                        value={maybeButtonText}
                        onChange={handleMaybeButtonTextChange}
                        required
                    />
                )}
                {dialogPromptClassNameContainsProp(className, "no_button_text") && (
                    <TextField
                        label="No Button Text"
                        value={noButtonText}
                        onChange={handleNoButtonTextChange}
                        required
                    />
                )}

                {dialogPromptClassNameContainsProp(className, "yes_response") && (<>
                    <TextField
                        label="Yes Response"
                        value={yesResponse}
                        onChange={handleYesResponseChange}
                        required
                    />
                </>)}
                {dialogPromptClassNameContainsProp(className, "maybe_response") && (<>
                    <TextField
                        label="Maybe Response"
                        value={maybeResponse}
                        onChange={handleMaybeResponseChange}
                        required
                    />
                </>)}
                {dialogPromptClassNameContainsProp(className, "no_response") && (<>
                    <TextField
                        label="No Response"
                        value={noResponse}
                        onChange={handleNoResponseChange}
                        required
                    />
                </>)}
                {className == "DialogPromptQuizWordModel" &&
                    <DialogPromptQuizWordForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden}
                        newWord={newWord} yesResponse={yesResponse} noResponse={noResponse} />}
                {className == "DialogPromptTypeSpeakModel" &&
                    <DialogPromptTypeSpeakForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} yesResponse={yesResponse}
                        imageSource={imageSource} imageSourceUrl={imageSourceUrl} />}
                {className == "DialogPromptMatchWordPictureModel" &&
                    <DialogPromptMatchWordPictureForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} yesResponse={yesResponse} noResponse={noResponse} />}
                {className == "DialogPromptQuizQuestionModel" &&
                    <DialogPromptQuizQuestionForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} yesResponse={yesResponse} noResponse={noResponse}
                        imageSource={imageSource} imageSourceUrl={imageSourceUrl} />}
                {className == "DialogPromptQuizQuestionAudioModel" &&
                    <DialogPromptQuizQuestionAudioForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} yesResponse={yesResponse} noResponse={noResponse}
                        imageSource={imageSource} imageSourceUrl={imageSourceUrl} />}
                {className == "DialogPromptImageSelectSpeakModel" &&
                    <DialogPromptImageSelectSpeakForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} yesResponse={yesResponse} noResponse={noResponse} />}
                {(className == "DialogPromptConversationListenModel" || className == "DialogPromptConversationListenWithoutCharacterModel") &&
                    <DialogPromptConversationListenForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt} setNewDialogPromptConversation={setNewDialogPromptConversation}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} dialogPromptClassName={className} />}
                {(className == "DialogPromptConversationListenSpeakModel" ||
                    className == "DialogPromptConversationReadSpeakModel" ||
                    className == "DialogPromptConversationReadWithBlankSpeakModel" ||
                    className == "DialogPromptConversationSpeakOnlyModel")
                    &&
                    <DialogPromptConversationRelatedForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt} setNewDialogPromptConversation={setNewDialogPromptConversation}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} yesResponse={yesResponse} noResponse={noResponse}
                        dialogPromptClassName={className} />}
                {className == "DialogPromptSelectNextDialogModel" &&
                    <DialogPromptSelectNextDialogForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} />}

                {className == "DialogPromptSpeakNoGradeModel" &&
                    <DialogPromptSpeakNoGradeForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} yesResponse={yesResponse} imageSource={imageSource} imageSourceUrl={imageSourceUrl} />}
                {className == "DialogPromptSpeakWithGradeModel" &&
                    <DialogPromptSpeakWithGradeForm oldDialogPromptId={oldDialogPromptId}
                        setNewDialogPrompt={setNewDialogPrompt}
                        id={id} dialogId={dialogId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} isHidden={isHidden} yesResponse={yesResponse} noResponse={noResponse} imageSource={imageSource} imageSourceUrl={imageSourceUrl} />}


                <TextField
                    label="Sequence Number"
                    value={orderIndex}
                    onChange={handleOrderIndexChange}
                    type="number"
                    required
                />
                <br />
                <Button disabled={isSaving} type="submit" variant="contained">
                    {isSaving ? <CircularProgress size={24} /> : 'Save'}
                </Button>
                <Button variant="contained" color="error" onClick={handleDeleteConfirmation}>
                    Delete
                </Button>
            </Stack>
            <Dialog open={showConfirmation} onClose={handleDeleteCancel}>
                <DialogTitle>Delete DialogPrompt</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this dialogPrompt?</Typography>
                    <Typography>{prompt}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default DialogPromptForm;