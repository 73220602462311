import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CourseApi } from '../api/CourseApi';
import { ILesson } from '../api/LessonApi';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';

interface LessonPageProps {
    lesson: ILesson;
}

const LessonPage: React.FC<LessonPageProps> = ({ lesson }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [courseName, setCourseName] = React.useState("");
    const [lessonName, setLessonName] = React.useState(lesson.name);
    const [lessonDescription, setLessonDescription] = React.useState(lesson.description);

    React.useEffect(() => {
        setLessonName(lesson.name);
        setLessonDescription(lesson.description);

        CourseApi.getById(lesson.course_id)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to CourseApi.getById() " + response.status)
            })
            .then(course => setCourseName(course.name))
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })

    }, [lesson]);

    return (
        <Stack spacing={4} sx={{ margin: '0 auto', pt: 2 }}>
            <Typography>
                {lessonDescription}
            </Typography>
        </Stack>
    );
};

export default LessonPage;
