import * as React from 'react';

export interface ILoggedInContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
};

export const IsLoggedInInitialContext = { isLoggedIn: false, setIsLoggedIn: () => null };

const IsLoggedInContext = React.createContext<ILoggedInContextType>(IsLoggedInInitialContext);

export default IsLoggedInContext;
