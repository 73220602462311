import { Box, Container, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CourseApi, ICourse } from '../api/CourseApi';
import { DialogApi, IDialog } from '../api/DialogApi';
import { ILesson, LessonApi } from '../api/LessonApi';
import CoursePage from '../component/CoursePage';
import DialogPage from '../component/DialogPage';
import LessonPage from '../component/LessonPage';
import SettingsPage from '../component/SettingsPage';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';

import ForwardArrowIcon from '../asset/icon-forward-arrow-grey-on-blue.png';
import LargeBlackIcon from '../asset/icon-menu-large-black.png';
import LargeWhiteIcon from '../asset/icon-menu-large-white.png';
import MediumOrangeIcon from '../asset/icon-menu-medium-orange.png';
import MediumWhiteIcon from '../asset/icon-menu-medium-white.png';
import SmallOrangeIcon from '../asset/icon-menu-small-orange.png';
import SmallWhiteIcon from '../asset/icon-menu-small-white.png';

import { useLocation } from 'react-router-dom';
import { UserDialogPromptApi, UserDialogPromptSummary } from '../api/UserDialogPromptApi';
import SettingsOrangeIcon from '../asset/settings-outline-large-orange.png';
import SettingsWhiteIcon from '../asset/settings-outline-large-white.png';

import LensIcon from '@mui/icons-material/Lens';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import TopMenuIndexContext, { MenuIndex } from '../context/TopMenuIndexContext';

export function isSettingsComplete() {
    return localStorage.getItem('studentName') != null &&
        localStorage.getItem('studentAvatar') != null &&
        localStorage.getItem('teacher') != null
    //  && localStorage.getItem('learnWithCharacter') != null;
}

export default function CreateLesson() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const { topMenuIndex, setTopMenuIndex } = React.useContext(TopMenuIndexContext);

    const [courseList, setCourseList] = React.useState<ICourse[]>([]);
    const [selectedCourse, setSelectedCourse] = React.useState<ICourse | null>(null);

    const [lessonList, setLessonList] = React.useState<ILesson[]>([]);
    const [selectedLesson, setSelectedLesson] = React.useState<ILesson | null>(null);

    const [dialogList, setDialogList] = React.useState<IDialog[]>([]);
    const [selectedDialog, setSelectedDialog] = React.useState<IDialog | null>(null);

    const [lastRequestParam, setLastRequestParamUsed] = React.useState("");

    const [initialSummaryLoaded, setInitialSummaryLoaded] = React.useState(false);

    React.useEffect(() => {
        setTopMenuIndex(MenuIndex.Lesson)
        CourseApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to CourseApi.getAll() " + response.status)
            })
            .then(list => {
                const visibleList = list.filter(course => course.is_hidden === false);
                setCourseList(visibleList);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        LessonApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to LessonApi.getAll() " + response.status)
            })
            .then(list => {
                const visibleList = list.filter(list => list.is_hidden === false);
                setLessonList(visibleList);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        DialogApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogApi.getAll() " + response.status)
            })
            .then(list => {
                const visibleList = list.filter(dialog => dialog.is_hidden === false);
                setDialogList(visibleList);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }, []);

    React.useEffect(() => {
        if (initialSummaryLoaded == false && courseList.length > 0 && lessonList.length > 0 && dialogList.length > 0) {
            setInitialSummaryLoaded(true);
            UserDialogPromptApi.getSummary()
                .then(response => {
                    if (response.status < 300) {
                        return response.data;
                    }
                    throw new Error("Unable to UserDialogPromptApi.completeDialogPrompt " + response.status)

                })
                .then(summary => {
                    updateUserDialogPromptSummary(summary)
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history, location);
                })
        }
        if (courseList.length > 0 && lessonList.length > 0 && dialogList.length > 0 && location.search != lastRequestParam) {
            const query = new URLSearchParams(location.search);
            const settings = query.get("settings") === "true";
            if (settings) {
                setSelectedCourseToSettings();
            } else {
                const dialogIdStr = query.get("dialogId");
                const dialogId = dialogIdStr ? parseInt(dialogIdStr, 10) : null;
                if (dialogId) {
                    const dialog = dialogList.find(d => d.id === dialogId);
                    if (dialog) {
                        const lesson = lessonList.find(l => l.id == dialog.lesson_id)
                        if (lesson) {
                            const course = courseList.find(c => c.id == lesson.course_id)
                            if (course) {
                                handleCourseMenuClick(course)
                            }
                            handleLessonMenuClick
                        }
                        handleDialogMenuClick(dialog)
                    }

                }
            }
            setLastRequestParamUsed(location.search)
        }
    }, [courseList, lessonList, dialogList, location.search])  // will re-run when query params change

    /*
        SETTINGS HANDLERS
     */

    function isSettings(course: ICourse) {
        return course.name === "Settings";
    }

    function setSelectedCourseToSettings() {
        const settingsCourse = courseList.find(course => isSettings(course));
        //set and unset to force rerender of SettingsPage.
        setSelectedCourse(null)
        setSelectedCourse(settingsCourse!)
        setSelectedLesson(null)
        setSelectedDialog(null)
    }

    /*
        CLEAR
    */

    const clearCourseMenu = () => {
        const updatedCourseList = courseList.map(course => ({
            ...course, // Spread operator to copy properties of the current course object
            expanded: false // Set the expanded property to false
        }));
        setCourseList(updatedCourseList);
    }
    const clearLessonMenu = () => {
        const updatedLessonList = lessonList.map(course => ({
            ...course, // Spread operator to copy properties of the current course object
            expanded: false // Set the expanded property to false
        }));
        setLessonList(updatedLessonList);
    }
    const clearDialogMenu = () => {
        const updatedDialogList = dialogList.map(course => ({
            ...course, // Spread operator to copy properties of the current course object
            expanded: false // Set the expanded property to false
        }));
        setDialogList(updatedDialogList);
    }

    /*
        COURSE HANDLERS
     */

    const expandCourse = (expandCourse: ICourse) => {
        setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
                course.id === expandCourse.id ? { ...course, expanded: true } : { ...course, expanded: false }
            )
        );
    };

    const handleCourseMenuClick = (course: ICourse) => {
        if (isSettingsComplete() == false) {
            setSelectedCourseToSettings()
        } else {
            setSelectedLesson(null)
            setSelectedDialog(null)
            if (course.expanded) {
                setSelectedCourse(null)
                clearCourseMenu();
                clearLessonMenu();
                clearDialogMenu();
            } else {
                setSelectedCourse(course)
                expandCourse(course)
                clearLessonMenu();
                clearDialogMenu();
            }
        }
    };

    /*
        LESSON HANDLERS
     */

    const expandLesson = (expandLesson: ILesson) => {
        setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
                course.id === expandLesson.course_id ? { ...course, expanded: true } : { ...course, expanded: false }
            )
        );
        setLessonList((prevLessonList) =>
            prevLessonList.map((lesson) =>
                lesson.id === expandLesson.id ? { ...lesson, expanded: true } : { ...lesson, expanded: false }
            )
        );
    };

    const handleLessonMenuClick = (lesson: ILesson) => {
        if (isSettingsComplete() == false) {
            setSelectedCourseToSettings()
        } else {
            setSelectedDialog(null)
            if (lesson.expanded) {
                setSelectedLesson(null)
                clearLessonMenu()
                clearDialogMenu()
            } else {
                setSelectedLesson(lesson)
                expandLesson(lesson)
                clearDialogMenu()
            }
        }
    };

    /*
        DIALOG HANDLERS
     */

    const expandDialog = async (expandDialog: IDialog) => {
        const lessonResponse = await LessonApi.getById(expandDialog.lesson_id);
        const lesson = lessonResponse.data;

        setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
                course.id === lesson.course_id ? { ...course, expanded: true } : { ...course, expanded: false }
            )
        );
        setLessonList((prevLessonList) =>
            prevLessonList.map((lesson) =>
                lesson.id === expandDialog.lesson_id ? { ...lesson, expanded: true } : { ...lesson, expanded: false }
            )
        );
        setDialogList((prevDialogList) =>
            prevDialogList.map((dialog) =>
                dialog.id === expandDialog.id ? { ...dialog, expanded: true } : { ...dialog, expanded: false }
            )
        );
    };

    const handleDialogMenuClick = (dialog: IDialog) => {
        if (isSettingsComplete() == false) {
            setSelectedCourseToSettings()
        } else {
            if (dialog.expanded) {
                setSelectedDialog(null)
                clearDialogMenu()
            } else {
                setSelectedDialog(dialog)
                expandDialog(dialog)
            }
        }
    };

    const updateUserDialogPromptSummary = (summaryList: UserDialogPromptSummary[]) => {
        const courseCount: Record<number, [number, number]> = {};
        const lessonCount: Record<number, [number, number]> = {};
        const dialogCount: Record<number, [number, number]> = {};

        summaryList.forEach((item) => {
            const { course_id, lesson_id, dialog_id, dialog_prompt_count, user_dialog_prompt_count } = item;

            // Update courseCount
            if (!courseCount[course_id]) {
                courseCount[course_id] = [0, 0];
            }
            courseCount[course_id][0] += dialog_prompt_count;
            courseCount[course_id][1] += user_dialog_prompt_count;

            // Update lessonCount
            if (!lessonCount[lesson_id]) {
                lessonCount[lesson_id] = [0, 0];
            }
            lessonCount[lesson_id][0] += dialog_prompt_count;
            lessonCount[lesson_id][1] += user_dialog_prompt_count;

            // Update dialogCount
            if (!dialogCount[dialog_id]) {
                dialogCount[dialog_id] = [0, 0];
            }
            dialogCount[dialog_id][0] += dialog_prompt_count;
            dialogCount[dialog_id][1] += user_dialog_prompt_count;
        });

        setCourseList(prevCourseList =>
            prevCourseList.map(course => {
                const counts = courseCount[course.id];
                return {
                    ...course,
                    dialogPromptCount: counts ? counts[0] : 0,
                    userDialogPromptCount: counts ? counts[1] : 0,
                };
            })
        );

        setLessonList(prevLessonList =>
            prevLessonList.map(lesson => {
                const counts = lessonCount[lesson.id];
                return {
                    ...lesson,
                    dialogPromptCount: counts ? counts[0] : 0,
                    userDialogPromptCount: counts ? counts[1] : 0,
                };
            })
        );

        setDialogList(prevDialogList =>
            prevDialogList.map(dialog => {
                const counts = dialogCount[dialog.id];
                return {
                    ...dialog,
                    dialogPromptCount: counts ? counts[0] : 0,
                    userDialogPromptCount: counts ? counts[1] : 0,
                };
            })
        );
    }

    const calculateUserDialogPromptIcon = (userDialogPromptCount: number | undefined, dialogPromptCount: number | undefined) => {
        if (userDialogPromptCount == 0 || userDialogPromptCount == undefined || dialogPromptCount == undefined) {
            return <div />
        }
        const percentComplete = Math.round((userDialogPromptCount / dialogPromptCount) * 100);
        const tooltipText = `${percentComplete}% complete`;

        if (userDialogPromptCount < dialogPromptCount) {
            return <Tooltip title={tooltipText} arrow>
                <TripOriginIcon sx={{ color: '#FFFFFF' }} />
            </Tooltip>
        } else {
            return <Tooltip title={tooltipText} arrow>
                <LensIcon sx={{ color: '#28A164' }} />
            </Tooltip>
        }
    }

    /**
     * handle header height, and height of main panel
     */
    const headerStackRef = React.useRef<HTMLDivElement>(null);

    return (
        <Container sx={{
            mx: 0, my: 0, p: 0, pr: 0, backgroundColor: '#002A6E', minWidth: '100vw',
            // Attempt to override with higher specificity
            '&.MuiContainer-root': {
                paddingRight: 0,
            },
        }}>
            <Grid container justifyContent="center" sx={{ p: 0 }}>
                <Grid item sm={12} md={4} alignItems="center" justifyContent="center" sx={{ paddingTop: 2, overflowY: 'auto' }}>
                    <div style={{ height: 'calc(100vh - 98px)' }}>
                        <Stack spacing={2}>
                            {/* Typography aligned to the left */}
                            <Typography variant="h2" color="secondary" sx={{ pl: '32px', pt: 0, pb: 0 }}>
                                Lesson
                            </Typography>
                            {courseList.map(course => isSettings(course) ? (
                                //                                 <>{/*do not show settings*/}</>
                                <Stack key={course.id} direction="row" alignItems="center" onClick={() => handleCourseMenuClick(course)}>
                                    {course.expanded ? (<>
                                        <img src={SettingsOrangeIcon} style={{ maxWidth: '48px', maxHeight: '48px', marginLeft: '9px' }} />
                                        <Typography variant="h4" sx={{ color: '#FCCD56', ml: 1 }}>
                                            {course.name}
                                        </Typography>
                                    </>) : (<>
                                        <img src={SettingsWhiteIcon} style={{ maxWidth: '48px', maxHeight: '48px', marginLeft: '9px' }} />
                                        <Typography variant="h4" sx={{ color: '#F2F7FF', ml: 1 }}>
                                            {course.name}
                                        </Typography>
                                    </>)}
                                </Stack >
                            ) : (<div key={course.id}>
                                {course.expanded ? (<>
                                    <Stack direction="row" alignItems="center" onClick={() => handleCourseMenuClick(course)}
                                        justifyContent="space-between" // pushes last child (icon) to the right
                                        sx={{
                                            backgroundColor: '#FCCD56', // Adjust the color as needed
                                            borderRadius: '8px', // Adjust the radius as needed
                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                            mr: 3
                                        }}
                                    >
                                        <Stack direction="row" alignItems="center">
                                            <img src={LargeBlackIcon} style={{ maxWidth: '48px', maxHeight: '48px' }} />
                                            <Typography variant="h4" sx={{ color: '#251C00', ml: 1 }}>
                                                {course.name}
                                            </Typography>
                                        </Stack>
                                        {calculateUserDialogPromptIcon(course.userDialogPromptCount, course.dialogPromptCount)}
                                    </Stack>
                                </>) : (<>
                                    <Stack direction="row" alignItems="center" onClick={() => handleCourseMenuClick(course)}
                                        justifyContent="space-between" // pushes last child (icon) to the right
                                        sx={{
                                            border: '1px solid #F2F7FF', // Adjust the color as needed
                                            borderRadius: '8px', // Adjust the radius as needed
                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                            mr: 3
                                        }}
                                    >
                                        <Stack direction="row" alignItems="center">
                                            <img src={LargeWhiteIcon} style={{ maxWidth: '48px', maxHeight: '48px' }} />
                                            <Typography variant="h4" sx={{ color: '#F2F7FF', ml: 1 }}>
                                                {course.name}
                                            </Typography>
                                        </Stack>
                                        {calculateUserDialogPromptIcon(course.userDialogPromptCount, course.dialogPromptCount)}
                                    </Stack>
                                </>)}
                                {course.expanded == true ? (
                                    <Stack>
                                        {lessonList
                                            .filter(lesson => lesson.course_id === course.id)
                                            .map(lesson => (<div key={lesson.id}>
                                                {lesson.expanded ? (<>
                                                    <Stack direction="row" alignItems="center" onClick={() => handleLessonMenuClick(lesson)}
                                                        justifyContent="space-between" // pushes last child (icon) to the right
                                                        sx={{
                                                            border: '1px solid #FCCD56', // Adjust the color as needed
                                                            borderRadius: '8px', // Adjust the radius as needed
                                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                                            mr: 3,
                                                            mt: 1
                                                        }}
                                                    >
                                                        <Stack direction="row" alignItems="center">
                                                            <img src={MediumOrangeIcon} style={{ maxWidth: '36px', maxHeight: '36px', marginLeft: '48px' }} />
                                                            <Typography variant="h5" sx={{ color: '#FCCD56', ml: 1 }}>
                                                                {lesson.name}
                                                            </Typography>
                                                        </Stack>
                                                        {calculateUserDialogPromptIcon(lesson.userDialogPromptCount, lesson.dialogPromptCount)}
                                                    </Stack>
                                                </>) : (<>
                                                    <Stack direction="row" alignItems="center" onClick={() => handleLessonMenuClick(lesson)}
                                                        justifyContent="space-between" // pushes last child (icon) to the right
                                                        sx={{
                                                            border: '1px solid #F2F7FF', // Adjust the color as needed
                                                            borderRadius: '8px', // Adjust the radius as needed
                                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                                            mr: 3,
                                                            mt: 1
                                                        }}
                                                    >
                                                        <Stack direction="row" alignItems="center">
                                                            <img src={MediumWhiteIcon} style={{ maxWidth: '36px', maxHeight: '36px', marginLeft: '48px' }} />
                                                            <Typography variant="h5" sx={{ color: '#F2F7FF', ml: 1 }}>
                                                                {lesson.name}
                                                            </Typography>
                                                        </Stack>
                                                        {calculateUserDialogPromptIcon(lesson.userDialogPromptCount, lesson.dialogPromptCount)}
                                                    </Stack>
                                                </>)}
                                                {lesson.expanded == true ? (
                                                    <Stack>
                                                        {dialogList
                                                            .filter(dialog => dialog.lesson_id === lesson.id)
                                                            .map(dialog => (<div key={dialog.id}>
                                                                {dialog.expanded ? (<>
                                                                    <Stack direction="row" alignItems="center" onClick={() => handleDialogMenuClick(dialog)}
                                                                        justifyContent="space-between" // pushes last child (icon) to the right
                                                                        sx={{
                                                                            border: '1px solid #FCCD56', // Adjust the color as needed
                                                                            borderRadius: '8px', // Adjust the radius as needed
                                                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                                                            mr: 3,
                                                                            mt: 1
                                                                        }}
                                                                    >
                                                                        <Stack direction="row" alignItems="center">
                                                                            <img src={SmallOrangeIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginLeft: '96px' }} />
                                                                            <Stack direction="column" spacing={0}>
                                                                                <Typography variant="h6" sx={{ color: '#FCCD56', ml: 1 }}>
                                                                                    {dialog.title}
                                                                                </Typography>
                                                                                <Typography variant="h6" sx={{ color: 'darkgrey', ml: 1 }}>
                                                                                    {dialog.subtitle}
                                                                                </Typography>
                                                                            </Stack>
                                                                        </Stack>
                                                                        {calculateUserDialogPromptIcon(dialog.userDialogPromptCount, dialog.dialogPromptCount)}
                                                                    </Stack>
                                                                </>) : (<>
                                                                    <Stack direction="row" alignItems="center" onClick={() => handleDialogMenuClick(dialog)}
                                                                        justifyContent="space-between" // pushes last child (icon) to the right
                                                                        sx={{
                                                                            border: '1px solid #F2F7FF', // Adjust the color as needed
                                                                            borderRadius: '8px', // Adjust the radius as needed
                                                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                                                            mr: 3,
                                                                            mt: 1
                                                                        }}
                                                                    >
                                                                        <Stack direction="row" alignItems="center">
                                                                            <img src={SmallWhiteIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginLeft: '96px' }} />
                                                                            <Stack direction="column" spacing={0}>
                                                                                <Typography variant="h6" sx={{ color: '#F2F7FF', ml: 1 }}>
                                                                                    {dialog.title}
                                                                                </Typography>
                                                                                <Typography variant="h6" sx={{ color: 'darkgrey', ml: 1 }}>
                                                                                    {dialog.subtitle}
                                                                                </Typography>
                                                                            </Stack>
                                                                        </Stack>
                                                                        {calculateUserDialogPromptIcon(dialog.userDialogPromptCount, dialog.dialogPromptCount)}
                                                                    </Stack>
                                                                </>)}
                                                            </div>))
                                                        }
                                                    </Stack>
                                                ) : null}
                                            </div>))
                                        }
                                    </Stack>
                                ) : null}
                            </div>))}
                        </Stack>
                    </div>
                </Grid>
                <Grid item sm={12} md={8} sx={{ paddingTop: 2, px: 8, paddingBottom: 2, backgroundColor: '#F2F7FF' }}> {/* Adds padding to create a frame */}
                    <Stack spacing={2} sx={{
                        height: '100%', // Ensure Box fills the Grid item vertically
                        width: '100%', // Ensure Box fills the Grid item horizontally
                        display: 'flex',
                        flexGrow: 1, // Allow Stack to expand
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexGrow: 1,
                            flexDirection: 'column',
                            justifyContent: 'start', // Center children horizontally
                            alignItems: 'center', // Center children vertically
                            alignSelf: "stretch", // Ensures Box fills parent's width
                            // height: '100%', // Ensure Box fills the Grid item vertically
                            width: '100%', // Ensure Box fills the Grid item horizontally
                            backgroundColor: "#FFFFFF",
                            // margin: 'auto', // Center the Box if needed, depending on your layout
                            // padding: 8, // Inner padding within the Box

                            paddingTop: 2,
                            paddingBottom: 0,
                            marginBottom: 0,
                            borderRadius: '16px',
                        }}>
                            <Stack ref={headerStackRef} direction="row" sx={{ width: '100%' }}>
                                {selectedCourse != null && (
                                    <Typography variant="h4" color="#52637D" sx={{ marginLeft: '32px' }} >
                                        {selectedCourse ? selectedCourse.name : '\u00A0' /* Non-breaking space if null */}
                                    </Typography>
                                )}
                                {selectedLesson != null && (<>
                                    <img src={ForwardArrowIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginLeft: '24px' }} />
                                    <Typography variant="h4" color="#52637D" sx={{ marginLeft: '24px' }}>
                                        {selectedLesson ? selectedLesson.name : '\u00A0' /* Non-breaking space if null */}
                                    </Typography>
                                </>)}
                                {selectedDialog != null && (<>
                                    <img src={ForwardArrowIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginLeft: '24px' }} />
                                    <Typography variant="h4" color="#52637D" sx={{ marginLeft: '24px' }}>
                                        {selectedDialog ? selectedDialog.title : '\u00A0' /* Non-breaking space if null */}
                                    </Typography>
                                </>)}
                            </Stack>

                            {/* Add a Divider here */}
                            <Divider sx={{ width: '100%', mt: 1 }} />
                            {selectedDialog != null ? (
                                <DialogPage dialog={selectedDialog} headerStackRef={headerStackRef} updateUserDialogPromptSummary={updateUserDialogPromptSummary} />
                            ) : (<>
                                {selectedLesson != null ? (
                                    <LessonPage lesson={selectedLesson} />
                                ) : (<>
                                    {selectedCourse != null ? (
                                        isSettings(selectedCourse) ? (
                                            <SettingsPage course={selectedCourse} />
                                        ) : (
                                            <CoursePage course={selectedCourse} />
                                        )
                                    ) : null}
                                </>)}
                            </>)}
                        </Box>
                    </Stack>
                </Grid>
            </Grid >
        </Container >
    );
}
