import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import * as React from 'react';
import { IChat } from '../api/ChatApi';
import OfficeHourInterface from '../component/OfficeHourInterface';
import OfficeHourMenu from '../component/OfficeHourMenu';
import ChatListContext, { ChatListInitialContext } from '../context/ChatListContext';
import SelectedChatContext, { SelectedChatInitialContext } from '../context/SelectedChatContext';
import TopMenuIndexContext, { MenuIndex } from '../context/TopMenuIndexContext';

export default function Chat() {

  const [selectedChat, setSelectedChat] = React.useState<IChat>(SelectedChatInitialContext.selectedChat)

  const [chatList, setChatList] = React.useState<IChat[]>(ChatListInitialContext.chatList);

  const { topMenuIndex, setTopMenuIndex } = React.useContext(TopMenuIndexContext);

  React.useEffect(() => {
    setTopMenuIndex(MenuIndex.OfficeHour)
  }, [])

  return (
    <SelectedChatContext.Provider value={{ selectedChat, setSelectedChat }}>
      <ChatListContext.Provider value={{ chatList, setChatList }}>
        <Grid container justifyContent="center">
          <Grid item sm={12} md={4} alignItems="center" justifyContent="center" className="secondaryBackground">
            <Container sx={{ mt: 1 }}>
              <OfficeHourMenu />
            </Container>
          </Grid>
          <Grid item sm={12} md={8} alignItems="center" justifyContent="center">
            <OfficeHourInterface />
          </Grid>
        </Grid>
      </ChatListContext.Provider>
    </SelectedChatContext.Provider>
  );
}
