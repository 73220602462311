import * as React from 'react';

// Individual exports
export enum MenuIndex {
  Home = 0,
  Lesson = 1,
  CustomSentence = 2,
  Conversation = 3,
  OfficeHour = 4,
  Registration = 5
}


export interface ITopMenuIndexContextType {
  topMenuIndex: MenuIndex;
  setTopMenuIndex: (topMenuIndex: MenuIndex) => void;
};

export const TopMenuIndexInitialContext = { topMenuIndex: MenuIndex.Home, setTopMenuIndex: () => null };

const TopMenuIndexContext = React.createContext<ITopMenuIndexContextType>(TopMenuIndexInitialContext);

export default TopMenuIndexContext;
