import { Button, Stack } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogPromptSelectNextDialog } from '../../api/DialogPromptApi';
import { INextDialog } from '../../api/NextDialog';
import { IUser, UserApi } from '../../api/UserApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';
import { RegistrationReminder, getAudioByText } from './DialogPromptComponent';

interface DialogPromptSelectNextDialogComponentProps {
    dialogPrompt: DialogPromptSelectNextDialog;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

const DialogPromptSelectNextDialogComponent: React.FC<DialogPromptSelectNextDialogComponentProps> =
    ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings = false }) => {

        const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
        const history = useHistory();
        const location = useLocation();        

        const [response, setResponse] = React.useState<string | null>(null);

        const [reminderMessage, setReminderMessage] = React.useState<string | null>(null);
        const [reminderMessageDone, setReminderMessageDone] = React.useState(false);
        const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
        const [chatBubbleResponseDone, setChatBubbleResponseDone] = React.useState(false);

        const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
        const [reminderAudio, setReminderAudio] = React.useState<Blob | null | undefined>(undefined);

        React.useEffect(() => {
            // clear promptInputComponent initially
            setPromptInputComponent(null);
            getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
            getAudioByText(history, setErrorMessage, RegistrationReminder, setReminderAudio)

            // what reminder message do we need? (if any)
            UserApi.get()
                .then(response => {
                    // Handle the response
                    const user: IUser = response.data;
                    if (user.email === undefined || user.email === null || user.read_only_email_validated == false) {
                        setReminderMessage(RegistrationReminder);
                    } else {
                        setReminderMessageDone(true);
                    }
                })
                .catch(err => {
                    setReminderMessageDone(true);
                    handleErrorMessage(err, setErrorMessage, history, location);
                });
        }, []);

        const handleButtonClick = (nextDialog: INextDialog) => {
            setResponse(nextDialog.response_text);
            history.push(`/lesson?dialogId=${nextDialog.next_dialog_id}`);
            // addDialog(nextDialog.next_dialog_id, true);
        }

        React.useEffect(() => {
            if (chatBubbleDone) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center' }}>
                        {dialogPrompt.next_dialog_list.map((nextDialog) => (
                            <Button variant="contained" color="primary" onClick={() => handleButtonClick(nextDialog)} sx={{ ml: 4 }}>
                                {nextDialog.button_text}
                            </Button>
                        ))}
                    </Stack>
                )
            }
        }, [chatBubbleDone]);

        React.useEffect(() => {
            if (chatBubbleResponseDone) {
                setDialogPromptComplete(dialogPrompt.id);
            }
        }, [chatBubbleResponseDone])

        return (
            <Stack>
                {reminderMessage && (
                    <ChatBubble
                        isTeacher={true}
                        message={reminderMessage}
                        messageAudio={reminderAudio ? reminderAudio : null}
                        showAvatar={showAvatar}
                        setChatBubbleDone={setReminderMessageDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                )}
                {reminderMessageDone && (
                    <ChatBubble
                        isTeacher={dialogPrompt.is_teacher}
                        message={dialogPrompt.prompt}
                        messageAudio={promptAudio ? promptAudio : null}
                        showAvatar={showAvatar}
                        setChatBubbleDone={setChatBubbleDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                )}
                {response ? (
                    <ChatBubble
                        isTeacher={!dialogPrompt.is_teacher}
                        message={response}
                        messageAudio={null} // no audio for student
                        showAvatar={showAvatar}
                        setChatBubbleDone={setChatBubbleResponseDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                ) : null}
            </Stack>
        );
    };

export default DialogPromptSelectNextDialogComponent;
