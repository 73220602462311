import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CourseApi, ICourse } from '../api/CourseApi';
import { ILesson, LessonApi } from '../api/LessonApi';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';

interface LessonFormProps {
    lessonId: number;
    onSubmit: (updatedLesson: ILesson) => void;
    onDelete: (lessonId: number) => void;
}

const LessonForm: React.FC<LessonFormProps> = ({ lessonId, onSubmit, onDelete }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [id, setId] = React.useState(-1);
    const [courseId, setCourseId] = React.useState(-1);
    const [courseList, setCourseList] = React.useState<ICourse[]>([]); // State to store course options
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState<string | null>('');
    const [isHidden, setIsHidden] = React.useState(false);
    const [orderIndex, setOrderIndex] = React.useState(0);
    const [showConfirmation, setShowConfirmation] = React.useState(false);

    React.useEffect(() => {
        const fetchCourseList = async () => {
            try {
                const response = await CourseApi.getAll();
                setCourseList(response.data);
            } catch (err: any) {
                handleErrorMessage(err, setErrorMessage, history, location);
            }
        };

        fetchCourseList();
    }, []);

    React.useEffect(() => {
        LessonApi.getById(lessonId)
            .then((response) => {
                const lesson = response.data;
                setId(lesson.id);
                setCourseId(lesson.course_id);
                setName(lesson.name);
                setDescription(lesson.description ? lesson.description : '');
                setIsHidden(lesson.is_hidden);
                setOrderIndex(lesson.order_index);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }, [lessonId]);

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => { setId(Number(e.target.value)); };
    const handleCourseIdChange = (e: React.ChangeEvent<HTMLInputElement>) => { setCourseId(Number(e.target.value)); };
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { setName(e.target.value); };
    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDescription(e.target.value); };
    const handleHiddenChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsHidden(e.target.checked); };
    const handleOrderIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => { setOrderIndex(Number(e.target.value)); };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const updatedLesson: ILesson = {
            id,
            course_id: courseId,
            order_index: orderIndex,
            name,
            description,
            is_hidden: isHidden
        };
        onSubmit(updatedLesson);
    };


    const handleDeleteConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setShowConfirmation(false);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmation(false);
        onDelete(lessonId);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
                <Typography variant="h5">
                    Edit Lesson
                </Typography>
                <input type="hidden" value={id} onChange={handleIdChange} />
                <TextField
                    label="Name"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={handleDescriptionChange}
                />
                <FormControl fullWidth>
                    <InputLabel>Parent Course Name</InputLabel>
                    <Select
                        value={courseId}
                        label="Parent Course Name"
                        onChange={e => setCourseId(Number(e.target.value))}
                        required
                    >
                        <MenuItem key={-1} value={-1} />
                        {courseList.map((course) => (
                            <MenuItem key={course.id} value={course.id} sx={{ fontWeight: course.is_hidden ? 200 : 400, opacity: course.is_hidden ? 0.4 : 1 }}>
                                {course.is_hidden && "[Hidden]"} {course.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={<Checkbox checked={isHidden} onChange={handleHiddenChange} />}
                    label="Hidden"
                />
                <TextField
                    label="Sequence Number"
                    value={orderIndex}
                    onChange={handleOrderIndexChange}
                    type="number"
                    required
                />
                <br />
                <Button type="submit" variant="contained">
                    Save
                </Button>
                <Button variant="contained" color="error" onClick={handleDeleteConfirmation}>
                    Delete
                </Button>
            </Stack>
            <Dialog open={showConfirmation} onClose={handleDeleteCancel}>
                <DialogTitle>Delete Lesson</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this lesson?</Typography>
                    <Typography>{name}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default LessonForm;
