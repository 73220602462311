import axios from 'axios';
import { REST_HOSTNAME } from '../Config';
import { ScoreGrade } from '../Score';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface ISentenceScore {
    id: number;
    text: string;
    text_pinyin: string[];
    score: number[];
    score_grade: ScoreGrade[];
    likely_text: string;     // endpoint has logic to select max/jaro/combine
    likely_pinyin: string[]; // then put the results in 'likely'
    likely_score: number[];  // currently, likely_* same as other variables with new scoring algorithm
    likely_score_grade: ScoreGrade[];
    passing_grade: string;
    audioData: Blob | null;
    first_name: string;
    sentence_id: number;
}

export const ScoreApi = {
    getScore: function (sentenceId: number, audio: any) {
        return axiosInstance.request<ISentenceScore>({
            method: "POST",
            url: `/sentence_score/${sentenceId}`,
            data: audio
        });
    },
    getScoreUrl: function (sentenceScoreId: number) {
        return axiosInstance.request<string>({
            method: "GET",
            url: `/sentence_score_url/${sentenceScoreId}`
        });
    },
    getPublicScore: function (publicSentenceScoreId: number) {
        return axiosInstance.request<ISentenceScore>({
            method: "GET",
            url: `/s/${publicSentenceScoreId}`
        });
    }
}   