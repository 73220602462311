import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Menu from './Menu';
import ErrorMessageContext, { ErrorMessageInitialContext } from './context/ErrorMessageContext';
import IsLoggedInContext, { IsLoggedInInitialContext } from './context/IsLoggedInContext';
import TopMenuIndexContext, { TopMenuIndexInitialContext } from './context/TopMenuIndexContext';
import Comment from './page/Comment';
import Conversation from './page/Conversation';
import CreateLesson from './page/CreateLesson';
import CustomSentence from './page/CustomSentence';
import DialogPlan from './page/DialogPlan';
import Home from './page/Home';
import Investor from './page/Investor';
import Lesson from './page/Lesson';
import Login from './page/Login';
import OfficeHour from './page/OfficeHour';
import PublicSentenceScore from './page/PublicSentenceScore';
import Registration from './page/Registration';
import ResetPassword from './page/ResetPassword';
import ResetPasswordEmail from './page/ResetPasswordEmail';
import TopReferrer from './page/TopReferrer';
import ValidateEmail from './page/ValidateEmail';

export default function App() {

  const [errorMessage, setErrorMessage] = React.useState(ErrorMessageInitialContext.errorMessage);
  const [isLoggedIn, setIsLoggedIn] = React.useState(IsLoggedInInitialContext.isLoggedIn);
  const [topMenuIndex, setTopMenuIndex] = React.useState(TopMenuIndexInitialContext.topMenuIndex);

  return (
    <ErrorMessageContext.Provider value={{ errorMessage, setErrorMessage }}>
      <IsLoggedInContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
        <TopMenuIndexContext.Provider value={{ topMenuIndex, setTopMenuIndex }}>
          <Router>
            <Switch>
              <Route style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Menu />
                <Switch style={{ minHeight: '100vh', minWidth: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/sentence" component={CustomSentence} />
                  <Route exact path="/chat" component={Conversation} />
                  <Route exact path="/lesson" component={Lesson} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/registration" component={Registration} />
                  <Route exact path="/validate_email" component={ValidateEmail} />
                  <Route exact path="/reset_password_email" component={ResetPasswordEmail} />
                  <Route exact path="/reset_password" component={ResetPassword} />
                  <Route exact path="/top_referrer" component={TopReferrer} />
                  <Route exact path="/comment" component={Comment} />
                  <Route exact path="/office_hour" component={OfficeHour} />

                  <Route exact path="/create_lesson" component={CreateLesson} />
                  <Route exact path="/dialog_plan" component={DialogPlan} />

                  <Route exact path="/investor" component={Investor} />

                  <Route path="/s/:id" component={PublicSentenceScore} />
                </Switch>
              </Route>
            </Switch>
          </Router>
        </TopMenuIndexContext.Provider>
      </IsLoggedInContext.Provider>
    </ErrorMessageContext.Provider>
  );
}
