import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, InputLabel, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogPlanLlmPrompt } from '../../api/DialogPlanLlmPromptApi';
import { DialogPromptImageSelectSpeak } from '../../api/DialogPromptApi';
import { IVocabularyWord } from '../../api/VocabularyWordApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import { arrayBufferToBase64 } from '../dialogPrompt/DialogPromptMatchWordPictureForm';

interface DialogPlanLlmPromptImageSelectSpeakGeneratedProps {
    dialogPrompt?: DialogPromptImageSelectSpeak; // if llmPrompt was generated
    onSubmit?: (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete?: (dialogPlanLlmPromptId: number) => void;

    isRegenerating?: boolean;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
}

const DialogPlanLlmPromptImageSelectSpeakGenerated: React.FC<DialogPlanLlmPromptImageSelectSpeakGeneratedProps> = ({ dialogPrompt, onSubmit, onDelete, isRegenerating, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState<string>('');
    // const [correctVocabularyWord, setCorrectVocabularyWord] = React.useState<string>('');
    // const [correctVocabularyWordImage, setCorrectVocabularyWordImage] = React.useState<string>('');
    const [vocabularyWordList, setVocabularyWordList] = React.useState<IVocabularyWord[]>([]);
    // const [displayPinyin, setDisplayPinyin] = React.useState<boolean | null>(null);

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
    const [wordIndexToDelete, setWordIndexToDelete] = React.useState<number | null>(null);

    React.useEffect(() => {
        if (dialogPrompt) {
            setDialogPromptFields(dialogPrompt);
        }
    }, [dialogPrompt])

    function setDialogPromptFields(dialogPrompt: DialogPromptImageSelectSpeak) {
        setTeacherChinesePrompt(dialogPrompt.teacher_chinese_prompt);
        // setCorrectVocabularyWord(dialogPrompt.correct_vocabulary_word.word);
        // if (dialogPrompt.correct_vocabulary_word.image) {
        //     setCorrectVocabularyWordImage(dialogPrompt.correct_vocabulary_word.image);
        // }
        setVocabularyWordList(dialogPrompt.vocabulary_word_list);
        // setDisplayPinyin(dialogPrompt.display_pinyin);
    }

    // update dialog prompt
    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); if (dialogPrompt) dialogPrompt.teacher_chinese_prompt = e.target.value };
    // const handleCorrectVocabularyWordChange = (e: React.ChangeEvent<HTMLInputElement>) => { setCorrectVocabularyWord(e.target.value); if (dialogPrompt) dialogPrompt.correct_vocabulary_word.word = e.target.value };

    // const handleCorrectImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

    //     const files = e.target.files;
    //     if (files && files.length > 0) {
    //         const file = files[0];
    //         const reader = new FileReader();

    //         reader.onloadend = () => {
    //             // Code to handle the reader result
    //             let base64String: string;
    //             let result = reader.result;
    //             if (typeof result === 'string') {
    //                 base64String = result;
    //             } else if (result instanceof ArrayBuffer) {
    //                 base64String = arrayBufferToBase64(result)
    //             } else {
    //                 base64String = ""
    //             }
    //             setCorrectVocabularyWordImage(base64String);
    //             if (dialogPrompt) dialogPrompt.correct_vocabulary_word.image = base64String
    //         };

    //         reader.readAsDataURL(file);
    //     }
    // };
    // const handleDeleteCorrectImage = () => {
    //     // Set the wordImage state to an empty string to remove the image
    //     setCorrectVocabularyWordImage('');
    //     if (dialogPrompt) dialogPrompt.correct_vocabulary_word.image = ''
    // };

    const handleAddVocabularyWord = () => {
        const newVocabularyWord: IVocabularyWord = {
            id: 0,
            word: '',
            image: '',
            image_url: '',
            audio_sentence: '',
            audio_sentence_id: 0,
            dialog_prompt_id: 0,
            teacher_english_prompt: '',
            teacher_chinese_prompt: '',
            teacher_chinese_prompt_sentence_id: 0,
            student_response_prompt: '<Answer/>',
            student_response_prompt_sentence_id: 0,
            word_pinyin: [],
            audio_sentence_pinyin: [],
            teacher_chinese_prompt_pinyin: []
        };
        if (vocabularyWordList) {
            setVocabularyWordList([...vocabularyWordList, newVocabularyWord]);
        } else {
            setVocabularyWordList([newVocabularyWord]);
        }
        if (dialogPrompt) {
            dialogPrompt.vocabulary_word_list = [
                ...dialogPrompt.vocabulary_word_list,
                newVocabularyWord
            ];
        }
    };

    const handleVocabularyWordChange = (
        index: number,
        field: string,
        value: string
    ) => {
        setVocabularyWordList((prevWords) =>
            prevWords.map((word, i) => {
                if (i === index) {
                    return { ...word, [field]: value };
                }
                return word;
            })
        );
        if (dialogPrompt) dialogPrompt.vocabulary_word_list[index].word = value
    };
    const handleVocabularyWordImageUpload = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Code to handle the reader result
                let base64String: string;
                let result = reader.result;
                if (typeof result === 'string') {
                    base64String = result;
                } else if (result instanceof ArrayBuffer) {
                    base64String = arrayBufferToBase64(result)
                } else {
                    base64String = ""
                }
                setVocabularyWordList((prevWords) =>
                    prevWords.map((word: IVocabularyWord, i) => {
                        if (i == index) {
                            return { ...word, image: base64String }
                        }
                        return word;
                    })
                )
                if (dialogPrompt) dialogPrompt.vocabulary_word_list[index].image = base64String
            };

            reader.readAsDataURL(file);
        }
    };

    // const handleDisplayPinyinChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDisplayPinyin(e.target.checked); };

    const handleDeleteVocabularyWord = (index: number) => {
        setWordIndexToDelete(index);
        setDeleteConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {
        if (wordIndexToDelete !== null) {
            setVocabularyWordList(prevList => prevList.filter((word, index) => index !== wordIndexToDelete));
            if (dialogPrompt) dialogPrompt.vocabulary_word_list.splice(wordIndexToDelete, 1);
        }
        setWordIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    const handleCancelDelete = () => {
        setWordIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    return (<>
        <Stack spacing={4} sx={{ width: '100%', margin: '0 auto', pt: 2, pb: 4 }}>
            <div style={{ width: '100%' }}>
                <TextField
                    label="Chinese Prompt (optional)"
                    value={teacherChinesePrompt}
                    onChange={handleTeacherChinesePromptChange}
                    multiline
                    rows={5}
                    fullWidth
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted
                </Typography>
                {/* Hidden spacer button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        disabled={true}
                        sx={{ opacity: 0 }}
                    >
                        Regenerate
                    </Button>
                </Box>
            </div>
            {/* <FormControlLabel
                control={<Checkbox checked={displayPinyin ? displayPinyin : false} onChange={handleDisplayPinyinChange} />}
                label="Display answers with Pinyin"
            /> */}
            <h3>Answer List</h3>
            {/* <TextField
                label="Word"
                value={correctVocabularyWord}
                onChange={handleCorrectVocabularyWordChange}
                multiline
                rows={5}
            /> */}
            {/* Hidden spacer button */}
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
            <Box>
                <InputLabel htmlFor="image-upload">Correct Answer Image</InputLabel>
                <Input
                    type="file"
                    id="image-upload"
                    inputProps={{ accept: "image/*" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCorrectImageUpload(e)}
                />
            </Box> */}
            {/* Display the image or a placeholder with a fixed height of 300px */}
            {/* <Box
                sx={{
                    height: '300px',
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically (image first, button below)
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={correctVocabularyWordImage}
                    alt="Uploaded"
                    style={{
                        maxHeight: '200px', maxWidth: '200px',
                        height: 'auto', width: 'auto',
                        margin: '0 auto', // Add this line to center the image horizontally
                        display: 'block', // Add this line to ensure the margin works correctly
                    }}
                /> */}
            {/* Add the delete image button */}
            {/* {(correctVocabularyWordImage && correctVocabularyWordImage != "broken") && (
                    <Box sx={{ marginTop: 2 }}>
                        <Button variant="outlined" onClick={handleDeleteCorrectImage}>
                            Delete Image
                        </Button>
                    </Box>
                )}
            </Box> */}
        </Stack>


        {vocabularyWordList && vocabularyWordList.map((word, index) => {
            // Assuming word.image contains the ArrayBuffer
            return (

                <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }} key={index}>
                    {/* <h3>Answer List</h3> */}
                    <TextField
                        label="Answer"
                        value={word.word}
                        onChange={(e) =>
                            handleVocabularyWordChange(index, 'word', e.target.value)
                        }
                    />
                    <Box>
                        <InputLabel htmlFor="image-upload">Answer Image</InputLabel>
                        <Input
                            type="file"
                            id="image-upload"
                            inputProps={{ accept: "image/*" }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleVocabularyWordImageUpload(index, e)}
                        />
                    </Box>
                    {/* Display the image or a placeholder with a fixed height of 300px */}
                    <Box
                        sx={{
                            height: '300px',
                            display: 'flex',
                            flexDirection: 'column', // Stack items vertically (image first, button below)
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {word.image && (
                            <img
                                src={word.image}
                                alt="Uploaded"
                                style={{
                                    maxHeight: '200px', maxWidth: '200px',
                                    height: 'auto', width: 'auto',
                                    margin: '0 auto', // Add this line to center the image horizontally
                                    display: 'block', // Add this line to ensure the margin works correctly
                                }}
                            />
                        )}
                        {/* {word.image && word.image != "broken" && (
                            TODO add delete image button? needed in original DialgoPromptImageSelectSpeakForm also
                        )} */}
                    </Box>
                    <TextField
                        label="Student Response Prompt"
                        value={word.student_response_prompt}
                        onChange={(e) =>
                            handleVocabularyWordChange(index, 'student_response_prompt', e.target.value)
                        }
                    />
                    <TextField
                        label="Teacher English Prompt"
                        value={word.teacher_english_prompt}
                        onChange={(e) =>
                            handleVocabularyWordChange(index, 'teacher_english_prompt', e.target.value)
                        }
                    />
                    <Box sx={{ marginTop: 2 }}>
                        <Button variant="outlined" onClick={() => handleDeleteVocabularyWord(index)}>
                            Delete Answer
                        </Button>
                    </Box>
                </Stack>
            )
        })}
        <Button variant="outlined" onClick={handleAddVocabularyWord}>
            Add Answer
        </Button>
        <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this answer {wordIndexToDelete !== null && (<>{vocabularyWordList[wordIndexToDelete].word}</>)} ?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDelete} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>);
};

export default DialogPlanLlmPromptImageSelectSpeakGenerated;