import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isJwtLoggedIn } from '../Menu';
import '../style/home_styles.css';

import columbiaLogo from '../asset/c_uni.png';
import arrowDownIcon from '../asset/dropdown_arrow.png';
import gwLogo from '../asset/gw_uni.png';
import introImage from '../asset/intro.png';
import vnLogo from '../asset/vn_uni.png';
import yxImage from '../asset/yuxin.png';
import zqsImage from '../asset/zhongqi_shi.png';

import hurdlesImage from '../asset/hurdles.png';
import hurdles1Icon from '../asset/hurdles1.png';
import hurdles2Icon from '../asset/hurdles2.png';
import whyImage from '../asset/why.png';
import why1Icon from '../asset/why1.png';
import why2Icon from '../asset/why2.png';

import oralIcon1 from '../asset/oral1.png';
import oralIcon2 from '../asset/oral2.png';
import oralIcon3 from '../asset/oral3.png';

import facebookIcon from '../asset/facebook-icon.png';
import instagramIcon from '../asset/instagram-icon.png';
import twitterIcon from '../asset/twitter-icon.png';
import youtubeIcon from '../asset/youtube-icon.png';

interface ShowAdditionalInfo {
    zhongqi: boolean;
    yuxin: boolean;
}

export default function Investor() {
    const location = useLocation();
    const [isLoggedIn, setLoggedIn] = React.useState(false);

    const [fadeIn, setFadeIn] = useState(false);

    // Handle scroll-triggered fade-in effect
    const handleScroll = () => {
        const sections = document.querySelectorAll('.section-fade');
        sections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                section.classList.add('visible');
            } else {
                section.classList.remove('visible');
            }
        });
    };

    React.useEffect(() => {
        if (isJwtLoggedIn()) {
            setLoggedIn(true)
        }

    }, []);
    React.useEffect(() => {
        // Function to get the value of the "refer" parameter from the URL
        const getQueryParam = (param: string) => {
            return new URLSearchParams(location.search).get(param);
        };

        // Retrieve the "refer" parameter value
        const referValue = getQueryParam('r');

        // If "refer" parameter exists, save it to localStorage
        if (referValue) {
            localStorage.setItem('r', referValue);
        }

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Initial call to handle scroll, so the effect happens right when the page loads
        handleScroll();

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    const [showAdditionalInfo, setShowAdditionalInfo] = useState<ShowAdditionalInfo>({
        zhongqi: false,
        yuxin: false,
    });

    // Toggle additional info visibility and arrow rotation
    const toggleInfo = (teacher: 'zhongqi' | 'yuxin') => {
        setShowAdditionalInfo((prevState) => ({
            ...prevState,
            [teacher]: !prevState[teacher],
        }));
    };

    return (
        <div>
            {/* <header className="header">
                <div className="logo">
                    <h1>ShiHouEri</h1>
                </div>
                <nav className="nav">
                    <a href="#home" className="nav-item">
                        <div className="nav-icon-container">
                            <img src="../asset/home.png" alt="Home Icon" className="nav-icon" />
                        </div>
                        <div className="nav-text">Home</div>
                    </a>
                    <a href="#team" className="nav-item">
                        <div className="nav-icon-container">
                            <img src="../asset/team.png" alt="Team Icon" className="nav-icon" />
                        </div>
                        <div className="nav-text">Team</div>
                    </a>
                    <a href="#advantages" className="nav-item">
                        <div className="nav-icon-container">
                            <img src="../asset/advantages.png" alt="Advantages Icon" className="nav-icon" />
                        </div>
                        <div className="nav-text">Advantages</div>
                    </a>
                </nav>
                <div className="login">
                    <button className="login-btn">Log In</button>
                </div>
            </header> */}

            {/* Introduction */}
            <section className="intro-section section-fade">
                <div className="text-side">
                    <h2>High <span className="blue-text">quality</span> content from university faculty</h2>
                    <p>Effective listening and speaking exercises proven by research and studies</p>
                    <div className="get-started">
                        <button className="get-started-btn">Get Started</button>
                    </div>
                </div>
                <div className="image-side">
                    {/* Use imported image */}
                    <img src={introImage} alt="Image with a Person with thumbs up and positive reviews surrounding him" className="person-image" />
                </div>
            </section>

            {/* Meet Teacher Section */}
            <section className="teachers-section section-fade">
                <div className="teachers-section-header">
                    <h2>Meet Our Teachers</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div>
                <div className="teacher-profiles">
                    {/* Left Teacher */}
                    <div className="teacher-profile">
                        <img src={zqsImage} alt="Professor Zhongqi Shi" className="teacher-image" />
                        <h3>Professor Zhongqi Shi</h3>
                        <ul>
                            <li>Professor of Chinese PhD in Cognitive Studies in Education</li>
                            <li>Columbia Faculty</li>
                            <li>Columbia Associate Director</li>
                            <li>Columbia Language Author of 6 textbooks</li>
                        </ul>

                        <div className="see-more-dropdown" onClick={() => toggleInfo('zhongqi')}>
                            <span>See More</span>
                            <img
                                src={arrowDownIcon}
                                alt="Arrow Down"
                                className={`arrow-icon ${showAdditionalInfo.zhongqi ? 'rotated' : ''}`}
                            />
                        </div>
                        {showAdditionalInfo.zhongqi && (
                            <div className="additional-info">
                                <p>Additional details about Professor Zhongqi Shi go here...</p>
                            </div>
                        )}

                        <div>
                            <img src={columbiaLogo} alt="Columbia University Logo" className="university-logo" />
                        </div>
                    </div>

                    {/* Right Teacher */}
                    <div className="teacher-profile">
                        <img src={yxImage} alt="Yuxin Hou" className="teacher-image" />
                        <h3>Yuxin Hou</h3>
                        <ul>
                            <li>Teacher of Chinese Language MA in Curriculum & Instruction (Reading specialist)</li>
                            <li>GWU CTCSOL & TCSOL certificate BA in English Literature</li>
                        </ul>

                        <div className="see-more-dropdown" onClick={() => toggleInfo('yuxin')}>
                            <span>See More</span>
                            <img
                                src={arrowDownIcon}
                                alt="Arrow Down"
                                className={`arrow-icon ${showAdditionalInfo.yuxin ? 'rotated' : ''}`}
                            />
                        </div>
                        {showAdditionalInfo.yuxin && (
                            <div className="additional-info">
                                <p>Additional details about Yuxin Hou go here...</p>
                            </div>
                        )}

                        <div>
                            <img src={columbiaLogo} alt="Columbia University Logo" className="university-logo" />
                            <img src={gwLogo} alt="The George Washington University Logo" className="university-logo" />
                            <img src={vnLogo} alt="University Logo" className="university-logo" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Section 3: Hurdles with Language Learning Apps */}
            <section className="hurdles-section section-fade">
                <div className="hurdles-left">
                    <h2>
                        <span className="hurdles-icon"></span>
                        Hurdles with Language Learning Apps
                    </h2>

                    <div className="hurdle">
                        <div className="hurdle-header">
                            <div className="hurdle-icon">
                                <img src={hurdles1Icon} alt="Exercise Icon" />
                            </div>
                            <h3>Exercises</h3>
                        </div>
                        <p>
                            Over-reliance on gaming elements, although good at retaining users, often fail to deliver meaningful progress.
                        </p>
                    </div>

                    <div className="hurdle">
                        <div className="hurdle-header">
                            <div className="hurdle-icon">
                                <img src={hurdles2Icon} alt="Quality Icon" />
                            </div>
                            <h3>Quality of Instruction</h3>
                        </div>
                        <p>
                            Inadequate instructional quality and absence of immediate feedback: Many language learning apps lack comprehensive guidance and timely responses, impeding effective learning.
                        </p>
                    </div>
                </div>

                <div className="hurdles-right">
                    <img src={hurdlesImage} alt="Hurdles Image" className="spanning-image" />
                </div>
            </section>

            {/* Section 4: Why we will succeed */}
            <section className="why-section section-fade">
                <div className="why-left">
                    <img src={whyImage} alt="Why Image" className="spanning-image" />
                </div>

                <div className="why-right">
                    <h2>
                        <span className="why-icon"></span>
                        Why we will succeed
                    </h2>

                    <div className="why">
                        <div className="why-header">
                            <div className="why-icon">
                                <img src={why1Icon} alt="Speak & Listen Icon" />
                            </div>
                            <h3>Speaking and Listening with ML and LLMs</h3>
                        </div>
                        <p>
                            Use ML and LLMs to align with language learning theory and emphasize speaking and listening skills.
                        </p>
                    </div>

                    <div className="why">
                        <div className="why-header">
                            <div className="why-icon">
                                <img src={why2Icon} alt="Taught Icon" />
                            </div>
                            <h3>Taught by Ivy Professors</h3>
                        </div>
                        <p>
                            A comprehensive curriculum designed by language experts and promoting real-life application.
                        </p>
                    </div>
                </div>
            </section>

            {/* Oral Communication Section */}
            <section className="oral-section section-fade">
                <div className="oral-section-header">
                    <h2>People acquire languages</h2>
                    <p>Though listening and speaking exercise, not typing or clicking.</p>
                </div>

                {/* Three Tiles Section */}
                <div className="tile-section">
                    {/* Tile 1 */}
                    <div className="tile">
                        <img src={oralIcon1} alt="Tile Image 1" className="tile-image" />
                        <h3>Oral Communication</h3>
                        <p>
                            Oral communication plays a vital role in language acquisition compared to typing or clicking,
                            which may not provide the same depth of language immersion or active engagement.
                        </p>
                    </div>

                    {/* Tile 2 */}
                    <div className="tile">
                        <img src={oralIcon2} alt="Tile Image 2" className="tile-image" />
                        <h3>Oral Communication</h3>
                        <p>
                            Oral communication plays a vital role in language acquisition compared to typing or clicking,
                            which may not provide the same depth of language immersion or active engagement.
                        </p>
                    </div>

                    {/* Tile 3 */}
                    <div className="tile">
                        <img src={oralIcon3} alt="Tile Image 3" className="tile-image" />
                        <h3>Oral Communication</h3>
                        <p>
                            Oral communication plays a vital role in language acquisition compared to typing or clicking,
                            which may not provide the same depth of language immersion or active engagement.
                        </p>
                    </div>
                </div>
            </section>

            {/* Banner Section */}
            <section className="banner-section section-fade">
                <div className="banner-card">
                    <div className="banner-card-content">
                        <h2 className="banner-header">Language Learning is Effortless</h2>
                        <p className="banner-subheader">
                            With expert professor guidance and research-backed lesson design
                        </p>

                        {/* Split section inside banner */}
                        <div className="banner-split-section">
                            <div className="split-left">
                                <h3>John Hattie (2009)</h3>
                                <p>
                                    Research by John Hattie (2009) on "visible learning" emphasizes the importance of expert
                                    teaching and instructional design in achieving effective learning outcomes.
                                </p>
                            </div>
                            <div className="split-right">
                                <h3>ACTFL and ECML</h3>
                                <p>
                                    Institutions like the American Council on the Teaching of Foreign Languages (ACTFL) and the
                                    European Centre for Modern Languages (ECML) emphasize the role of structured guidance and
                                    research-based methodologies in language education.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer Section */}
            <footer className="footer">
                <div className="footer-left">
                    <div className="footer-brand">
                        <h1>ShiHouEri</h1>
                        <p>Work management platform teams use to stay</p>
                    </div>
                    <div className="footer-social">
                        <a href="#" className="social-icon">
                            <img src={facebookIcon} alt="Facebook" className="social-icon-img" />
                        </a>
                        <a href="#" className="social-icon">
                            <img src={twitterIcon} alt="Twitter" className="social-icon-img" />
                        </a>
                        <a href="#" className="social-icon">
                            <img src={instagramIcon} alt="Instagram" className="social-icon-img" />
                        </a>
                        <a href="#" className="social-icon">
                            <img src={youtubeIcon} alt="YouTube" className="social-icon-img" />
                        </a>
                    </div>
                </div>

                <div className="footer-right">
                    <div className="footer-column">
                        <h3>Product</h3>
                        <ul>
                            <li><a href="#">Social Media Inbox</a></li>
                            <li><a href="#">Social Media Publishing</a></li>
                            <li><a href="#">Social Media Monitoring</a></li>
                            <li><a href="#">Social Media Reporting</a></li>
                            <li><a href="#">For Agencies</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3>Product Features</h3>
                        <ul>
                            <li><a href="#">What's New</a></li>
                            <li><a href="#">Status</a></li>
                            <li><a href="#">Beta</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3>Company</h3>
                        <ul>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Our Customers</a></li>
                            <li><a href="#">Why Us</a></li>
                            <li><a href="#">Pricing</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3>Support</h3>
                        <ul>
                            <li><a href="#">Quick Start Guide</a></li>
                            <li><a href="#">Customer Support</a></li>
                            <li><a href="#">Platform Support</a></li>
                            <li><a href="#">Platform Status</a></li>
                            <li><a href="#">Talk to Sales</a></li>
                        </ul>
                    </div>
                </div>
            </footer>

        </div>
    );
}