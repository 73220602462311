import { Button, Stack } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogApi } from '../../api/DialogApi';
import { DialogPromptStartNextLesson } from '../../api/DialogPromptApi';
import { LessonApi } from '../../api/LessonApi';
import { IUser, UserApi } from '../../api/UserApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';
import { LastLessonOfClass, RegistrationReminder } from './DialogPromptComponent';

interface DialogPromptStartNextLessonComponentProps {
    dialogPrompt: DialogPromptStartNextLesson;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

function findNext(list: any[], currentId: number) {
    const currentDialogIndex = list.findIndex(item => item.id === currentId);
    if (currentDialogIndex == -1) {
        throw Error("Unable to find id " + currentId + " in list " + list.toString);
    }
    // Dialog ID found in the list
    if (currentDialogIndex === list.length - 1) {
        return -1
    } else {
        // Get the next dialog ID
        const nextDialogId = list[currentDialogIndex + 1].id;
        return nextDialogId
    }
}

const DialogPromptStartNextLessonComponent: React.FC<DialogPromptStartNextLessonComponentProps> =
    ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings = false }) => {

        const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
        const history = useHistory();
    const location = useLocation();        

        const [hasNextLesson, setHasNextLesson] = React.useState<boolean>(true);

        const [response, setResponse] = React.useState<string | null>(null);

        const [reminderMessage, setReminderMessage] = React.useState<string | null>(null);
        const [reminderMessageDone, setReminderMessageDone] = React.useState(false);
        const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
        const [chatBubbleResponseDone, setChatBubbleResponseDone] = React.useState(false);

        const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
        const [registrationReminderAudio, setRegistrationReminderAudio] = React.useState<Blob | null | undefined>(undefined);
        const [lastLessonAudio, setLastLessonAudio] = React.useState<Blob | null | undefined>(undefined);

        React.useEffect(() => {
            // clear promptInputComponent initially
            setPromptInputComponent(null);

            // what reminder message do we need? (if any)
            UserApi.get()
                .then(response => {
                    // Handle the response
                    const user: IUser = response.data;
                    if (user.email === undefined || user.email === null || user.read_only_email_validated == false) {
                        setReminderMessage(RegistrationReminder);
                    } else {
                        setReminderMessageDone(true);
                    }
                })
                .catch(err => {
                    setReminderMessageDone(true);
                    handleErrorMessage(err, setErrorMessage, history, location);
                });
        }, []);

        const handleYesButtonClick = async () => {
            setResponse(dialogPrompt.yes_response);

            try {
                // get dialog
                const dialogResponse = await DialogApi.getById(dialogPrompt.dialog_id);
                const dialog = dialogResponse.data;

                // get all dialogs in this lesson
                const dialogListResponse = await DialogApi.getAll(dialog.lesson_id);
                const dialogList = dialogListResponse.data.filter(dialog => dialog.is_hidden === false);

                // get next dialog id
                let nextDialogId = findNext(dialogList, dialogPrompt.dialog_id);
                if (nextDialogId < 0) {
                    // we are in last dialog of lesson

                    // get lesson
                    const lessonResponse = await LessonApi.getById(dialog.lesson_id);
                    const lesson = lessonResponse.data;

                    // get all lessons in course
                    const lessonListResponse = await LessonApi.getAll(lesson.course_id);
                    const lessonList = lessonListResponse.data.filter(lesson => lesson.is_hidden === false);

                    // get next lesson
                    let nextLessonId = findNext(lessonList, dialog.lesson_id);
                    if (nextLessonId >= 0) {
                        // get first dialog of next lesson
                        const nextDialogListResponse = await DialogApi.getAll(nextLessonId);
                        const nextDialogList = nextDialogListResponse.data.filter(dialog => dialog.is_hidden === false);
                        nextDialogId = nextDialogList[0].id
                    }
                }

                if (nextDialogId >= 0) {
                    history.push(`/lesson?dialogId=${nextDialogId}`);
                    // addDialog(nextDialogId);
                } else {
                    setHasNextLesson(false);

                }
            } catch (err) {
                console.error("Error getting lesson" + err)
                handleErrorMessage(err, setErrorMessage, history, location);
            }
        }

        const handleNoButtonClick = () => {
            setResponse(dialogPrompt.no_response);
        }
        React.useEffect(() => {
            if (chatBubbleDone) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center' }}  >
                        <Button variant="contained" color="secondary" onClick={() => handleNoButtonClick()} sx={{ ml: 4 }}>
                            {dialogPrompt.no_button_text}
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleYesButtonClick()} sx={{ ml: 4 }}>
                            {dialogPrompt.yes_button_text}
                        </Button>
                    </Stack>

                )
            }
        }, [chatBubbleDone]);

        React.useEffect(() => {
            if (chatBubbleResponseDone) {
                setDialogPromptComplete(dialogPrompt.id);
            }
        }, [chatBubbleResponseDone])

        return (
            <Stack>
                {reminderMessage && (
                    <ChatBubble
                        isTeacher={true}
                        message={reminderMessage}
                        messageAudio={registrationReminderAudio ? registrationReminderAudio : null}
                        showAvatar={showAvatar}
                        setChatBubbleDone={setReminderMessageDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                )}
                {reminderMessageDone && (
                    <ChatBubble
                        isTeacher={dialogPrompt.is_teacher}
                        message={dialogPrompt.prompt}
                        messageAudio={promptAudio ? promptAudio : null}
                        showAvatar={showAvatar}
                        setChatBubbleDone={setChatBubbleDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                )}
                {response ? (
                    <ChatBubble
                        isTeacher={!dialogPrompt.is_teacher}
                        message={response}
                        messageAudio={null} // no audio for student
                        showAvatar={showAvatar}
                        setChatBubbleDone={setChatBubbleResponseDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                ) : null}
                {hasNextLesson == false && chatBubbleResponseDone ? (
                    <ChatBubble
                        isTeacher={true}
                        message={LastLessonOfClass}
                        messageAudio={lastLessonAudio ? lastLessonAudio : null}
                        showAvatar={showAvatar}
                        setChatBubbleDone={setChatBubbleResponseDone}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                    />
                ) : null}

            </Stack>
        );
    };

export default DialogPromptStartNextLessonComponent;
