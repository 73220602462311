import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, InputLabel, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptImageSelectSpeak } from "../../api/DialogPromptApi";
import { IVocabularyWord } from "../../api/VocabularyWordApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";
import { arrayBufferToBase64 } from "./DialogPromptMatchWordPictureForm";

interface DialogPromptImageSelectSpeakFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    isHidden: boolean;
    yesResponse: string;
    noResponse: string;
    // imageSource: string;
    // imageSourceUrl: string;
}

export const DialogPromptImageSelectSpeakForm: React.FC<DialogPromptImageSelectSpeakFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, yesResponse, noResponse }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState<string>('');
    // const [correctVocabularyWordId, setCorrectVocabularyWordId] = React.useState<number>(0);
    // const [correctVocabularyWord, setCorrectVocabularyWord] = React.useState<string>('');
    // const [correctVocabularyWordImage, setCorrectVocabularyWordImage] = React.useState<string | null>('');
    // const [correctVocabularyWordImageUrl, setCorrectVocabularyWordImageUrl] = React.useState<string | null>('');
    const [vocabularyWordList, setVocabularyWordList] = React.useState<IVocabularyWord[]>([]);
    // const [displayPinyin, setDisplayPinyin] = React.useState<boolean | null>(false)


    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
    const [wordIndexToDelete, setWordIndexToDelete] = React.useState<number | null>(null);

    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    // const handleCorrectVocabularyWordChange = (e: React.ChangeEvent<HTMLInputElement>) => { setCorrectVocabularyWord(e.target.value); };
    // const handleDisplayPinyinChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDisplayPinyin(e.target.checked); };

    React.useEffect(() => {
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
        DialogPromptApi.getById(oldDialogPromptId)
            .then((response) => {
                const oldDialogPrompt = response.data as DialogPromptImageSelectSpeak;
                setDialogPromptFields(oldDialogPrompt);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptImageSelectSpeak)
        // }
    }, [oldDialogPromptId])


    function setDialogPromptFields(dialogPrompt: DialogPromptImageSelectSpeak) {
        if (dialogPrompt.class_name == "DialogPromptImageSelectSpeakModel") {
            setTeacherChinesePrompt(dialogPrompt.teacher_chinese_prompt);
            // setCorrectVocabularyWordId(dialogPrompt.correct_vocabulary_word_id);
            // setCorrectVocabularyWord(dialogPrompt.correct_vocabulary_word.word);
            // setCorrectVocabularyWordImage(dialogPrompt.correct_vocabulary_word.image);
            // setCorrectVocabularyWordImageUrl(dialogPrompt.correct_vocabulary_word.image_url);
            setVocabularyWordList(dialogPrompt.vocabulary_word_list);
            // setDisplayPinyin(dialogPrompt.display_pinyin);
        } else {
            setTeacherChinesePrompt('');
            // setCorrectVocabularyWordId(0);
            // setCorrectVocabularyWord('');
            // setCorrectVocabularyWordImage('');
            // setCorrectVocabularyWordImageUrl('');
            setVocabularyWordList([]);
            // setDisplayPinyin(null)
        }
        setDeleteConfirmationOpen(false);
        setWordIndexToDelete(null);
    }

    React.useEffect(() => {
        // const word = {
        //     id: correctVocabularyWordId,
        //     word: correctVocabularyWord,
        //     image: correctVocabularyWordImage,
        //     image_url: correctVocabularyWordImageUrl,
        //     audio_sentence: '',
        //     audio_sentence_id: 0,
        //     dialog_prompt_id: dialogId,
        //     teacher_english_prompt: '',
        //     teacher_chinese_prompt: '',
        //     teacher_chinese_prompt_sentence_id: 0,
        //     student_response_prompt: '',
        //     student_response_prompt_sentence_id: 0,
        //     word_pinyin: [],
        //     audio_sentence_pinyin: [],
        //     teacher_chinese_prompt_pinyin: []
        // }
        const dialogPrompt = new DialogPromptImageSelectSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            isHidden,
            yesResponse,
            noResponse,
            // imageSource,
            // imageSourceUrl,
            teacherChinesePrompt,
            // correctVocabularyWordId,
            // word,
            vocabularyWordList,
            // displayPinyin
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, yesResponse, noResponse,
        teacherChinesePrompt, vocabularyWordList])

    // const handleCorrectImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

    //     const files = e.target.files;
    //     if (files && files.length > 0) {
    //         const file = files[0];
    //         const reader = new FileReader();

    //         reader.onloadend = () => {
    //             // Code to handle the reader result
    //             let base64String: string;
    //             let result = reader.result;
    //             if (typeof result === 'string') {
    //                 base64String = result;
    //             } else if (result instanceof ArrayBuffer) {
    //                 base64String = arrayBufferToBase64(result)
    //             } else {
    //                 base64String = ""
    //             }
    //             setCorrectVocabularyWordImage(base64String);
    //         };

    //         reader.readAsDataURL(file);
    //     }
    // };
    // const handleDeleteCorrectImage = () => {
    //     // Set the wordImage state to an empty string to remove the image
    //     setCorrectVocabularyWordImage('');
    //     setCorrectVocabularyWordImageUrl('');
    // };

    const handleAddVocabularyWord = () => {
        const newVocabularyWord: IVocabularyWord = {
            id: 0,
            word: '',
            image: '',
            image_url: '',
            audio_sentence: '',
            audio_sentence_id: 0,
            dialog_prompt_id: 0,
            teacher_english_prompt: '',
            teacher_chinese_prompt: '',
            teacher_chinese_prompt_sentence_id: 0,
            student_response_prompt: '<Answer/>',
            student_response_prompt_sentence_id: 0,
            word_pinyin: [],
            audio_sentence_pinyin: [],
            teacher_chinese_prompt_pinyin: []
        };
        if (vocabularyWordList) {
            setVocabularyWordList([...vocabularyWordList, newVocabularyWord]);
        } else {
            setVocabularyWordList([newVocabularyWord]);
        }
    };

    const handleVocabularyWordChange = (
        index: number,
        field: string,
        value: string
    ) => {
        setVocabularyWordList((prevWords) =>
            prevWords.map((word, i) => {
                if (i === index) {
                    return { ...word, [field]: value };
                }
                return word;
            })
        );
    };
    const handleVocabularyWordImageUpload = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Code to handle the reader result
                let base64String: string;
                let result = reader.result;
                if (typeof result === 'string') {
                    base64String = result;
                } else if (result instanceof ArrayBuffer) {
                    base64String = arrayBufferToBase64(result)
                } else {
                    base64String = ""
                }
                setVocabularyWordList((prevWords) =>
                    prevWords.map((word: IVocabularyWord, i) => {
                        if (i == index) {
                            return { ...word, image: base64String }
                        }
                        return word;
                    })
                )
            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteVocabularyWord = (index: number) => {
        setWordIndexToDelete(index);
        setDeleteConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {
        if (wordIndexToDelete !== null) {
            setVocabularyWordList(prevList => prevList.filter((word, index) => index !== wordIndexToDelete));
        }
        setWordIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    const handleCancelDelete = () => {
        setWordIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };
    return (<>
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
            <div style={{ width: '100%' }}>
                <TextField
                    label="Chinese Prompt (optional)"
                    value={teacherChinesePrompt}
                    onChange={handleTeacherChinesePromptChange}
                    fullWidth
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted.<br />
                    The prompt will be skipped and not displayed, if this field is only &lt;Answer/&gt;
                </Typography>
            </div>

            {/* <FormControlLabel
                control={<Checkbox checked={displayPinyin ? displayPinyin : false} onChange={handleDisplayPinyinChange} />}
                label="Display answers with Pinyin"
            /> */}

            {/* <h3>Correct Answer</h3>
            <TextField
                label="Word"
                value={correctVocabularyWord}
                onChange={handleCorrectVocabularyWordChange}
            />
            <Box>
                <InputLabel htmlFor="image-upload">Prompt with Image</InputLabel>
                <Input
                    type="file"
                    id="image-upload"
                    inputProps={{ accept: "image/*" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCorrectImageUpload(e)}
                />
            </Box>
            <img
                src={correctVocabularyWordImage ? correctVocabularyWordImage : (correctVocabularyWordImageUrl ? correctVocabularyWordImageUrl : undefined)}
                alt="Uploaded"
                style={{
                    maxHeight: '200px', maxWidth: '200px',
                    height: 'auto', width: 'auto',
                    margin: '0 auto', // Add this line to center the image horizontally
                    display: 'block', // Add this line to ensure the margin works correctly
                }}
            /> */}
            {/* Add the delete image button */}
            {/* {((correctVocabularyWordImage && correctVocabularyWordImage != "broken") || (correctVocabularyWordImageUrl && correctVocabularyWordImageUrl != "broken")) && (
                <Box display="inline-block">
                    <Button variant="outlined" onClick={handleDeleteCorrectImage}>
                        Delete Image
                    </Button>
                </Box>
            )} */}
        </Stack>


        {vocabularyWordList && vocabularyWordList.map((word, index) => {
            // Assuming word.image contains the ArrayBuffer
            return (

                <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }} key={index}>
                    <h3>Answer</h3>
                    <TextField
                        label="Answer"
                        value={word.word}
                        onChange={(e) =>
                            handleVocabularyWordChange(index, 'word', e.target.value)
                        }
                    />
                    <Box>
                        <InputLabel htmlFor="image-upload">Prompt with Image</InputLabel>
                        <Input
                            type="file"
                            id="image-upload"
                            inputProps={{ accept: "image/*" }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleVocabularyWordImageUpload(index, e)}
                        />
                    </Box>
                    <img
                        src={word.image ? word.image : (word.image_url ? word.image_url : undefined)}
                        alt="Uploaded"
                        style={{
                            maxHeight: '200px', maxWidth: '200px',
                            height: 'auto', width: 'auto',
                            margin: '0 auto', // Add this line to center the image horizontally
                            display: 'block', // Add this line to ensure the margin works correctly
                        }}
                    />
                    <TextField
                        label="Student Response Prompt"
                        value={word.student_response_prompt}
                        onChange={(e) =>
                            handleVocabularyWordChange(index, 'student_response_prompt', e.target.value)
                        }
                    />
                    <TextField
                        label="Teacher English Prompt"
                        value={word.teacher_english_prompt}
                        onChange={(e) =>
                            handleVocabularyWordChange(index, 'teacher_english_prompt', e.target.value)
                        }
                    />
                    {/* {word.image && word.image != "broken" && (
                        TODO add delete image button? needed in DialgoPlanLlmPromptImageSelectSpeakForm also
                    )} */}
                    <Button variant="outlined" onClick={() => handleDeleteVocabularyWord(index)}>
                        Delete
                    </Button>
                </Stack>
            )
        })}
        <Button variant="outlined" onClick={handleAddVocabularyWord}>
            Add Answer
        </Button>
        <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this answer {wordIndexToDelete !== null && (<>{vocabularyWordList[wordIndexToDelete].word}</>)} ?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDelete} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>);
};
