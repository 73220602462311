import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AudioApi } from '../../api/AudioApi';
import { DialogPrompt } from '../../api/DialogPromptApi';
import { ChineseTeacher } from '../../api/UserApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';

interface DialogPromptComponentProps {
    dialogPrompt: DialogPrompt;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

export const emptyAudioBlob = new Blob([]);

export function getAudioByText(history: ReturnType<typeof useHistory>, setErrorMessage: React.Dispatch<string>,
    text: string | null, setAudio: React.Dispatch<Blob | null>, isSettings: boolean = false, speakerName: string | null = null) {

    // isSettings = true, used during all settings dialogs, before ChineseTeacher is defined
    // speakerName != null, during selectTeacherPrompt, to display teacher
    const storedOnlyRequiredAudio = localStorage.getItem('onlyRequiredAudio');
    const onlyRequiredAudio = storedOnlyRequiredAudio ? JSON.parse(storedOnlyRequiredAudio) : true;


    if (text == null || text.trim().length <= 0 || (speakerName == null && onlyRequiredAudio)) {
        setAudio(null)
    } else {
        const teacher = speakerName || localStorage.getItem('teacher');
        let audioPromise;

        if (teacher === ChineseTeacher.Male) {
            audioPromise = AudioApi.getMaleByText(text);
        } else if (teacher === ChineseTeacher.Female) {
            audioPromise = AudioApi.getFemaleByText(text);
        } else if (teacher === ChineseTeacher.Female2) {
            audioPromise = AudioApi.getFemale2ByText(text);
        } else if (isSettings) {
            audioPromise = AudioApi.getSpeakerBByText(text);
        } else {
            throw new Error("Unknown selected teacher name in local storage: " + teacher);
        }

        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Audio request failed with status code ' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                setAudio(blob); // Set the Blob on success
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            });
    }
}


const DialogPromptComponent: React.FC<DialogPromptComponentProps> = ({
    dialogPrompt,
    setDialogPromptComplete,
    setPromptInputComponent,
    showAvatar = true,
    chatBubbleContainerRef,
    isSettings = false
}) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);

    React.useEffect(() => {
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio, isSettings)
    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            setDialogPromptComplete(dialogPrompt.id);
        }
    }, [chatBubbleDone]);

    return (<>
        <ChatBubble
            isTeacher={dialogPrompt.is_teacher}
            message={dialogPrompt.prompt}
            messageAudio={promptAudio ? promptAudio : null}
            showAvatar={showAvatar}
            setChatBubbleDone={setChatBubbleDone}
            chatBubbleContainerRef={chatBubbleContainerRef}
        />
    </>);
};

export default DialogPromptComponent;

// Please refer to python/model/sentence.py
export const SentenceScoreMediumResult = "You're on the right track!"
export const SentenceScoreTryAgain = "Would you like to try again?"
export const SentenceScoreTryAgainYes = "I will try again"
export const SentenceScoreTryAgainNo = "No, thank you"
export const RegistrationReminder = "If you like this lesson, please register an account with a valid email and you will be notified when new lessons are available"
export const LastLessonOfClass = "That is the last lesson. Congratulations on completing this class! Please select your next class."
export const TeacherIntroductionMale = "你好，我是史老师"
export const TeacherIntroductionFemale = "你好，我是侯老师"
export const TeacherIntroductionFemale2 = "你好，我是范老师"
