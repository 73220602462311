import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptConversationListen, DialogPromptConversationListenWithoutCharacter, IDialogPromptSentence } from "../../api/DialogPromptApi";
import { DialogPromptConversationApi, IDialogPromptConversation } from "../../api/DialogPromptConversationApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPromptConversationListenFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    setNewDialogPromptConversation: React.Dispatch<React.SetStateAction<IDialogPromptConversation | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    isHidden: boolean;
    dialogPromptClassName: string;
}

export const DialogPromptConversationListenForm: React.FC<DialogPromptConversationListenFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt, setNewDialogPromptConversation, id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, dialogPromptClassName }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [dialogPromptConversationArray, setDialogPromptConversationArray] = React.useState<IDialogPromptConversation[]>([]);
    const [dialogPromptConversationIdSelected, setDialogPromptConversationIdSelected] = React.useState<number>(-1);
    const [conversationText, setConversationText] = React.useState("");
    const [conversationEnabled, setConversationEnabled] = React.useState(true);

    const [dialogPromptRequiredError, setDialogPromptRequiredError] = React.useState(false);
    const handleConversationChange = (e: React.ChangeEvent<HTMLInputElement>) => { setConversationText(e.target.value); };

    React.useEffect(() => {
        DialogPromptConversationApi.getAll()
            .then(response => {
                const list = response.data as IDialogPromptConversation[];
                const sortedListDescending = list.sort((a, b) => b.id - a.id);
                setDialogPromptConversationArray(sortedListDescending)
            })
            .catch(err => {
                setDialogPromptConversationArray([])
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }, [])
    React.useEffect(() => {
        DialogPromptApi.getById(oldDialogPromptId)
            .then((response) => {
                const oldDialogPrompt = response.data as DialogPromptConversationListen;
                setDialogPromptConversationIdSelected(oldDialogPrompt.dialog_prompt_conversation_id ? oldDialogPrompt.dialog_prompt_conversation_id : -1);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }, [oldDialogPromptId])

    React.useEffect(() => {
        setConversationEnabled(dialogPromptConversationIdSelected <= 0)
        if (dialogPromptConversationIdSelected > 0) {
            const conversation = dialogPromptConversationArray.find(conversation => conversation.id == dialogPromptConversationIdSelected)
            if (conversation) {
                setDialogPromptFields(conversation)
            }
        } else {
            setConversationText("")
        }
    }, [dialogPromptConversationIdSelected, dialogPromptConversationArray])

    function setDialogPromptFields(conversation: IDialogPromptConversation) {
        setConversationText(conversation.dialog_prompt_sentence_list
            ? conversation.dialog_prompt_sentence_list
                .map(item => item.sentence_text)
                .join("\n") // Convert array to newline-separated string
            : ""
        )
    }

    React.useEffect(() => {
        var dialogPrompt = null;
        if (dialogPromptClassName == "DialogPromptConversationListenModel") {
            dialogPrompt = new DialogPromptConversationListen(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                isHidden,
                dialogPromptConversationIdSelected,
            );
            setNewDialogPrompt(dialogPrompt);
        } else if (dialogPromptClassName == "DialogPromptConversationListenWithoutCharacterModel") {
            dialogPrompt = new DialogPromptConversationListenWithoutCharacter(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                isHidden,
                dialogPromptConversationIdSelected,
            );
        } else {
            throw new Error("Unknown class name " + dialogPromptClassName);
        }
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, dialogPromptConversationIdSelected])

    React.useEffect(() => {
        if (dialogPromptConversationIdSelected <= 0) {
            // Convert newline-separated string to array
            const dialogPromptSentenceList: IDialogPromptSentence[] = conversationText.split("\n").map((response: string, index) => ({
                id: 0,
                dialog_prompt_id: id,
                sentence_id: 0,
                sentence_text: response,
                order_index: index
            }));
            const conversation: IDialogPromptConversation = {
                id: 0,
                name: null,
                dialog_prompt_sentence_list: dialogPromptSentenceList
            };
            setNewDialogPromptConversation(conversation)
        } else {
            setNewDialogPromptConversation(null)
        }
        setDialogPromptRequiredError(conversationText.trim().length == 0)
    }, [conversationText])

    return (
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
            <div>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Select Conversation</InputLabel>
                    <Select
                        value={dialogPromptConversationIdSelected}
                        label="Select Conversation"
                        onChange={(event) => setDialogPromptConversationIdSelected(Number(event.target.value))}
                        required
                    >
                        <MenuItem key={-1} value={-1}>New Conversation</MenuItem>
                        {dialogPromptConversationArray
                            .filter(conversation => conversation.dialog_prompt_sentence_list && conversation.dialog_prompt_sentence_list.length > 0) // Filter out null or empty lists
                            .map(conversation => (
                                <MenuItem key={conversation.id} value={conversation.id}>
                                    {conversation.dialog_prompt_sentence_list
                                        .slice(0, 2) // Take only the first two items
                                        .map(item => item.sentence_text) // Extract the sentence_text
                                        .join(" / ") // Join them with " / "
                                    }
                                </MenuItem>
                            ))}
                    </Select>
                    <FormHelperText style={{ color: dialogPromptRequiredError ? 'red' : 'inherit' }}>
                        Conversation is required!
                    </FormHelperText>
                </FormControl>

                <TextField
                    label="Conversation"
                    value={conversationText}
                    onChange={handleConversationChange}
                    multiline
                    rows={6}
                    sx={{ width: '100%' }}
                    required
                    disabled={!conversationEnabled}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Alternate lines between speaker A and B
                </Typography>

            </div>
        </Stack>
    )
}