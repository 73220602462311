import { Alert, Button, Checkbox, CircularProgress, IconButton, InputAdornment, Link, Radio, RadioGroup, Snackbar, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory from React Router
import { isJwtLoggedIn } from '../Menu';
import { IRegister, LoginApi } from '../api/LoginApi';
import { ChineseTeacher, IUser, ScoringLevel, UserApi } from '../api/UserApi';
import background from '../asset/background-registration.png';

import female_avatar_1 from '../asset/female_avatar_1.jpg';
import female_avatar_2 from '../asset/female_avatar_2.jpg';
import male_avatar from '../asset/male_avatar.jpg';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { DialogPromptApi, DialogPromptScoringLevel } from '../api/DialogPromptApi';
import female_shilhouette from '../asset/female_silhouette.png';
import male_shilhouette from '../asset/male_silhouette.png';
import { TeacherIntroductionFemale, TeacherIntroductionFemale2, TeacherIntroductionMale, getAudioByText } from '../component/dialogPrompt/DialogPromptComponent';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';
import TopMenuIndexContext, { MenuIndex } from '../context/TopMenuIndexContext';
import { setUserLocalStorage } from './Login';

export default function Registration() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();

    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const [chineseTeacher, setChineseTeacher] = React.useState<string | null>(null);
    const [avatar, setAvatar] = React.useState('');
    const [learnChineseCharacter, setLearnChineseCharacter] = React.useState(true);
    const [onlyRequiredAudio, setOnlyRequiredAudio] = React.useState(true);
    const [scoringLevel, setScoringLevel] = React.useState<string | null>(null);

    const [registerStatusCode, setRegisterStatusCode] = React.useState(999);
    const [userMessage, setUserMessage] = React.useState('');
    const [validationErrorMessage, setValidationErrorMessage] = React.useState('');

    const [isLoggedIn, setLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState<IUser | null>(null);

    const [isLoading, setIsLoading] = React.useState(false);

    const { topMenuIndex, setTopMenuIndex } = React.useContext(TopMenuIndexContext);

    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);

    const [maleAudio, setMaleAudio] = React.useState<Blob | null | undefined>(undefined);
    const [femaleAudio, setFemaleAudio] = React.useState<Blob | null | undefined>(undefined);
    const [female2Audio, setFemale2Audio] = React.useState<Blob | null | undefined>(undefined);

    const [dialogPromptScoringLevel, setDialogPromptScoringLevel] = React.useState<DialogPromptScoringLevel | null>(null)


    const handleToggleCurrentPassword = () => {
        setShowCurrentPassword(prev => !prev);
    };

    const [showNewPassword, setShowNewPassword] = React.useState(false);

    const handleToggleNewPassword = () => {
        setShowNewPassword(prev => !prev);
    };

    React.useEffect(() => {
        setTopMenuIndex(MenuIndex.Registration)


        if (isJwtLoggedIn()) {
            setLoggedIn(true)

            getAudioByText(history, setErrorMessage, TeacherIntroductionMale, setMaleAudio, false, "male")
            getAudioByText(history, setErrorMessage, TeacherIntroductionFemale, setFemaleAudio, false, "female")
            getAudioByText(history, setErrorMessage, TeacherIntroductionFemale2, setFemale2Audio, false, "female2")

            UserApi.get()
                .then(response => {
                    // Handle the response
                    const user: IUser = response.data;
                    if (user) {
                        setUser(user);
                        if (user.email) setEmail(user.email);
                        if (user.first_name) setFirstName(user.first_name);
                        if (user.last_name) setLastName(user.last_name);
                        if (user.avatar) setAvatar(user.avatar);
                        if (user.chinese_teacher) setChineseTeacher(user.chinese_teacher);
                        if (user.learn_chinese_character != null && user.learn_chinese_character !== undefined) setLearnChineseCharacter(user.learn_chinese_character);
                        if (user.only_required_audio !== null && user.only_required_audio !== undefined) setOnlyRequiredAudio(Boolean(user.only_required_audio));
                        if (user.scoring_level) setScoringLevel(user.scoring_level);
                    }
                })
                .catch(err => {
                    setUserMessage("")
                    setValidationErrorMessage('Error getting user:' + err.response.data.message);
                });

            DialogPromptApi.getAll(undefined, "DialogPromptScoringLevelModel")
                .then(response => {
                    // check if the response was successful
                    if (response.status >= 300) {
                        throw new Error('not ok statuscode=' + response.status + " " + response.statusText);
                    }
                    return response.data;
                })
                .then(list => {
                    if (list.length <= 0) {
                        throw new Error("Scoring Level not found");
                    }
                    const visibleItemList = list
                        .filter(item => !item.is_hidden)
                        .sort((a, b) => b.id - a.id);
                    if (list.length <= 0) {
                        throw new Error("Scoring Level hidden");
                    }
                    setDialogPromptScoringLevel(visibleItemList[0] as DialogPromptScoringLevel)
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history, location);
                });

        } else {
            setLoggedIn(false)
        }
    }, []);


    function playTeacherIntroductionAudio(teacher: string) {
        if (teacher == ChineseTeacher.Male && maleAudio) {
            const audio = new Audio(URL.createObjectURL(maleAudio));
            audio.play();
        } else if (teacher == ChineseTeacher.Female && femaleAudio) {
            const audio = new Audio(URL.createObjectURL(femaleAudio));
            audio.play();
        } else if (teacher == ChineseTeacher.Female2 && female2Audio) {
            const audio = new Audio(URL.createObjectURL(female2Audio));
            audio.play();
        } else {
            const storedOnlyRequiredAudio = localStorage.getItem('onlyRequiredAudio');
            const onlyRequiredAudio = storedOnlyRequiredAudio ? JSON.parse(storedOnlyRequiredAudio) : true;
            if (onlyRequiredAudio == false) {
                throw Error("unknown selected teacher name in local storage " + teacher);
            }
        }
    };

    const avatarOptions = [male_shilhouette, female_shilhouette];

    const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const result = e.target && e.target.result ? e.target.result.toString() : "";
                setAvatar(result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRegister = () => {
        if (isLoggedIn) {
            if (password !== confirmPassword) {
                setUserMessage("");
                setValidationErrorMessage("Passwords do not match");
            } else {
                const userData: IUser = {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    current_password: currentPassword,
                    password: password,
                    avatar: avatar,
                    chinese_teacher: chineseTeacher as ChineseTeacher,
                    learn_chinese_character: learnChineseCharacter,
                    only_required_audio: onlyRequiredAudio,
                    scoring_level: scoringLevel as ScoringLevel,
                };
                setIsLoading(true)
                UserApi.update(userData)
                    .then(response => {
                        if (response.status < 300) {
                            setUserMessage("Save successful");
                            setValidationErrorMessage("");
                            setUserLocalStorage(userData);
                        } else {
                            throw new Error("Unable to LoginApi.register() " + response.status)
                        }
                    })
                    .catch(err => {
                        setUserMessage("");
                        setValidationErrorMessage(err.response.data.message);
                    })
                    .finally(() => {
                        setIsLoading(false)
                    });
            }
        } else {
            const referrerSentenceScoreId = localStorage.getItem('s');
            const referrerGuid = localStorage.getItem('r');
            const registerData: IRegister = {
                email,
                password,
                confirmPassword,
                firstName,
                lastName,
                referrerSentenceScoreId,
                referrerGuid
            };

            setIsLoading(true)
            LoginApi.register(registerData)
                .then(response => {
                    if (response.status < 300) {
                        setRegisterStatusCode(response.status)
                    } else {
                        throw new Error("Unable to LoginApi.register() " + response.status)
                    }
                })
                .catch(err => {
                    setUserMessage("");
                    setValidationErrorMessage(err.response.data.message);
                })
                .finally(() => {
                    setIsLoading(false)
                });

        }
    };

    // fix some strange re-rendering problem
    const [validateEmailClicked, setValidateEmailClicked] = React.useState(false);
    const handleValidateEmail = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Prevent the default navigation behavior
        setValidateEmailClicked(true);
    }
    const [registrationEmailSnackbarOpen, setRegistrationEmailSnackbarOpen] = React.useState(false);
    const [registrationEmailSnackbarMessage, setRegistrationEmailSnackbarMessage] = React.useState('');
    const [registrationEmailSnackbarSeverity, setRegistrationEmailSnackbarSeverity] = React.useState<'success' | 'error'>('success');
    React.useEffect(() => {
        if (validateEmailClicked) {
            UserApi.sendValidateEmail()
                .then(response => {
                    // console.log('Promise resolved:', response);
                    setUserMessage("Validation email has been sent");
                    setValidationErrorMessage("");
                    setValidateEmailClicked(false);
                    setRegistrationEmailSnackbarOpen(true); // 👈 Show snackbar
                    setRegistrationEmailSnackbarMessage("Validation email sent successfully!");
                    setRegistrationEmailSnackbarSeverity("success");
                })
                .catch(err => {
                    // console.log('Promise rejected:', error);
                    const message = err.response.data.message || "Unknown error occurred";
                    setUserMessage("");
                    setValidationErrorMessage("Error sending validation email " + message);
                    setValidateEmailClicked(false);
                    setRegistrationEmailSnackbarMessage("Failed to send validation email: " + message);
                    setRegistrationEmailSnackbarSeverity("error");
                    setRegistrationEmailSnackbarOpen(true);
                })
        }
    }, [validateEmailClicked]);

    return (

        <div style={{
            display: 'flex',
            flexDirection: 'column', // Changed to stack children vertically
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${background})`, // Replace 'path_to_your_image.png' with the actual path to your image
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: 'end',
            minHeight: 'calc(100vh - 82px)', // Set the container's minimum height to fill the entire viewport, minus menu
            width: '100vw', // Adjusted width to fill the entire viewport horizontally
            padding: 0,
        }}>

            {registerStatusCode < 300 ? (<>
                {registerStatusCode == 201 ? (<>
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        Registration Successful!
                    </Typography>
                    <br />
                    <br />
                    <Typography>
                        Please check your email (including spam folder) and validate your email address. This may take up to 30 minutes.
                        <br />
                        You can {' '}<Link href="/login">Login</Link>{' '}and use most of the site before validating your email address.
                    </Typography>
                </>) : (<>
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        Email already exists.
                    </Typography>
                </>)}
                <br />
                <br />
            </>) : <>
                {!isLoggedIn && (<>
                    <Typography variant="h4">We need your help!</Typography>
                    <Typography variant="h5">If you find this site useful, please register a <span style={{ color: '#E6B800 ', fontWeight: 'bold' }}>free account</span> to let us know</Typography>
                </>)}

                <form>
                    {isLoggedIn && (<>

                        <Typography variant="h2" sx={{ textAlign: "center", mt: 2 }}>
                            Settings
                        </Typography>
                        {/* Chinese Teacher Selection */}
                        <div style={{ display: 'flex', alignItems: 'center', margin: '1em 0' }}>
                            <label style={{ width: '150px' }}>Teacher Voice:</label>
                            <RadioGroup row value={chineseTeacher} onChange={e => setChineseTeacher(e.target.value)}>
                                <label key={ChineseTeacher.Male} onClick={() => playTeacherIntroductionAudio(ChineseTeacher.Male)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}>
                                    <Radio value={ChineseTeacher.Male} />
                                    <img src={male_avatar} alt={ChineseTeacher.Male} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                                </label>
                                <label key={ChineseTeacher.Female} onClick={() => playTeacherIntroductionAudio(ChineseTeacher.Female)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}>
                                    <Radio value={ChineseTeacher.Female} />
                                    <img src={female_avatar_1} alt={ChineseTeacher.Female} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                                </label>
                                <label key={ChineseTeacher.Female2} onClick={() => playTeacherIntroductionAudio(ChineseTeacher.Female2)} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}>
                                    <Radio value={ChineseTeacher.Female2} />
                                    <img src={female_avatar_2} alt={ChineseTeacher.Female2} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                                </label>
                            </RadioGroup>
                        </div>

                        {/* Avatar Selection */}
                        <div style={{ display: 'flex', alignItems: 'center', margin: '1em 0' }}>
                            <label style={{ width: '150px' }}>Avatar:</label>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                {/* Predefined Avatars */}
                                {avatarOptions.map((src) => (
                                    <img
                                        key={src}
                                        src={src}
                                        alt="Avatar Option"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            border: avatar === src ? '3px solid blue' : '3px solid transparent'
                                        }}
                                        onClick={() => setAvatar(src)}
                                    />
                                ))}

                                {/* Upload Avatar Button */}
                                <label htmlFor="avatar-upload" style={{ cursor: 'pointer', border: '1px solid #ccc', padding: '5px 10px', borderRadius: '5px' }}>
                                    Upload Image
                                </label>
                                <input
                                    type="file"
                                    id="avatar-upload"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleAvatarChange}
                                />

                                {/* Display Uploaded Image */}
                                {avatar && !["/avatars/avatar1.png", "/avatars/avatar2.png"].includes(avatar) && (
                                    <img
                                        src={avatar}
                                        alt="Uploaded Avatar"
                                        style={{ width: '50px', height: '50px', borderRadius: '50%', marginLeft: '10px' }}
                                    />
                                )}
                            </div>
                        </div>

                        {/* Learn Chinese Characters Checkbox */}
                        {/* <div style={{ display: 'flex', alignItems: 'center', margin: '1em 0' }}>
                        <label style={{ width: '150px' }}>Learn Chinese Characters:</label>
                        <Checkbox checked={learnChineseCharacter} onChange={e => setLearnChineseCharacter(e.target.checked)} />
                    </div> */}

                        {/* Only Required Audio Checkbox */}
                        <div style={{ display: 'flex', alignItems: 'center', margin: '1em 0' }}>
                            <label style={{ width: '150px' }}>Required Audio Only:</label>
                            <Checkbox checked={onlyRequiredAudio} onChange={e => setOnlyRequiredAudio(e.target.checked)} />
                        </div>

                        {/* Scoring Level selection, should */}
                        <div style={{ display: 'flex', alignItems: 'center', margin: '1em 0' }}>
                            <label style={{ width: '150px' }}>Grading Difficulty:</label>
                            <RadioGroup row value={scoringLevel} onChange={e => setScoringLevel(e.target.value)}>
                                <label key={ScoringLevel.Easy} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}>
                                    <Radio value={ScoringLevel.Easy} />{dialogPromptScoringLevel ? dialogPromptScoringLevel.no_button_text : "Not Bad"}
                                </label>
                                <label key={ScoringLevel.Easy} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}>
                                    <Radio value={ScoringLevel.Medium} />{dialogPromptScoringLevel ? dialogPromptScoringLevel.maybe_button_text : "Strong"}
                                </label>
                                <label key={ScoringLevel.Hard} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}>
                                    <Radio value={ScoringLevel.Hard} />{dialogPromptScoringLevel ? dialogPromptScoringLevel.yes_button_text : "Pro"}
                                </label>
                            </RadioGroup>
                        </div>

                        <Typography variant="h2" sx={{ textAlign: "center", mt: 2 }}>
                            Profile
                        </Typography>
                    </>)}

                    {/* Input fields */}
                    <div style={{ display: 'flex', gap: '1em' }}>
                        <TextField
                            label="First Name"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Last Name"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                        autoFocus
                    />
                    {isLoggedIn && (<>
                        <Typography>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Link
                                    component="button"
                                    onClick={handleValidateEmail}
                                    disabled={validateEmailClicked}
                                    underline="hover"
                                    sx={{
                                        cursor: validateEmailClicked ? 'not-allowed' : 'pointer',
                                        color: validateEmailClicked ? 'text.disabled' : 'primary.main',
                                    }}
                                >
                                    Resend email verification link.
                                </Link>

                                {validateEmailClicked && (
                                    <CircularProgress size={16} thickness={5} />
                                )}
                            </Stack>
                            Have a new email? Click on <strong>Update</strong> first before requesting an email verification!
                        </Typography>
                        <Snackbar
                            open={registrationEmailSnackbarOpen}
                            autoHideDuration={4000}
                            onClose={() => setRegistrationEmailSnackbarOpen(false)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <Alert
                                onClose={() => setRegistrationEmailSnackbarOpen(false)}
                                severity={registrationEmailSnackbarSeverity}
                                sx={{ width: '100%' }}
                            >
                                {registrationEmailSnackbarMessage}
                            </Alert>
                        </Snackbar>
                        <TextField
                            label="Current Password"
                            value={currentPassword}
                            onChange={e => setCurrentPassword(e.target.value)}
                            type={showCurrentPassword ? 'text' : 'password'} // 👈 toggle between text and password
                            fullWidth
                            required
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleCurrentPassword} edge="end">
                                            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </>)}
                    <div style={{ display: 'flex', gap: '1em' }}>
                        <TextField
                            label="New Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type={showNewPassword ? 'text' : 'password'} // 👈 toggle between text and password
                            fullWidth
                            required={!isLoggedIn} // Set required to true if isLoggedIn is false
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleNewPassword} edge="end">
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', gap: '1em' }}>
                        <TextField
                            label="Confirm New Password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            type="password"
                            fullWidth
                            required={!isLoggedIn} // Set required to true if isLoggedIn is false
                            margin="normal"
                        />
                    </div>

                    {validationErrorMessage && (
                        <Typography variant="h5" color="error" style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }} >
                            {validationErrorMessage}
                        </Typography>

                    )}
                    {userMessage && (
                        <Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }} >
                            {userMessage}
                        </Typography >

                    )}

                    {/* Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '2em', marginTop: '1em' }}>
                        <Button variant="contained" fullWidth color="primary" onClick={handleRegister} disabled={isLoading}>
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : isLoggedIn ? "Update" : "Register"}
                        </Button>
                    </div>
                </form>
            </>}
        </div >
    );
};
