import { Box, Button, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogPlanLlmPrompt } from '../../api/DialogPlanLlmPromptApi';
import { DialogPromptTypeSpeak } from '../../api/DialogPromptApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';

interface DialogPlanLlmPromptTypeSpeakGeneratedProps {
    dialogPrompt?: DialogPromptTypeSpeak; // if llmPrompt was generated
    onSubmit?: (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete?: (dialogPlanLlmPromptId: number) => void;

    isRegenerating?: boolean;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
}

const DialogPlanLlmPromptTypeSpeakGenerated: React.FC<DialogPlanLlmPromptTypeSpeakGeneratedProps> = ({ dialogPrompt, onSubmit, onDelete, isRegenerating, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState("");
    const [studentResponsePrompt, setStudentResponsePrompt] = React.useState("<Answer/>");
    const [studentResponseList, setStudentResponseList] = React.useState("");
    const [teacherAskStudentToSpeakPrompt, setTeacherAskStudentToSpeakPrompt] = React.useState("");
    const [spokenYesResponse, setSpokenYesResponse] = React.useState("");
    const [spokenNoResponse, setSpokenNoResponse] = React.useState("");

    React.useEffect(() => {
        if (dialogPrompt) {
            setDialogPromptFields(dialogPrompt);
        }
    }, [dialogPrompt])

    function setDialogPromptFields(dialogPrompt: DialogPromptTypeSpeak) {
        setTeacherChinesePrompt(dialogPrompt.teacher_chinese_prompt ? dialogPrompt.teacher_chinese_prompt : "")
        setStudentResponsePrompt(dialogPrompt.student_response_prompt ? dialogPrompt.student_response_prompt : "")
        setStudentResponseList(dialogPrompt.dialog_prompt_sentence_list
            ? dialogPrompt.dialog_prompt_sentence_list
                .map(item => item.student_response)
                .join("\n") // Convert array to newline-separated string
            : ""
        )
        setTeacherAskStudentToSpeakPrompt(dialogPrompt.teacher_ask_student_to_speak_prompt ? dialogPrompt.teacher_ask_student_to_speak_prompt : "")
        setSpokenYesResponse(dialogPrompt.spoken_yes_response ? dialogPrompt.spoken_yes_response : "")
        setSpokenNoResponse(dialogPrompt.spoken_no_response ? dialogPrompt.spoken_no_response : "")
    }

    // update dialog prompt
    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); if (dialogPrompt) dialogPrompt.teacher_chinese_prompt = e.target.value };
    const handleStudentResponsePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponsePrompt(e.target.value); if (dialogPrompt) dialogPrompt.student_response_prompt = e.target.value };
    const handleStudentResponseListChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const responseListString = e.target.value
        setStudentResponseList(responseListString);
        if (dialogPrompt) {
            const lines = responseListString.split("\n").filter(line => line.trim() !== "");

            // Map each line to an IDialogPromptSentence object
            dialogPrompt.dialog_prompt_sentence_list = lines.map((studentResponse, index) => ({
                id: 0, // Assuming `id` is generated by the backend
                dialog_prompt_id: dialogPrompt.id, // Use the provided dialogPromptId
                sentence_id: 0, // Assuming this is set later or generated by the backend
                student_response: studentResponse.trim(), // Assign the trimmed response
                order_index: index + 1, // Incremental order based on line position
            }));
        }
    };
    const handleTeacherAskStudentToSpeakPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherAskStudentToSpeakPrompt(e.target.value); if (dialogPrompt) dialogPrompt.teacher_ask_student_to_speak_prompt = e.target.value };
    const handleSpokenYesResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setSpokenYesResponse(e.target.value); if (dialogPrompt) dialogPrompt.spoken_yes_response = e.target.value };
    const handleSpokenNoResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setSpokenNoResponse(e.target.value); if (dialogPrompt) dialogPrompt.spoken_no_response = e.target.value };

    return (<>
        <Stack spacing={4} sx={{ width: '100%', margin: '0 auto', pt: 2, pb: 4 }}>

            <TextField
                label="Teacher Chinese Prompt"
                value={teacherChinesePrompt}
                onChange={handleTeacherChinesePromptChange}
                multiline
                rows={5}
            />
            {/* Hidden spacer button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
            <div>
                <TextField
                    label="Student Chinese Prompt"
                    value={studentResponsePrompt ? studentResponsePrompt : "<Answer/>"}
                    onChange={handleStudentResponsePromptChange}
                    required
                    multiline
                    rows={5}
                    sx={{ width: '100%' }}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted
                </Typography>
                {/* Hidden spacer button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        disabled={true}
                        sx={{ opacity: 0 }}
                    >
                        Regenerate
                    </Button>
                </Box>
            </div>
            <TextField
                label="Allowed Student Prompt Responses"
                value={studentResponseList}
                onChange={handleStudentResponseListChange}
                multiline
                rows={5}
                sx={{ width: '100%' }}
                required
            />
            {/* Hidden spacer button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
            <TextField
                label="Teacher Ask Student To Speak Prompt"
                value={teacherAskStudentToSpeakPrompt}
                onChange={handleTeacherAskStudentToSpeakPromptChange}
                multiline
                rows={5}
                required
            />
            {/* Hidden spacer button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
            <TextField
                label="Student Spoken Well Prompt"
                value={spokenYesResponse}
                onChange={handleSpokenYesResponseChange}
                multiline
                rows={5}
                required
            />
            {/* Hidden spacer button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
            <TextField
                label="Student Spoken Poorly Prompt"
                value={spokenNoResponse}
                onChange={handleSpokenNoResponseChange}
                multiline
                rows={5}
                required
            />
            {/* Hidden spacer button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
        </Stack>
    </>);
};

export default DialogPlanLlmPromptTypeSpeakGenerated;