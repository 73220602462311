import '../style/global.css';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { ILogin, LoginApi } from '../api/LoginApi';
import { IUser, UserApi } from "../api/UserApi";
import background from '../asset/background-login.png';
import ErrorMessageContext, { handleErrorMessage } from "../context/ErrorMessageContext";
import IsLoggedInContext from "../context/IsLoggedInContext";
import { isSettingsComplete } from './Lesson';

export function setUserLocalStorage(user: IUser) {
    if (user.first_name === undefined || user.first_name === null) {
        localStorage.removeItem('studentName');
    } else {
        localStorage.setItem('studentName', user.first_name);
    }
    if (user.learn_chinese_character === undefined || user.learn_chinese_character === null) {
        localStorage.setItem('learnWithCharacter', true.toString());
    } else {
        localStorage.setItem('learnWithCharacter', user.learn_chinese_character.toString());
    }
    if (user.avatar === undefined || user.avatar === null) {
        localStorage.removeItem('studentAvatar')
    } else {
        localStorage.setItem('studentAvatar', user.avatar);
    }
    if (user.chinese_teacher === undefined || user.chinese_teacher === null) {
        localStorage.removeItem('teacher')
    } else {
        localStorage.setItem('teacher', user.chinese_teacher);
    }
    if (user.only_required_audio === undefined || user.only_required_audio === null) {
        localStorage.setItem('onlyRequiredAudio', true.toString());
    } else {
        localStorage.setItem('onlyRequiredAudio', user.only_required_audio.toString());
    }
}

export default function Login() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const { isLoggedIn, setIsLoggedIn } = React.useContext(IsLoggedInContext)

    const [loginErrorMessage, setLoginErrorMessage] = React.useState('');

    const [showPassword, setShowPassword] = React.useState(false);

    const handleTogglePassword = () => {
        setShowPassword(prev => !prev);
    };

    const handleSubmit = (event: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined; }) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const loginObject: ILogin = {
            email: String(data.get("email")),
            password: String(data.get("password")),
            rememberMe: Boolean(data.get("rememberMe"))
        };
        LoginApi.login(loginObject)
            .then(response => {
                if (response.status < 300) {
                    localStorage.setItem('jwt', response.data.token);
                    setIsLoggedIn(true)
                    UserApi.get()
                        .then(response => {
                            // Handle the response
                            const user: IUser = response.data;
                            setUserLocalStorage(user);

                            if (isSettingsComplete() == false) {
                                history.push('/lesson?settings=true');
                            } else {
                                const redirectTo = location.state &&
                                    location.state.from &&
                                    location.state.from.pathname || '/lesson'
                                history.push(redirectTo)
                            }

                        })
                        .catch(err => {
                            handleErrorMessage(err, setErrorMessage, history, location);
                        });
                } else {
                    setLoginErrorMessage("Invalid Login");
                }
            })
            .catch(err => {
                setLoginErrorMessage("Login Error");
                handleErrorMessage(err, setErrorMessage, history, location);
            });
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${background})`, // Replace 'path_to_your_image.png' with the actual path to your image
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: 'right',
            minHeight: 'calc(100vh - 82px)', // Set the container's minimum height to fill the entire viewport, minus menu
            width: '100vw', // Adjusted width to fill the entire viewport horizontally
            padding: 0,
        }}>
            <Container maxWidth="xs">
                <Typography variant="h2">
                    Log in
                </Typography>
                {/* Error message */}
                {loginErrorMessage && (
                    <Typography color="error" style={{ display: 'flex', justifyContent: 'center' }}>
                        {loginErrorMessage}
                    </Typography>
                )}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        className="blueInput"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        className="blueInput"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'} // toggle between text and password
                        id="password"
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleTogglePassword} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox value="True" color="primary" id="rememberMe" name="rememberMe" />
                            }
                            label="Remember me"
                        />
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Log In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/reset_password_email" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/registration" variant="body2">
                                {"Don't have an account? Register Now!"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

        </div>
    );
}