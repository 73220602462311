import axios, { AxiosResponse } from 'axios';
import { REST_HOSTNAME } from '../Config';
import { INextDialog } from './NextDialog';
import { ISentenceScore } from './ScoreApi';
import { IVocabularyWord } from './VocabularyWordApi';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface IDialogPromptSentence {
    id: number;
    dialog_prompt_id?: number;
    sentence_id: number;
    sentence_text?: string;
    sentence_text_alternate?: string;
    student_response?: string;
    order_index: number;
}

export type DialogPromptTypeUnion = DialogPrompt & DialogPromptImage;

export class DialogPrompt {
    // Add an index signature to allow arbitrary string keys at the cost of type safety.
    // [key: string]: any; // Add this index signature
    id: number;
    dialog_id: number;
    order_index: number;
    is_teacher: boolean;
    prompt: string;
    is_note_to_student: boolean;
    is_hidden: boolean;
    class_name: string;
    has_user_input: boolean;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean
    ) {
        this.id = id;
        this.class_name = "DialogPromptModel";
        this.dialog_id = dialog_id;
        this.order_index = order_index;
        this.is_teacher = is_teacher;
        this.prompt = prompt;
        this.is_note_to_student = is_note_to_student;
        this.is_hidden = is_hidden;
        this.has_user_input = false;
    }
}

export class DialogPromptEnterName extends DialogPrompt {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptEnterNameModel"
        this.has_user_input = true;
    }
}

export class DialogPromptImage extends DialogPrompt {
    image_src: string | null;
    image_src_url: string | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        image_src: string | null,
        image_src_url: string | null,
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptImageModel"
        this.has_user_input = false;
        this.image_src = image_src;
        this.image_src_url = image_src_url;
    }
}

export class DialogPromptIntroduceWord extends DialogPrompt {
    new_word: string;
    pinyin: string[] | null;
    sentence_id: number;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        new_word: string,
        pinyin: string[] | null,
        sentence_id: number
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptIntroduceWordModel"
        this.has_user_input = true;
        this.new_word = new_word;
        this.pinyin = pinyin;
        this.sentence_id = sentence_id
    }
}

export class DialogPromptLearnWithCharacter extends DialogPrompt {
    yes_button_text: string;
    no_button_text: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_button_text: string,
        no_button_text: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptLearnWithCharacterModel"
        this.has_user_input = true;
        this.yes_button_text = yes_button_text
        this.no_button_text = no_button_text
        this.yes_response = yes_response
        this.no_response = no_response
    }
}


export class DialogPromptScoringLevel extends DialogPrompt {
    yes_button_text: string;
    no_button_text: string;
    maybe_button_text: string;
    yes_response: string;
    no_response: string;
    maybe_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_button_text: string,
        no_button_text: string,
        maybe_button_text: string,
        yes_response: string,
        no_response: string,
        maybe_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptScoringLevelModel"
        this.has_user_input = true;
        this.yes_button_text = yes_button_text
        this.no_button_text = no_button_text
        this.maybe_button_text = maybe_button_text
        this.yes_response = yes_response
        this.no_response = no_response
        this.maybe_response = maybe_response
    }
}

export class DialogPromptListenSpeak extends DialogPrompt {
    new_word: string;
    pinyin: string[] | null;
    sentence_id: number;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        new_word: string,
        pinyin: string[] | null,
        sentence_id: number,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptListenSpeakModel"
        this.has_user_input = true;
        this.new_word = new_word;
        this.pinyin = pinyin;
        this.sentence_id = sentence_id
        this.yes_response = yes_response
        this.no_response = no_response
    }
}

export class DialogPromptListenSpeakNoGrade extends DialogPrompt {
    new_word: string;
    pinyin: string[] | null;
    sentence_id: number;
    yes_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        new_word: string,
        pinyin: string[] | null,
        sentence_id: number,
        yes_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptListenSpeakNoGradeModel"
        this.has_user_input = true;
        this.new_word = new_word;
        this.pinyin = pinyin;
        this.sentence_id = sentence_id
        this.yes_response = yes_response
    }
}

export class DialogPromptQuizWord extends DialogPrompt {
    new_word: string;
    yes_response: string;
    no_response: string;
    wrong_word_1: string | null;
    wrong_word_2: string | null;
    wrong_word_3: string | null;
    wrong_word_4: string | null;
    wrong_word_5: string | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        new_word: string,
        yes_response: string,
        no_response: string,
        wrong_word_1: string | null,
        wrong_word_2: string | null,
        wrong_word_3: string | null,
        wrong_word_4: string | null,
        wrong_word_5: string | null
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptQuizWordModel"
        this.has_user_input = true;
        this.new_word = new_word;
        this.yes_response = yes_response;
        this.no_response = no_response;
        this.wrong_word_1 = wrong_word_1
        this.wrong_word_2 = wrong_word_2
        this.wrong_word_3 = wrong_word_3
        this.wrong_word_4 = wrong_word_4
        this.wrong_word_5 = wrong_word_5
    }
}

export class DialogPromptSelectAvatar extends DialogPrompt {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptSelectAvatarModel"
        this.has_user_input = true;
    }
}

export class DialogPromptSelectTeacher extends DialogPrompt {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptSelectTeacherModel"
        this.has_user_input = true;
    }
}

export class DialogPromptStartClass extends DialogPrompt {
    yes_button_text: string;
    no_button_text: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_button_text: string,
        no_button_text: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptStartClassModel"
        this.has_user_input = true;
        this.yes_button_text = yes_button_text
        this.no_button_text = no_button_text
        this.yes_response = yes_response
        this.no_response = no_response
    }
}

export class DialogPromptStartNextLesson extends DialogPrompt {
    yes_button_text: string;
    no_button_text: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_button_text: string,
        no_button_text: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptStartNextLessonModel"
        this.has_user_input = true;
        this.yes_button_text = yes_button_text
        this.no_button_text = no_button_text
        this.yes_response = yes_response
        this.no_response = no_response
    }
}


export class DialogPromptMatchWordPicture extends DialogPrompt {
    vocabulary_word_list: IVocabularyWord[];
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        vocabulary_word_list: IVocabularyWord[]
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptMatchWordPictureModel"
        this.has_user_input = true;
        this.yes_response = yes_response
        this.no_response = no_response
        this.vocabulary_word_list = vocabulary_word_list
    }
}


export class DialogPromptImageSelectSpeak extends DialogPrompt {
    yes_response: string;
    no_response: string;
    teacher_chinese_prompt: string;
    vocabulary_word_list: IVocabularyWord[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        teacher_chinese_prompt: string,
        vocabulary_word_list: IVocabularyWord[],
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden)
        this.class_name = "DialogPromptImageSelectSpeakModel"
        this.has_user_input = true
        this.yes_response = yes_response
        this.no_response = no_response
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.vocabulary_word_list = vocabulary_word_list
    }
}

export class DialogPromptQuizQuestion extends DialogPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string;
    correct_vocabulary_word_id: number;
    correct_vocabulary_word: IVocabularyWord;
    incorrect_vocabulary_word_list: IVocabularyWord[];
    display_pinyin: boolean | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string,
        correct_vocabulary_word_id: number,
        correct_vocabulary_word: IVocabularyWord,
        incorrect_vocabulary_word_list: IVocabularyWord[],
        display_pinyin: boolean | null
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden)
        this.class_name = "DialogPromptQuizQuestionModel"
        this.has_user_input = true
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.correct_vocabulary_word_id = correct_vocabulary_word_id
        this.correct_vocabulary_word = correct_vocabulary_word
        this.incorrect_vocabulary_word_list = incorrect_vocabulary_word_list
        this.display_pinyin = display_pinyin
    }
}

export class DialogPromptQuizQuestionAudio extends DialogPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string;
    correct_vocabulary_word_id: number;
    correct_vocabulary_word: IVocabularyWord;
    incorrect_vocabulary_word_list: IVocabularyWord[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string,
        correct_vocabulary_word_id: number,
        correct_vocabulary_word: IVocabularyWord,
        incorrect_vocabulary_word_list: IVocabularyWord[]
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden)
        this.class_name = "DialogPromptQuizQuestionAudioModel"
        this.has_user_input = true
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.correct_vocabulary_word_id = correct_vocabulary_word_id
        this.correct_vocabulary_word = correct_vocabulary_word
        this.incorrect_vocabulary_word_list = incorrect_vocabulary_word_list
    }
}


export class DialogPromptTypeSpeak extends DialogPrompt {
    yes_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string | null;
    teacher_chinese_prompt_sentence_id: number | null;
    student_response_prompt: string | null;
    dialog_prompt_sentence_list: IDialogPromptSentence[];
    teacher_ask_student_to_speak_prompt: string | null;
    spoken_yes_response: string | null;
    spoken_no_response: string | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string | null,
        teacher_chinese_prompt_sentence_id: number | null,
        student_response_prompt: string | null,
        dialog_prompt_sentence_list: IDialogPromptSentence[],
        teacher_ask_student_to_speak_prompt: string | null,
        spoken_yes_response: string | null,
        spoken_no_response: string | null,
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptTypeSpeakModel"
        this.has_user_input = true;
        this.yes_response = yes_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.teacher_chinese_prompt_sentence_id = teacher_chinese_prompt_sentence_id
        this.student_response_prompt = student_response_prompt
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
        this.teacher_ask_student_to_speak_prompt = teacher_ask_student_to_speak_prompt
        this.spoken_yes_response = spoken_yes_response
        this.spoken_no_response = spoken_no_response
    }
}


export class DialogPromptConversationListen extends DialogPrompt {
    dialog_prompt_conversation_id: number;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        dialog_prompt_conversation_id: number
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptConversationListenModel"
        this.has_user_input = true;
        this.dialog_prompt_conversation_id = dialog_prompt_conversation_id
    }
}


export class DialogPromptConversationListenWithoutCharacter extends DialogPromptConversationListen {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        dialog_prompt_conversation_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden, dialog_prompt_conversation_id);
        this.class_name = "DialogPromptConversationListenWithoutCharacterModel"
        this.has_user_input = false;
    }
}

export class DialogPromptConversationRelated extends DialogPromptConversationListen {
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden, dialog_prompt_conversation_id);
        this.class_name = "AbstractModel"
        this.yes_response = yes_response;
        this.no_response = no_response;
        this.dialog_prompt_conversation_id = dialog_prompt_conversation_id
    }
}

export class DialogPromptConversationListenSpeak extends DialogPromptConversationRelated {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden,
            yes_response, no_response, dialog_prompt_conversation_id);
        this.class_name = "DialogPromptConversationListenSpeakModel"
        this.has_user_input = true;
    }
}


export class DialogPromptConversationReadSpeak extends DialogPromptConversationRelated {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden,
            yes_response, no_response, dialog_prompt_conversation_id);
        this.class_name = "DialogPromptConversationReadSpeakModel"
        this.has_user_input = true;
    }
}


export class DialogPromptConversationReadWithBlankSpeak extends DialogPromptConversationRelated {
    dialog_prompt_sentence_list: IDialogPromptSentence[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_id: number,
        dialog_prompt_sentence_list: IDialogPromptSentence[],

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden,
            yes_response, no_response, dialog_prompt_conversation_id);
        this.class_name = "DialogPromptConversationReadWithBlankSpeakModel"
        this.has_user_input = true;
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
    }
}


export class DialogPromptConversationSpeakOnly extends DialogPromptConversationRelated {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden,
            yes_response, no_response, dialog_prompt_conversation_id);
        this.class_name = "DialogPromptConversationSpeakOnlyModel"
        this.has_user_input = true;
    }
}


export class DialogPromptSelectLengthTime extends DialogPrompt {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptSelectLengthTimeModel"
        this.has_user_input = true;
    }
}


export class DialogPromptSelectNextDialog extends DialogPrompt {
    next_dialog_list: INextDialog[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        next_dialog_list: INextDialog[],

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptSelectNextDialogModel"
        this.has_user_input = true;
        this.next_dialog_list = next_dialog_list
    }
}


export class DialogPromptSpeakNoGrade extends DialogPrompt {
    yes_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string | null;
    teacher_chinese_prompt_sentence_id: number | null;
    student_response_prompt: string | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string | null,
        teacher_chinese_prompt_sentence_id: number | null,
        student_response_prompt: string | null,
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptSpeakNoGradeModel"
        this.has_user_input = true;
        this.yes_response = yes_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.teacher_chinese_prompt_sentence_id = teacher_chinese_prompt_sentence_id
        this.student_response_prompt = student_response_prompt
    }
}


export class DialogPromptSpeakWithGrade extends DialogPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string | null;
    teacher_chinese_prompt_sentence_id: number | null;
    student_response_prompt: string | null;
    teacher_hint_prompt: string | null;
    dialog_prompt_sentence_list: IDialogPromptSentence[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        is_hidden: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string | null,
        teacher_chinese_prompt_sentence_id: number | null,
        student_response_prompt: string | null,
        teacher_hint_prompt: string | null,
        dialog_prompt_sentence_list: IDialogPromptSentence[],
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student, is_hidden);
        this.class_name = "DialogPromptSpeakWithGradeModel"
        this.has_user_input = true;
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.teacher_chinese_prompt_sentence_id = teacher_chinese_prompt_sentence_id
        this.student_response_prompt = student_response_prompt
        this.teacher_hint_prompt = teacher_hint_prompt
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
    }
}

export class CorrectedResponse {
    is_correct: boolean;
    reason_for_incorrect: string;
    corrected_response: string;
    closest_sentence_text: string;
    closest_sentence_id: number;

    constructor(
        is_correct: boolean,
        reason_for_incorrect: string,
        corrected_response: string,
        closest_sentence_text: string,
        closest_sentence_id: number,
    ) {
        this.is_correct = is_correct;
        this.reason_for_incorrect = reason_for_incorrect;
        this.corrected_response = corrected_response;
        this.closest_sentence_text = closest_sentence_text;
        this.closest_sentence_id = closest_sentence_id;
    }
}

export const DialogPromptApi = {
    getAll: function (dialogId?: number, className?: string): Promise<AxiosResponse<DialogPrompt[]>> {
        return axiosInstance.request<DialogPrompt[]>({
            method: "GET",
            url: `/dialog_prompt${dialogId ? `?dialog_id=${dialogId}` : ''}${className ? `?class_name=${className}` : ''}`
        });
    },
    getById: function (dialogPromptId: number) {
        return axiosInstance.request<DialogPrompt>({
            method: "GET",
            url: `/dialog_prompt/${dialogPromptId}`
        });
    },
    create: function (dialogPrompt: DialogPrompt) {
        return axiosInstance.request<DialogPrompt>({
            method: "POST",
            url: `/dialog_prompt`,
            data: dialogPrompt
        });
    },
    update: function (dialogPrompt: DialogPrompt): Promise<any[]> {
        const promiseList = [];
        promiseList.unshift(
            axiosInstance.request<string>({
                method: "PUT",
                url: `/dialog_prompt`,
                data: dialogPrompt
            })
        );
        return Promise.all(promiseList);
    },
    delete: function (dialogPromptId: number) {
        return axiosInstance.request<string>({
            method: "DELETE",
            url: `/dialog_prompt/${dialogPromptId}`,
        });
    },
    getDialogPromptTypeSpeakMatch: function (dialogPromptId: number, word: string) {
        return axiosInstance.request<CorrectedResponse>({
            method: "GET",
            url: `/typed_response/dialog_prompt_type_speak_match/${dialogPromptId}/${word}`
        });
    },
    getDialogPromptSpeakWithGradeMatch: function (dialogPromptId: number, audio: any) {
        return axiosInstance.request<ISentenceScore>({
            method: "POST",
            url: `/spoken_response/dialog_prompt_speak_with_grade_match/${dialogPromptId}`,
            data: audio
        });
    }
}


export function DialogPromptFactory(className: string,
    id: number,
    dialogId: number,
    orderIndex: number,
    isTeacher: boolean,
    prompt: string,
    isNoteToStudent: boolean,
    is_hidden: boolean,
    imageSource?: string | null,
    imageSourceUrl?: string | null,
    newWord?: string | null,
    yesButtonText?: string | null,
    noButtonText?: string | null,
    maybeButtonText?: string | null,
    yesResponse?: string | null,
    noResponse?: string | null,
    maybeResponse?: string | null,
    sentenceId?: number | null,
): DialogPrompt {
    if (className == "DialogPromptModel") {
        const dialogPrompt = new DialogPrompt(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden
        );
        return dialogPrompt
    } else if (className == "DialogPromptEnterNameModel") {
        const dialogPrompt = new DialogPromptEnterName(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden
        );
        return dialogPrompt
    } else if (className == "DialogPromptImageModel") {
        const dialogPrompt = new DialogPromptImage(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            imageSource ? imageSource : "broken",
            imageSourceUrl ? imageSourceUrl : "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPromptIntroduceWordModel") {
        const dialogPrompt = new DialogPromptIntroduceWord(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            newWord ? newWord : "broken",
            null,
            sentenceId ? sentenceId : -1
        );
        return dialogPrompt
    } else if (className == "DialogPromptLearnWithCharacterModel") {
        const dialogPrompt = new DialogPromptLearnWithCharacter(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesButtonText ? yesButtonText : "",
            noButtonText ? noButtonText : "",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptScoringLevelModel") {
        const dialogPrompt = new DialogPromptScoringLevel(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesButtonText ? yesButtonText : "",
            noButtonText ? noButtonText : "",
            maybeButtonText ? maybeButtonText : "",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            maybeResponse ? maybeResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptListenSpeakModel") {
        const dialogPrompt = new DialogPromptListenSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            newWord ? newWord : "broken",
            null,
            sentenceId ? sentenceId : -1,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptListenSpeakNoGradeModel") {
        const dialogPrompt = new DialogPromptListenSpeakNoGrade(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            newWord ? newWord : "broken",
            null,
            sentenceId ? sentenceId : -1,
            yesResponse ? yesResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptQuizWordModel") {
        const dialogPrompt = new DialogPromptQuizWord(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "broken",
            noResponse ? noResponse : "broken",
            null, null, null, null, null
        );
        return dialogPrompt
    } else if (className == "DialogPromptSelectAvatarModel") {
        const dialogPrompt = new DialogPromptSelectAvatar(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden
        );
        return dialogPrompt
    } else if (className == "DialogPromptSelectTeacherModel") {
        const dialogPrompt = new DialogPromptSelectTeacher(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden
        );
        return dialogPrompt
    } else if (className == "DialogPromptStartClassModel") {
        const dialogPrompt = new DialogPromptStartClass(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesButtonText ? yesButtonText : "",
            noButtonText ? noButtonText : "",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptStartNextLessonModel") {
        const dialogPrompt = new DialogPromptStartNextLesson(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesButtonText ? yesButtonText : "",
            noButtonText ? noButtonText : "",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptMatchWordPictureModel") {
        const dialogPrompt = new DialogPromptMatchWordPicture(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            []
        );
        return dialogPrompt
    } else if (className == "DialogPromptTypeSpeakModel") {
        const dialogPrompt = new DialogPromptTypeSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            imageSource ? imageSource : "",
            imageSourceUrl ? imageSourceUrl : "broken",
            "broken", null, "broken",
            [],
            "broken",
            "broken", "broken"
        );
        return dialogPrompt
    } else if (className == "DialogPromptImageSelectSpeakModel") {
        const dialogPrompt = new DialogPromptImageSelectSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "",
            [],
        );
        return dialogPrompt
    } else if (className == "DialogPromptQuizQuestionModel") {
        const dialogPrompt = new DialogPromptQuizQuestion(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "", "",
            "",
            0,
            {
                id: 0,
                word: '',
                image: '',
                image_url: '',
                audio_sentence: '',
                audio_sentence_id: 0,
                dialog_prompt_id: 0,
                teacher_english_prompt: '',
                teacher_chinese_prompt: '',
                teacher_chinese_prompt_sentence_id: 0,
                student_response_prompt: '<Answer/>',
                student_response_prompt_sentence_id: 0,
                word_pinyin: [],
                audio_sentence_pinyin: [],
                teacher_chinese_prompt_pinyin: []
            },
            [],
            null
        );
        return dialogPrompt
    } else if (className == "DialogPromptQuizQuestionAudioModel") {
        const dialogPrompt = new DialogPromptQuizQuestionAudio(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "", "",
            "",
            0,
            {
                id: 0,
                word: '',
                image: '',
                image_url: '',
                audio_sentence: '',
                audio_sentence_id: 0,
                dialog_prompt_id: 0,
                teacher_english_prompt: '',
                teacher_chinese_prompt: '',
                teacher_chinese_prompt_sentence_id: 0,
                student_response_prompt: '<Answer/>',
                student_response_prompt_sentence_id: 0,
                word_pinyin: null,
                audio_sentence_pinyin: null,
                teacher_chinese_prompt_pinyin: null
            },
            []
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationListenModel") {
        const dialogPrompt = new DialogPromptConversationListen(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationListenSpeakModel") {
        const dialogPrompt = new DialogPromptConversationListenSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationListenWithoutCharacterModel") {
        const dialogPrompt = new DialogPromptConversationListenWithoutCharacter(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationReadSpeakModel") {
        const dialogPrompt = new DialogPromptConversationReadSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationReadWithBlankSpeakModel") {
        const dialogPrompt = new DialogPromptConversationReadWithBlankSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            -1,
            []
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationSpeakOnlyModel") {
        const dialogPrompt = new DialogPromptConversationSpeakOnly(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptSelectLengthTimeModel") {
        const dialogPrompt = new DialogPromptSelectLengthTime(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden
        );
        return dialogPrompt
    } else if (className == "DialogPromptSelectNextDialogModel") {
        const dialogPrompt = new DialogPromptSelectNextDialog(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            [],
        );
        return dialogPrompt
    } else if (className == "DialogPromptSpeakNoGradeModel") {
        const dialogPrompt = new DialogPromptSpeakNoGrade(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            imageSource ? imageSource : "",
            imageSourceUrl ? imageSourceUrl : "broken",
            "broken", null, "broken"
        );
        return dialogPrompt
    } else if (className == "DialogPromptSpeakWithGradeModel") {
        const dialogPrompt = new DialogPromptSpeakWithGrade(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            is_hidden,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            imageSource ? imageSource : "",
            imageSourceUrl ? imageSourceUrl : "broken",
            "broken", null, "broken", "broken",
            [],
        );
        return dialogPrompt
    }
    throw new Error("Unknown dialog prompt class " + className);
}
