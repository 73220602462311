import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DialogPlanLlmPrompt, DialogPlanLlmPromptApi, DialogPlanLlmPromptImageSelectSpeak } from "../../api/DialogPlanLlmPromptApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPlanLlmPromptImageSelectSpeakFormProps {
    dialogPlanLlmPromptId?: number;
    dialogPlanLlmPrompt?: DialogPlanLlmPromptImageSelectSpeak;
    setDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
    isRegenerating?: boolean;

    id: number;
    dialogPlanId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string;
    noResponse: string;
    // imageSource: string;
}

export const DialogPlanLlmPromptImageSelectSpeakForm: React.FC<DialogPlanLlmPromptImageSelectSpeakFormProps> = ({
    dialogPlanLlmPromptId, dialogPlanLlmPrompt, setDialogPlanLlmPrompt, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt, isRegenerating,
    id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState<string>('');
    const [vocabularyWordList, setVocabularyWordList] = React.useState<string | null>('');
    const [vocabularyWordImageList, setVocabularyWordImageList] = React.useState<string | null>('');

    const [studentResponsePrompt, setStudentResponsePrompt] = React.useState<string | null>('');
    const [teacherEnglishPrompt, setTeacherEnglishPrompt] = React.useState<string | null>('');

    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    const handleVocabularyWordListChange = (e: React.ChangeEvent<HTMLInputElement>) => { setVocabularyWordList(e.target.value); };
    const handleVocabularyWordImageListChange = (e: React.ChangeEvent<HTMLInputElement>) => { setVocabularyWordImageList(e.target.value); };
    const handleStudentResponsepPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponsePrompt(e.target.value); };
    const handleTeacherEnglishPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherEnglishPrompt(e.target.value); };
    // const handleDisplayPinyinChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDisplayPinyin(e.target.checked); };

    React.useEffect(() => {
        if (!dialogPlanLlmPromptId && dialogPlanLlmPrompt) {
            setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
        }
    }, [])
    React.useEffect(() => {
        if (dialogPlanLlmPromptId && !dialogPlanLlmPrompt) {
            DialogPlanLlmPromptApi.getById(dialogPlanLlmPromptId)
                .then((response) => {
                    const dialogPlanLlmPrompt = response.data as DialogPlanLlmPromptImageSelectSpeak;
                    setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history, location);
                })
        }
    }, [dialogPlanLlmPromptId])

    function setDialogPlanLlmPromptFields(dialogPlanLlmPrompt: DialogPlanLlmPromptImageSelectSpeak) {
        if (dialogPlanLlmPrompt.class_name == "DialogPlanLlmPromptImageSelectSpeakModel") {
            setTeacherChinesePrompt(dialogPlanLlmPrompt.teacher_chinese_prompt);
            setVocabularyWordList(dialogPlanLlmPrompt.vocabulary_word_list);
            setVocabularyWordImageList(dialogPlanLlmPrompt.vocabulary_word_image_list);
            setStudentResponsePrompt(dialogPlanLlmPrompt.student_response_prompt);
            setTeacherEnglishPrompt(dialogPlanLlmPrompt.teacher_english_prompt);
            // setDisplayPinyin(dialogPlanLlmPrompt.display_pinyin);
        }
    }

    React.useEffect(() => {
        const newDialogPlanLlmPrompt = new DialogPlanLlmPromptImageSelectSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse,
            noResponse,
            // imageSource,
            teacherChinesePrompt,
            vocabularyWordList,
            vocabularyWordImageList,
            studentResponsePrompt,
            teacherEnglishPrompt,
            // displayPinyin
        );
        if (handleLlmPromptChange) {
            handleLlmPromptChange(newDialogPlanLlmPrompt);
        }
        if (setDialogPlanLlmPrompt) {
            setDialogPlanLlmPrompt(newDialogPlanLlmPrompt);
        }
    }, [id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, teacherChinesePrompt,
        vocabularyWordList, vocabularyWordImageList, studentResponsePrompt, teacherEnglishPrompt])

    return (<>
        <Stack spacing={4} sx={{
            width: dialogPlanLlmPrompt ? '100%' : '90%', // Conditionally set width
            margin: '0 auto', pt: 2, pb: 4
        }}>
            <div style={{ width: '100%' }}>
                <TextField
                    label="LLM prompt for Chinese Prompt (optional)"
                    value={teacherChinesePrompt}
                    onChange={handleTeacherChinesePromptChange}
                    fullWidth
                    multiline
                    rows={5}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted
                </Typography>
                {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained"
                            onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "teacher_chinese_prompt")}
                            disabled={isRegenerating}>
                            Regenerate
                            {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                        </Button>
                    </Box>
                </>}
            </div>
            {/* <FormControlLabel
                control={<Checkbox checked={displayPinyin ? displayPinyin : false} onChange={handleDisplayPinyinChange} />}
                label="Display answers with Pinyin"
            /> */}
            <h3>Answer List</h3>
            <TextField
                label="LLM Prompt for Answer List"
                value={vocabularyWordList}
                onChange={handleVocabularyWordListChange}
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "vocabulary_word_list")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            <TextField
                label="LLM Prompt for Answer Image"
                value={vocabularyWordImageList}
                onChange={handleVocabularyWordImageListChange}
                multiline
                rows={5}
            />
            <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                &lt;VocabularyWord/&gt; will be replaced with
                the corresponding correct vocabulary word when generating.
            </Typography>
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "vocabulary_word_image")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            <TextField
                label="LLM Prompt for Student Response Prompt"
                value={studentResponsePrompt}
                onChange={handleStudentResponsepPromptChange}
                multiline
                rows={5}
            />
            <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                &lt;VocabularyWord/&gt; will be replaced with
                the corresponding correct vocabulary word when generating.
            </Typography>
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "student_response_prompt")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            <TextField
                label="LLM Prompt for Teacher English Prompt"
                value={teacherEnglishPrompt}
                onChange={handleTeacherEnglishPromptChange}
                multiline
                rows={5}
            />
            <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                &lt;VocabularyWord/&gt; will be replaced with
                the corresponding correct vocabulary word when generating.
            </Typography>
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "teacher_english_prompt")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
        </Stack>
    </>);
};
