import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface IUserDialogPrompt {
    id: number;
    order_index: number;
    name: string;
    description?: string;
    is_hidden: boolean;
    expanded?: boolean;
}

export type UserDialogPromptSummary = {
    course_id: number;
    lesson_id: number;
    dialog_id: number;
    dialog_prompt_count: number;
    user_dialog_prompt_count: number;
};

export const UserDialogPromptApi = {
    getAll: function () {
        return axiosInstance.request<IUserDialogPrompt[]>({
            method: "GET",
            url: `/user_dialog_prompt`
        });
    },
    getById: function (userDialogPromptId: number) {
        return axiosInstance.request<IUserDialogPrompt>({
            method: "GET",
            url: `/user_dialog_prompt/${userDialogPromptId}`
        });
    },
    completeDialogPrompt: function (dialogPromptId: number) {
        return axiosInstance.request<UserDialogPromptSummary[]>({
            method: "PUT",
            url: `/user_dialog_prompt/completed/${dialogPromptId}`
        });
    },
    getSummary: function () {
        return axiosInstance.request<UserDialogPromptSummary[]>({
            method: "GET",
            url: `/user_dialog_prompt/summary`
        });
    }
}
