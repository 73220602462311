import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Button, Drawer, IconButton, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import jwtDecode from 'jwt-decode';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import iconHome from './asset/icon-home.png';
import iconLesson from './asset/icon-lesson.png';
import iconLogout from './asset/icon-logout.png';
import iconPronounce from './asset/icon-pronounce-blue.png';
import iconSelfStudy from './asset/icon-self-study.png';
import iconSettings from './asset/settings-outline-large-blue.png';
import ErrorMessageContext from './context/ErrorMessageContext';
import IsLoggedInContext from './context/IsLoggedInContext';
import TopMenuIndexContext, { MenuIndex } from './context/TopMenuIndexContext';

export function isJwtLoggedIn() {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
        try {
            const decodedToken: { exp: number } = jwtDecode(jwt);
            const currentTime = Date.now() / 1000;
            return currentTime < decodedToken.exp;
        } catch (error) {
            console.error('Error decoding JWT:', error);
            return false;
        }
    }
    return false;
}

export default function Menu() {

    const { errorMessage } = React.useContext(ErrorMessageContext);
    const { isLoggedIn, setIsLoggedIn } = React.useContext(IsLoggedInContext);
    const { topMenuIndex, setTopMenuIndex } = React.useContext(TopMenuIndexContext);

    const theme = useTheme();
    const [loginLinkText, setLoginLinkText] = React.useState("Login");
    const [menuOpen, setMenuOpen] = React.useState(false);

    const tabContainerRef = useRef<HTMLDivElement>(null);

    type MenuOption = {
        label: string;
        icon: string;
        to: string | { pathname: string; state: { from: { pathname: string } } };
      };

    const [menuOptionList, setMenuOptionList] = React.useState<MenuOption[]>([]);

    const handleTopMenuIndexChange = (event: React.ChangeEvent<{}>, index: number) => {
        setTopMenuIndex(index as MenuIndex); // cast number to MenuIndex enum
    };

    const handleLogoutClick = () => {
        localStorage.removeItem('jwt');
        setIsLoggedIn(false);
    };

    React.useEffect(() => {
        setIsLoggedIn(isJwtLoggedIn())
    }, [])

    React.useEffect(() => {
        const menuOptionList = [
            { label: "Home", icon: iconHome, to: "/" },
            { label: "Lesson", icon: iconLesson, to: (isLoggedIn ? "/lesson" : { pathname: "/login", state: { from: { pathname: "/lesson" } } } ) },
            { label: "Pronunciation", icon: iconPronounce, to: (isLoggedIn ? "/sentence" : { pathname: "/login", state: { from: { pathname: "/sentence" } } } ) },
            { label: "Conversation", icon: iconSelfStudy, to: (isLoggedIn ? "/chat" : { pathname: "/login", state: { from: { pathname: "/chat" } } } ) },
            { label: "Office Hours", icon: iconSelfStudy, to: (isLoggedIn ? "/office_hour" : { pathname: "/login", state: { from: { pathname: "/office_hour" } } } ) }
        ]
        if (isLoggedIn) {
            setLoginLinkText('Logout');
            menuOptionList.push({ label: "Settings", icon: iconSettings, to: "/registration" })
        } else {
            setLoginLinkText('Login');
        }
        setMenuOptionList(menuOptionList)

    }, [isLoggedIn])

    return (
        <AppBar position="static" elevation={0}
            sx={{
                backgroundColor: theme.palette.background.default,
                borderBottom: '1.5px solid rgba(82, 99, 125, 0.2)',
                padding: '16px 64px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>

            {/* Mobile Menu Button */}
            <IconButton onClick={() => setMenuOpen(true)} sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' } }}>
                <MenuIcon />
            </IconButton>

            {/* Tabs */}
            <Stack direction="row" alignItems="center" sx={{ flex: 1, display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                <div ref={tabContainerRef} style={{
                    display: 'flex',
                    overflowX: 'auto',
                    scrollBehavior: 'smooth',
                    whiteSpace: 'nowrap',
                    flex: 1
                }}>
                            <Tabs value={topMenuIndex} onChange={handleTopMenuIndexChange} sx={{ display: 'flex' }}>
                                {menuOptionList.map((menuOption, index) => (
                                    <Tab key={index}
                                        label={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={menuOption.icon} alt={menuOption.label} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                                {menuOption.label}
                                            </div>
                                        }
                                        component={Link}
                                        to={menuOption.to}
                                        sx={{ color: theme.palette.primary.main, fontSize: '16px' }}
                                    />
                                ))}
                            </Tabs>
                </div>
            </Stack>

            {/* Error Message */}
            {errorMessage && errorMessage.length > 0 && (
                <Typography variant="body1" sx={{ color: 'red', marginRight: '16px' }}>{errorMessage}</Typography>
            )}

            {/* Login/Logout Button */}
            <Link to="/login" style={{
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: '16px',
                textDecoration: 'none',
            }}>
                {isLoggedIn ? (
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleLogoutClick}>
                        <img src={iconLogout} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        {loginLinkText}
                    </div>
                ) : (
                    <Button variant="outlined" sx={{
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        borderWidth: 2,
                        '&:hover': {
                            borderColor: theme.palette.primary.main,
                            backgroundColor: 'transparent',
                        },
                        minWidth: '128px',
                        height: '48px'
                    }}>
                        Log In
                    </Button>
                )}
            </Link>

            {/* Mobile Drawer for Collapsible Menu */}
            <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
                <Stack direction="column" sx={{ width: 250, padding: 2 }}>
                    {menuOptionList.map((item, index) => (
                        <Button key={index} component={Link} to={item.to} onClick={() => setMenuOpen(false)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                textTransform: 'none',
                                fontSize: '16px',
                                color: theme.palette.primary.main
                            }}>
                            <img src={item.icon} alt={item.label} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                            {item.label}
                        </Button>
                    ))}
                </Stack>
            </Drawer>
        </AppBar>
    );
}
