import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptTypeSpeak, IDialogPromptSentence } from "../../api/DialogPromptApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPromptTypeSpeakFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    isHidden: boolean;
    yesResponse: string | null;
    imageSource: string | null;
    imageSourceUrl: string | null;
}

export const DialogPromptTypeSpeakForm: React.FC<DialogPromptTypeSpeakFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, yesResponse, imageSource, imageSourceUrl }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState("");
    const [studentResponsePrompt, setStudentResponsePrompt] = React.useState("<Answer/>");
    const [studentResponseList, setStudentResponseList] = React.useState("");
    const [teacherAskStudentToSpeakPrompt, setTeacherAskStudentToSpeakPrompt] = React.useState("");
    const [spokenYesResponse, setSpokenYesResponse] = React.useState("");
    const [spokenNoResponse, setSpokenNoResponse] = React.useState("");
    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    const handleStudentResponsePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponsePrompt(e.target.value); };
    const handleStudentResponseListChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponseList(e.target.value); };
    const handleTeacherAskStudentToSpeakPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherAskStudentToSpeakPrompt(e.target.value); };
    const handleSpokenYesResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setSpokenYesResponse(e.target.value); };
    const handleSpokenNoResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setSpokenNoResponse(e.target.value); };

    React.useEffect(() => {
        // // is oldDialogPromptId in the update queue (currently being saved)?
        // // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
        DialogPromptApi.getById(oldDialogPromptId)
            .then((response) => {
                const oldDialogPrompt = response.data as DialogPromptTypeSpeak;
                setDialogPromptFields(oldDialogPrompt);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptTypeSpeak)
        // }
    }, [oldDialogPromptId])

    function setDialogPromptFields(dialogPrompt: DialogPromptTypeSpeak) {
        if (dialogPrompt.class_name == "DialogPromptTypeSpeakModel") {
            setTeacherChinesePrompt(dialogPrompt.teacher_chinese_prompt ? dialogPrompt.teacher_chinese_prompt : "")
            setStudentResponsePrompt(dialogPrompt.student_response_prompt ? dialogPrompt.student_response_prompt : "")
            setStudentResponseList(dialogPrompt.dialog_prompt_sentence_list
                ? dialogPrompt.dialog_prompt_sentence_list
                    .map(item => item.student_response)
                    .join("\n") // Convert array to newline-separated string
                : ""
            )
            setTeacherAskStudentToSpeakPrompt(dialogPrompt.teacher_ask_student_to_speak_prompt ? dialogPrompt.teacher_ask_student_to_speak_prompt : "")
            setSpokenYesResponse(dialogPrompt.spoken_yes_response ? dialogPrompt.spoken_yes_response : "")
            setSpokenNoResponse(dialogPrompt.spoken_no_response ? dialogPrompt.spoken_no_response : "")
        } else {
            setTeacherChinesePrompt("");
            setStudentResponsePrompt("");
            setStudentResponseList("");
            setTeacherAskStudentToSpeakPrompt("");
            setSpokenYesResponse("");
            setSpokenNoResponse("");
        }
    }

    React.useEffect(() => {
        // Convert newline-separated string to array
        const dialogPromptSentenceList: IDialogPromptSentence[] = studentResponseList.split("\n").map((response: string, index) => ({
            id: -1,
            dialog_prompt_id: -1,
            sentence_id: -1,
            student_response: response,
            order_index: -1
        }));

        const dialogPrompt = new DialogPromptTypeSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            isHidden,
            yesResponse as string | "ERROR",
            imageSource,
            imageSourceUrl,
            teacherChinesePrompt,
            null, // sentence_id is null
            studentResponsePrompt,
            dialogPromptSentenceList,
            teacherAskStudentToSpeakPrompt,
            spokenYesResponse,
            spokenNoResponse
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, yesResponse, imageSource,
        teacherChinesePrompt, studentResponsePrompt, studentResponseList, teacherAskStudentToSpeakPrompt, spokenYesResponse, spokenNoResponse])

    return (
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>

            <TextField
                label="Teacher Chinese Prompt"
                value={teacherChinesePrompt}
                onChange={handleTeacherChinesePromptChange}
            />
            <div>
                <TextField
                    label="Student Chinese Prompt"
                    value={studentResponsePrompt ? studentResponsePrompt : "<Answer/>"}
                    onChange={handleStudentResponsePromptChange}
                    required
                    sx={{ width: '100%' }}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted.<br />
                    The prompt will be skipped and not displayed, if this field is only &lt;Answer/&gt;
                </Typography>
            </div>
            <TextField
                label="Allowed Student Prompt Responses"
                value={studentResponseList}
                onChange={handleStudentResponseListChange}
                multiline
                rows={6}
                sx={{ width: '100%' }}
                required
            />
            <TextField
                label="Teacher Ask Student To Speak Prompt"
                value={teacherAskStudentToSpeakPrompt}
                onChange={handleTeacherAskStudentToSpeakPromptChange}
                required
            />
            <TextField
                label="Student Spoken Well Prompt"
                value={spokenYesResponse}
                onChange={handleSpokenYesResponseChange}
                required
            />
            <TextField
                label="Student Spoken Poorly Prompt"
                value={spokenNoResponse}
                onChange={handleSpokenNoResponseChange}
                required
            />
        </Stack>
    )
}
