import axios from 'axios';
import { REST_HOSTNAME } from '../Config';
import { IDialog } from './DialogApi';
import { DialogPlanLlmPrompt } from './DialogPlanLlmPromptApi';
import { DialogPrompt } from './DialogPromptApi';
import { IDialogPromptConversation } from './DialogPromptConversationApi';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface IDialogPlan {
    id: number;
    name: string;
    dialog_title: string;
    dialog_subtitle?: string;
    expanded?: boolean;
    variable_list?: string[];
}

export interface IGeneratedDialogPlanResponse {
    dialog: IDialog,
    dialog_plan_llm_prompt_list: DialogPlanLlmPrompt[];
    dialog_prompt_list: DialogPrompt[];
    dialog_prompt_conversation_list: (IDialogPromptConversation | null)[];
}

export const DialogPlanApi = {
    getAll: function () {
        return axiosInstance.request<IDialogPlan[]>({
            method: "GET",
            url: `/dialog_plan`
        });
    },
    getById: function (dialogPlanId: number) {
        return axiosInstance.request<IDialogPlan>({
            method: "GET",
            url: `/dialog_plan/${dialogPlanId}`
        });
    },
    create: function (dialogPlan: IDialogPlan) {
        return axiosInstance.request<IDialogPlan>({
            method: "POST",
            url: `/dialog_plan`,
            data: dialogPlan
        });
    },
    update: function (dialogPlan: IDialogPlan) {
        return axiosInstance.request<string>({
            method: "PUT",
            url: `/dialog_plan`,
            data: dialogPlan
        });
    },
    delete: function (dialogPlanId: number) {
        return axiosInstance.request<string>({
            method: "DELETE",
            url: `/dialog_plan/${dialogPlanId}`,
        });
    },
    generate: function (hskLevel: number, dialogPlanId: number, includePrompt: boolean,
        vocabularyWord1?: string, vocabularyWord2?: string, vocabularyWord3?: string, vocabularyWord4?: string, vocabularyWord5?: string,
        customInputMap?: Record<string, string>) {
        return axiosInstance.request<IGeneratedDialogPlanResponse>({
            method: "POST",
            url: `/generated_dialog_plan/${hskLevel}/${dialogPlanId}?include_prompt=${includePrompt}`,
            data: {
                '<HskLevel/>': hskLevel,
                '<VocabularyWord1/>': vocabularyWord1,
                '<VocabularyWord2/>': vocabularyWord2,
                '<VocabularyWord3/>': vocabularyWord3,
                '<VocabularyWord4/>': vocabularyWord4,
                '<VocabularyWord5/>': vocabularyWord5,
                ...(customInputMap || {})
            }
        });
    }
}
