import { Box, Button, CircularProgress, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomSentenceApi } from '../api/CustomSentenceApi';
import FemaleBlackIcon from '../asset/icon-female-black.png';
import FemaleWhiteIcon from '../asset/icon-female-white.png';
import MaleBlackIcon from '../asset/icon-male-black.png';
import MaleWhiteIcon from '../asset/icon-male-white.png';
import SpeakerBlackIcon from '../asset/icon-speaker-black.png';
import CustomSentenceListContext from '../context/CustomSentenceListContext';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';
import SelectedCustomSentenceContext from '../context/SelectedCustomSentenceContext';
import { useKaiTiFontStyles } from '../font/KaiTiFont';
import RecorderControl, { UseRecorder, useRecorder } from './RecorderControl';
import RecordingList from './RecordingList';
import SendButtonTextField from './SendButtonTextField';


export default function RecordAndScore() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const theme = useTheme();

    const { selectedSentence, setSelectedSentence } = React.useContext(SelectedCustomSentenceContext)
    const { customSentenceList, setCustomSentenceList } = React.useContext(CustomSentenceListContext)

    const { recorderState, ...handlers }: UseRecorder = useRecorder(setErrorMessage, history, location);
    const { audio } = recorderState;

    const sentenceRef = useRef<HTMLDivElement>(null)

    const kaiTiFontClass = useKaiTiFontStyles();

    const [publicSentenceScoreDone, setPublicSentenceScoreDone] = React.useState(false);
    const [addErrorMessage, setAddErrorMessage] = React.useState<string | null>(null);
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [addSentenceTextField, setAddSentenceTextField] = React.useState('');

    const queryParams = new URLSearchParams(location.search);
    const sentenceText = queryParams.get('text');

    React.useEffect(() => {
        if (sentenceText) {
            const selectedSentence = { id: -1, text: "AddSentence" }
            setSelectedSentence(selectedSentence)
            setAddSentenceTextField(sentenceText)
        }
    }, [sentenceText])

    // State to track the selected button's value
    const [selectedToggleVoice, setSelectedToggleVoice] = React.useState<string | null>(() => {
        const savedValue = localStorage.getItem('selectedCustomSentenceVoice');
        return savedValue === 'null' ? 'female' : savedValue // default to female
    });

    const handleSelectedToggleVoice = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        setSelectedToggleVoice(newAlignment);
    };

    // Update localStorage whenever selectedValue changes
    React.useEffect(() => {
        // Saving to localStorage
        if (selectedToggleVoice !== null) {
            localStorage.setItem('selectedCustomSentenceVoice', selectedToggleVoice);
        } else {
            localStorage.setItem('selectedCustomSentenceVoice', 'null'); // Store "null" as a string
        }
    }, [selectedToggleVoice]);

    React.useEffect(() => {
        const publicSentenceScoreId = localStorage.getItem('s');
        localStorage.removeItem('s');
        if (publicSentenceScoreId) {
            CustomSentenceApi.createPublic(publicSentenceScoreId)
                .then(response => {
                    if (response.status < 300) {
                        return response.data;
                    }
                    throw new Error('Unable to create sentence statuscode=' + response.status + " " + response.statusText);
                })
                .then(customSentence => {
                    CustomSentenceApi.getAll().then(response => {
                        setCustomSentenceList(response.data)
                    })
                })
                .catch(err => {
                    if (err.response.status == 490) {
                        setAddErrorMessage("Please validate your email before adding sentences. You can validate your email in your profile.");
                    } else if (err.response.status == 491) {
                        setAddErrorMessage(err.response['data']);
                    } else if (err.response.status == 492) {
                        setAddErrorMessage("This user is read only. If you would like to add sentences, please logout and register a new user.");
                    } else {
                        console.error(err);
                        handleErrorMessage(err, setErrorMessage, history, location);
                    }
                })
                .finally(() => {
                    setPublicSentenceScoreDone(true);
                })

        } else {
            setPublicSentenceScoreDone(true);
        }
    }, [])

    React.useEffect(() => {
        if (publicSentenceScoreDone) {
            CustomSentenceApi.getAll()
                .then(response => {
                    if (response.status < 300) {
                        return response.data;
                    }
                    console.error(response)
                    handleErrorMessage(response, setErrorMessage, history, location);
                    return []
                })
                .then(list => setCustomSentenceList(list))
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history, location);
                })
        }
    }, [publicSentenceScoreDone])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitting(true)
        const data = new FormData(event.currentTarget);

        let sentence = data.get("sentence");
        if (sentence == null) {
            sentence = ""
        } else {
            sentence = sentence.toString().trim();
        }

        if (sentence.length === 0) {
            setAddErrorMessage("Sentence is blank");
            setSubmitting(false)
        } else {
            setAddErrorMessage(null);
            CustomSentenceApi.create({ id: 0, text: sentence })
                .then(response => {
                    if (response.status < 300) {
                        return response.data;
                    }
                    throw new Error('Unable to create sentence statuscode=' + response.status + " " + response.statusText);
                })
                .then(customSentence => {
                    CustomSentenceApi.getAll().then(response => {
                        setCustomSentenceList(response.data)
                    })
                    setSelectedSentence(customSentence);
                    setAddSentenceTextField('');
                })
                .catch(err => {
                    if (err.response && err.response.status == 490) {
                        setAddErrorMessage("Please validate your email before adding sentences. You can validate your email in your profile.");
                    } else if (err.response && err.response.status == 491) {
                        setAddErrorMessage(err.response['data'].message);
                    } else if (err.response && err.response.status == 492) {
                        setAddErrorMessage("This user is read only. If you would like to add sentences, please logout and register a new user.");
                    } else {
                        console.error(err);
                        handleErrorMessage(err, setErrorMessage, history, location);
                    }
                })
                .finally(() => {
                    setSubmitting(false)
                })
        }
    };

    const handlePlayAudio = async (id: number) => {
        if (selectedToggleVoice == 'male') {
            handleMaleAudio(id)
        } else {
            handleFemaleAudio(id);
        }
    }
    const handleMaleAudio = async (id: number) => {
        CustomSentenceApi.getMaleAudio(id)
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.play();
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }

    const handleFemaleAudio = async (id: number) => {
        CustomSentenceApi.getFemaleAudio(id)
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting female audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.play();
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }

    return (
        <section>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Aligns children to the start and end of the container
                    alignItems: 'center', // Centers children vertically
                    pl: 8, pr: 8, pt: 2
                }}
            >
                {/* Typography aligned to the left */}
                <Typography variant="h2" sx={{ color: '#002A6E' }}>
                    Check your pronuciation
                </Typography>

                {/* ToggleButtonGroup aligned to the right */}
                <ToggleButtonGroup
                    value={selectedToggleVoice}
                    exclusive
                    onChange={handleSelectedToggleVoice}
                    aria-label="text alignment"
                >
                    <ToggleButton value="male" aria-label="Male" sx={{ minWidth: '128px' }}>
                        {selectedToggleVoice == "male" ? (
                            <img src={MaleWhiteIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginRight: '8px' }} />
                        ) : (
                            <img src={MaleBlackIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginRight: '8px' }} />
                        )}
                        Male
                    </ToggleButton>
                    <ToggleButton value="female" aria-label="Female" sx={{ minWidth: '128px' }}>
                        {selectedToggleVoice == "female" ? (
                            <img src={FemaleWhiteIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginRight: '8px' }} />
                        ) : (
                            <img src={FemaleBlackIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginRight: '8px' }} />
                        )}
                        Female
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            {(selectedSentence.id == -1 && selectedSentence.text == "AddSentence") ? (
                <Box component="form" noValidate onSubmit={handleSubmit}
                    sx={{
                        pl: 4, pr: 4, pb: 4,
                        ml: 4, mr: 4, mb: 4, mt: 2,
                        borderRadius: 8,
                        width: 'calc(100vw - 590)',
                        height: 'calc(100vh - 184px)',
                        backgroundColor: 'white',
                        overflow: 'hidden', // Hide the scrollbar if content doesn't overflow
                        display: 'flex', // Use flexbox
                        alignItems: 'center', // Center vertically
                        flexDirection: 'column',
                        justifyContent: 'center', // Center horizontally              
                    }}>
                    <SendButtonTextField
                        name="sentence"
                        value={addSentenceTextField}
                        disabled={isSubmitting}
                        onChange={(e) => setAddSentenceTextField(e.target.value)}
                        onSubmit={() => { } /* handleSubmit logic here if needed, or handle it through form submission */}
                        onKeyDown={() => { } /* handleSubmit logic here if needed, or handle it through form submission */}
                        required
                        sx={{ width: '80%' }} // Directly control the width here
                    />
                    <Typography variant="h6" sx={{ color: '#002A6E', opacity: 0.3, pb: 4 }}>
                        Adding a sentence takes about 30 seconds
                    </Typography>
                    {isSubmitting && <CircularProgress size={48} sx={{ ml: 2, mt: 4 }} />}
                    {addErrorMessage && (
                        <Typography color="error" style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
                            {addErrorMessage}
                        </Typography>
                    )}
                </Box>
            ) : (selectedSentence.processing_queue && selectedSentence.processing_queue > 0 ? (
                <Box sx={{
                    pl: 4, pr: 4, pb: 4,
                    ml: 4, mr: 4, mb: 4, mt: 2,
                    borderRadius: 8,
                    width: 'calc(100vw - 590)',
                    height: 'calc(100vh - 184px)',
                    backgroundColor: 'white',
                    overflow: 'hidden', // Hide the scrollbar if content doesn't overflow
                    display: 'flex', // Use flexbox
                    alignItems: 'center', // Center vertically
                    justifyContent: 'center', // Center horizontally              
                }}>
                    <Stack spacing={2} alignItems="center" justifyContent="center"
                        sx={{
                            m: 2,
                        }}>
                        <Typography variant="h3" sx={{ color: '#002A6E', opacity: 0.3 }}>
                            {selectedSentence.text}
                        </Typography>
                        <Typography variant="h4" sx={{ color: '#002A6E', opacity: 0.9 }}>
                            Please Wait. This sentence is waiting to be processed.
                        </Typography>
                        <Typography variant="h5" sx={{ color: '#002A6E', opacity: 0.3 }}>
                            Position: {selectedSentence.processing_queue}
                        </Typography>
                    </Stack>
                </Box>
            ) : (selectedSentence.text.length <= 0 ? (
                <Box sx={{
                    pl: 4, pr: 4, pb: 4,
                    ml: 4, mr: 4, mb: 4, mt: 2,
                    borderRadius: 8,
                    width: 'calc(100vw - 590)',
                    height: 'calc(100vh - 184px)',
                    backgroundColor: 'white',
                    overflow: 'hidden', // Hide the scrollbar if content doesn't overflow
                    display: 'flex', // Use flexbox
                    alignItems: 'center', // Center vertically
                    justifyContent: 'center', // Center horizontally              
                }}>
                    <Stack spacing={2} alignItems="center" justifyContent="center"
                        sx={{
                            m: 2,
                        }}>
                        <Typography variant="h2" sx={{ color: '#002A6E', opacity: 0.3 }}>
                            Please select a sentence
                        </Typography>
                    </Stack>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 155px)',
                    pl: 4, pr: 4, pb: 4,
                    mt: 2,
                    gap: 2, // Creates space between the left and right panels
                }}>
                    <Box sx={{
                        display: 'flex',
                        flex: 1, // Take up half of the container
                        gap: 2, // Creates space between the left and right panels
                    }}>
                        {/* Left Panel */}
                        <Box sx={{
                            flex: 1, // Take up half of the top-half space
                            display: 'flex',
                            flexDirection: 'column', // Stack content vertically
                            justifyContent: 'center', // Vertically center content
                            alignItems: 'center', // Horizontally center content
                            backgroundColor: 'white',
                            borderTopLeftRadius: 16, // Round top-left corner with a radius of 16px
                            gap: 2
                        }}>
                            <Typography variant="h4" sx={{ minHeight: '40px' }}>Listen to the sentence</Typography>
                            <Typography align="center" sx={{ minHeight: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: "36px" }}>
                                <div className={kaiTiFontClass.customFont} style={{ textAlign: 'center', width: '100%' }}>
                                    {selectedSentence.text}
                                </div>
                            </Typography>
                            <Button variant="contained" onClick={() => handlePlayAudio(selectedSentence.id)} className="secondaryButton">
                                <img src={SpeakerBlackIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginRight: '16px' }} />
                                Play Audio
                            </Button>
                        </Box>
                        {/* Right Panel */}
                        <Box sx={{
                            flex: 1, // Take up half of the top-half space
                            display: 'flex',
                            flexDirection: 'column', // Stack content vertically
                            justifyContent: 'center', // Vertically center content
                            alignItems: 'center', // Horizontally center content
                            backgroundColor: 'white',
                            borderTopRightRadius: 16, // Round top-left corner with a radius of 16px
                            gap: 2
                        }}>
                            <Typography variant="h4" sx={{ minHeight: '40px' }}>Record yourself</Typography>
                            <Typography align="center"
                                sx={{ minHeight: '75px', lineHeight: '2', maxWidth: '80%' }}
                                variant="h6">
                                Please record yourself speaking the sentence above. For accurate results,
                                please use a quiet room and review the recording for any cutoff words.
                            </Typography>
                            <RecorderControl recorderState={recorderState} handlers={handlers} />
                        </Box>
                    </Box>
                    {/* Bottom Panel */}
                    <Box sx={{
                        flex: 1, // Take up the remaining half of the container
                        backgroundColor: 'white',
                        borderBottomLeftRadius: 16, // Round top-left corner with a radius of 16px
                        borderBottomRightRadius: 16, // Round top-left corner with a radius of 16px
                    }}>
                        <RecordingList audio={audio} selectedSentence={selectedSentence} />
                    </Box>
                </Box>
            )))
            }
        </section >
    );
}
