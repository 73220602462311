import axios from 'axios';
import { REST_HOSTNAME } from '../Config';
import { DialogPrompt } from './DialogPromptApi';
import { IDialogPromptConversation } from './DialogPromptConversationApi';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export type DialogPlanLlmPromptTypeUnion = DialogPlanLlmPrompt & DialogPlanLlmPromptImage;

export class DialogPlanLlmPrompt {
    id: number;
    dialog_plan_id: number;
    order_index: number;
    is_teacher: boolean;
    prompt: string;
    is_note_to_student: boolean;
    class_name: string;
    variable_list?: string[];

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
    ) {
        this.id = id;
        this.dialog_plan_id = dialog_plan_id;
        this.order_index = order_index;
        this.is_teacher = is_teacher;
        this.prompt = prompt;
        this.is_note_to_student = is_note_to_student;
        this.class_name = "DialogPlanLlmPromptModel";
    }
}

export class DialogPlanLlmPromptImage extends DialogPlanLlmPrompt {
    image_src: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        image_src: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptImageModel";
        this.image_src = image_src;
    }
}

export class DialogPlanLlmPromptIntroduceWord extends DialogPlanLlmPrompt {
    new_word: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptIntroduceWordModel"
        this.new_word = new_word;
    }
}


export class DialogPlanLlmPromptListenSpeak extends DialogPlanLlmPrompt {
    new_word: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptListenSpeakModel"
        this.new_word = new_word;
        this.yes_response = yes_response
        this.no_response = no_response
    }
}

export class DialogPlanLlmPromptListenSpeakNoGrade extends DialogPlanLlmPrompt {
    new_word: string;
    yes_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        yes_response: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptListenSpeakNoGradeModel"
        this.new_word = new_word;
        this.yes_response = yes_response
    }
}


export class DialogPlanLlmPromptImageSelectSpeak extends DialogPlanLlmPrompt {
    yes_response: string;
    no_response: string;
    teacher_chinese_prompt: string;
    vocabulary_word_list: string | null;
    vocabulary_word_image_list: string | null;
    student_response_prompt: string | null;
    teacher_english_prompt: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        teacher_chinese_prompt: string,
        vocabulary_word_list: string | null,
        vocabulary_word_image_list: string | null,
        student_response_prompt: string | null,
        teacher_english_prompt: string | null
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student)
        this.class_name = "DialogPlanLlmPromptImageSelectSpeakModel"
        this.yes_response = yes_response
        this.no_response = no_response
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.vocabulary_word_list = vocabulary_word_list
        this.vocabulary_word_image_list = vocabulary_word_image_list
        this.student_response_prompt = student_response_prompt
        this.teacher_english_prompt = teacher_english_prompt
    }
}


export class DialogPlanLlmPromptQuizQuestion extends DialogPlanLlmPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    teacher_chinese_prompt: string;
    correct_vocabulary_word: string | null;
    correct_vocabulary_word_image: string | null;
    incorrect_vocabulary_word_list: string | null;
    incorrect_vocabulary_word_image_list: string | null;
    display_pinyin: boolean | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        teacher_chinese_prompt: string,
        correct_vocabulary_word: string | null,
        correct_vocabulary_word_image: string | null,
        incorrect_vocabulary_word_list: string | null,
        incorrect_vocabulary_word_image_list: string | null,
        display_pinyin: boolean | null
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student)
        this.class_name = "DialogPlanLlmPromptQuizQuestionModel"
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.correct_vocabulary_word = correct_vocabulary_word
        this.correct_vocabulary_word_image = correct_vocabulary_word_image
        this.incorrect_vocabulary_word_list = incorrect_vocabulary_word_list
        this.incorrect_vocabulary_word_image_list = incorrect_vocabulary_word_image_list
        this.display_pinyin = display_pinyin
    }
}


export class DialogPlanLlmPromptQuizQuestionAudio extends DialogPlanLlmPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    teacher_chinese_prompt: string;
    display_word_also: boolean;
    correct_vocabulary_word: string | null;
    correct_vocabulary_word_image: string | null;
    incorrect_vocabulary_word_list: string | null;
    incorrect_vocabulary_word_image_list: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        teacher_chinese_prompt: string,
        display_word_also: boolean,
        correct_vocabulary_word: string | null,
        correct_vocabulary_word_image: string | null,
        incorrect_vocabulary_word_list: string | null,
        incorrect_vocabulary_word_image_list: string | null
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student)
        this.class_name = "DialogPlanLlmPromptQuizQuestionAudioModel"
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.display_word_also = display_word_also
        this.correct_vocabulary_word = correct_vocabulary_word
        this.correct_vocabulary_word_image = correct_vocabulary_word_image
        this.incorrect_vocabulary_word_list = incorrect_vocabulary_word_list
        this.incorrect_vocabulary_word_image_list = incorrect_vocabulary_word_image_list
    }
}

export class DialogPlanLlmPromptQuizWord extends DialogPlanLlmPrompt {
    new_word: string;
    yes_response: string;
    no_response: string;
    wrong_word_list: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        yes_response: string,
        no_response: string,
        wrong_word_list: string | null,
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptQuizWordModel"
        this.new_word = new_word;
        this.yes_response = yes_response;
        this.no_response = no_response;
        this.wrong_word_list = wrong_word_list
    }
}


export class DialogPlanLlmPromptSpeakNoGrade extends DialogPlanLlmPrompt {
    yes_response: string;
    image_src: string | null;
    teacher_chinese_prompt: string | null;
    student_response_prompt: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        image_src: string | null,
        teacher_chinese_prompt: string | null,
        student_response_prompt: string | null,
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptSpeakNoGradeModel"
        this.yes_response = yes_response
        this.image_src = image_src
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.student_response_prompt = student_response_prompt
    }
}


export class DialogPlanLlmPromptSpeakWithGrade extends DialogPlanLlmPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    teacher_chinese_prompt: string | null;
    student_response_prompt: string | null;
    teacher_hint_prompt: string | null;
    dialog_prompt_sentence_list: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        teacher_chinese_prompt: string | null,
        student_response_prompt: string | null,
        teacher_hint_prompt: string | null,
        dialog_prompt_sentence_list: string | null,
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptSpeakWithGradeModel"
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.student_response_prompt = student_response_prompt
        this.teacher_hint_prompt = teacher_hint_prompt
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
    }
}


export class DialogPlanLlmPromptTypeSpeak extends DialogPlanLlmPrompt {
    yes_response: string;
    image_src: string | null;
    teacher_chinese_prompt: string | null;
    student_response_prompt: string | null;
    dialog_prompt_sentence_list: string | null;
    teacher_ask_student_to_speak_prompt: string | null;
    spoken_yes_response: string | null;
    spoken_no_response: string | null;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        image_src: string | null,
        teacher_chinese_prompt: string | null,
        student_response_prompt: string | null,
        dialog_prompt_sentence_list: string | null,
        teacher_ask_student_to_speak_prompt: string | null,
        spoken_yes_response: string | null,
        spoken_no_response: string | null,
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptTypeSpeakModel"
        this.yes_response = yes_response
        this.image_src = image_src
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.student_response_prompt = student_response_prompt
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
        this.teacher_ask_student_to_speak_prompt = teacher_ask_student_to_speak_prompt
        this.spoken_yes_response = spoken_yes_response
        this.spoken_no_response = spoken_no_response
    }
}


export class DialogPlanLlmPromptConversationListen extends DialogPlanLlmPrompt {
    dialog_prompt_conversation: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        dialog_prompt_conversation: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptConversationListenModel"
        this.dialog_prompt_conversation = dialog_prompt_conversation
    }
}


export class DialogPlanLlmPromptConversationListenWithoutCharacter extends DialogPlanLlmPrompt {
    dialog_prompt_conversation: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        dialog_prompt_conversation: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptConversationListenWithoutCharacterModel"
        this.dialog_prompt_conversation = dialog_prompt_conversation
    }
}


export class DialogPlanLlmPromptConversationListenSpeak extends DialogPlanLlmPrompt {
    dialog_prompt_conversation: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptConversationListenSpeakModel"
        this.yes_response = yes_response
        this.no_response = no_response
        this.dialog_prompt_conversation = dialog_prompt_conversation
    }
}


export class DialogPlanLlmPromptConversationReadSpeak extends DialogPlanLlmPrompt {
    dialog_prompt_conversation: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptConversationReadSpeakModel"
        this.yes_response = yes_response
        this.no_response = no_response
        this.dialog_prompt_conversation = dialog_prompt_conversation
    }
}


export class DialogPlanLlmPromptConversationSpeakOnly extends DialogPlanLlmPrompt {
    dialog_prompt_conversation: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptConversationSpeakOnlyModel"
        this.yes_response = yes_response
        this.no_response = no_response
        this.dialog_prompt_conversation = dialog_prompt_conversation
    }
}


export class DialogPlanLlmPromptConversationReadWithBlankSpeak extends DialogPlanLlmPrompt {
    dialog_prompt_conversation: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptConversationReadWithBlankSpeakModel"
        this.yes_response = yes_response
        this.no_response = no_response
        this.dialog_prompt_conversation = dialog_prompt_conversation
    }
}



export class DialogPlanLlmPromptStartNextLesson extends DialogPlanLlmPrompt {
    yes_button_text: string;
    no_button_text: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_plan_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_button_text: string,
        no_button_text: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_plan_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPlanLlmPromptStartNextLessonModel"
        this.yes_button_text = yes_button_text;
        this.no_button_text = no_button_text;
        this.yes_response = yes_response
        this.no_response = no_response
    }
}


export interface IGeneratedDialogPlanLlmPromptResponse {
    dialog_plan_llm_prompt: DialogPlanLlmPrompt;
    dialog_prompt: DialogPrompt;
    dialog_prompt_conversation: IDialogPromptConversation
}

export const DialogPlanLlmPromptApi = {
    getAll: function (dialogPlanId?: number) {
        return axiosInstance.request<DialogPlanLlmPrompt[]>({
            method: "GET",
            url: `/dialog_plan_llm_prompt${dialogPlanId ? `?dialog_plan_id=${dialogPlanId}` : ''}`
        });
    },
    getById: function (dialogPlanLlmPromptId: number) {
        return axiosInstance.request<DialogPlanLlmPrompt>({
            method: "GET",
            url: `/dialog_plan_llm_prompt/${dialogPlanLlmPromptId}`
        });
    },
    create: function (dialogPlanLlmPrompt: DialogPlanLlmPrompt) {
        return axiosInstance.request<DialogPlanLlmPrompt>({
            method: "POST",
            url: `/dialog_plan_llm_prompt`,
            data: dialogPlanLlmPrompt
        });
    },
    update: function (dialogPlanLlmPrompt: DialogPlanLlmPrompt) {
        return axiosInstance.request<string>({
            method: "PUT",
            url: `/dialog_plan_llm_prompt`,
            data: dialogPlanLlmPrompt
        });
    },
    delete: function (dialogPlanLlmPromptId: number) {
        return axiosInstance.request<string>({
            method: "DELETE",
            url: `/dialog_plan_llm_prompt/${dialogPlanLlmPromptId}`,
        });
    },
    generateById: function (hskLevel: number, dialogPlanLlmPromptId: number,
        vocabularyWord1?: string, vocabularyWord2?: string, vocabularyWord3?: string, vocabularyWord4?: string, vocabularyWord5?: string,
        customInputMap?: Record<string, string>) {
        return axiosInstance.request<IGeneratedDialogPlanLlmPromptResponse>({
            method: "POST",
            url: `/generated_dialog_plan_llm_prompt/${hskLevel}/${dialogPlanLlmPromptId}`,
            data: {
                'dialog_plan_input_dict': {
                    '<HskLevel/>': hskLevel,
                    '<VocabularyWord1/>': vocabularyWord1,
                    '<VocabularyWord2/>': vocabularyWord2,
                    '<VocabularyWord3/>': vocabularyWord3,
                    '<VocabularyWord4/>': vocabularyWord4,
                    '<VocabularyWord5/>': vocabularyWord5,
                    ...(customInputMap || {})
                }
            }
        });
    },
    generate: function (hskLevel: number, dialogPlanLlmPrompt: DialogPlanLlmPrompt, dialogPrompt?: DialogPrompt, fieldName?: string,
        vocabularyWord1?: string, vocabularyWord2?: string, vocabularyWord3?: string, vocabularyWord4?: string, vocabularyWord5?: string,
        customInputMap?: Record<string, string>) {
        // Check if exactly one of dialogPrompt or fieldName is defined
        const isDialogPromptDefined = dialogPrompt !== undefined;
        const isFieldNameDefined = fieldName !== undefined;

        if (isDialogPromptDefined !== isFieldNameDefined) {
            throw new Error("Either both dialogPrompt and fieldName should be defined, or both should be undefined.");
        }
        return axiosInstance.request<IGeneratedDialogPlanLlmPromptResponse>({
            method: "POST",
            url: `/generated_dialog_plan_llm_prompt/${hskLevel}`,
            data: {
                'dialog_plan_input_dict': {
                    '<HskLevel/>': hskLevel,
                    '<VocabularyWord1/>': vocabularyWord1,
                    '<VocabularyWord2/>': vocabularyWord2,
                    '<VocabularyWord3/>': vocabularyWord3,
                    '<VocabularyWord4/>': vocabularyWord4,
                    '<VocabularyWord5/>': vocabularyWord5,
                    ...(customInputMap || {})
                },
                'dialog_plan_llm_prompt': dialogPlanLlmPrompt,
                ...(dialogPrompt && { dialog_prompt: dialogPrompt }), // Conditionally add dialogPrompt if it is defined
                ...(fieldName && { field_name: fieldName }) // Conditionally add fieldName if it is defined
            }
        });
    }
}

export function DialogPlanLlmPromptFactory(
    className: string,
    id: number,
    dialogPlanId: number,
    orderIndex: number,
    isTeacher: boolean,
    prompt: string,
    isNoteToStudent: boolean,
    imageSource?: string | null,
    newWord?: string | null,
    yesButtonText?: string | null,
    noButtonText?: string | null,
    yesResponse?: string | null,
    noResponse?: string | null,
): DialogPlanLlmPrompt {
    if (className == "DialogPlanLlmPromptModel") {
        const dialogPrompt = new DialogPlanLlmPrompt(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptImageModel") {
        const dialogPrompt = new DialogPlanLlmPromptImage(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            imageSource ? imageSource : "broken"
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptIntroduceWordModel") {
        const dialogPrompt = new DialogPlanLlmPromptIntroduceWord(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptListenSpeakModel") {
        const dialogPrompt = new DialogPlanLlmPromptListenSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptListenSpeakNoGradeModel") {
        const dialogPrompt = new DialogPlanLlmPromptListenSpeakNoGrade(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptQuizWordModel") {
        const dialogPrompt = new DialogPlanLlmPromptQuizWord(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "broken",
            noResponse ? noResponse : "broken",
            null
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptImageSelectSpeakModel") {
        const dialogPrompt = new DialogPlanLlmPromptImageSelectSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "",
            "",
            "",
            "",
            "",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptQuizQuestionModel") {
        const dialogPrompt = new DialogPlanLlmPromptQuizQuestion(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "",
            "",
            "",
            "",
            "",
            "",
            null
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptQuizQuestionAudioModel") {
        const dialogPrompt = new DialogPlanLlmPromptQuizQuestionAudio(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "",
            "",
            false,
            "",
            "",
            "",
            ""
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptSpeakNoGradeModel") {
        const dialogPrompt = new DialogPlanLlmPromptSpeakNoGrade(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "",
            null, null
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptSpeakWithGradeModel") {
        const dialogPrompt = new DialogPlanLlmPromptSpeakWithGrade(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            null, null, null, null
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptSpeakWithGradeModel") {
        const dialogPrompt = new DialogPlanLlmPromptSpeakWithGrade(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            null, null, null, null
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptTypeSpeakModel") {
        const dialogPrompt = new DialogPlanLlmPromptTypeSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            imageSource ? imageSource : "",
            "broken", "broken",
            "broken",
            "broken",
            "broken", "broken"
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptConversationListenModel") {
        const dialogPrompt = new DialogPlanLlmPromptConversationListen(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptConversationListenWithoutCharacterModel") {
        const dialogPrompt = new DialogPlanLlmPromptConversationListenWithoutCharacter(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptConversationListenSpeakModel") {
        const dialogPrompt = new DialogPlanLlmPromptConversationListenSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptConversationReadSpeakModel") {
        const dialogPrompt = new DialogPlanLlmPromptConversationReadSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptConversationSpeakOnlyModel") {
        const dialogPrompt = new DialogPlanLlmPromptConversationSpeakOnly(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptConversationReadWithBlankSpeakModel") {
        const dialogPrompt = new DialogPlanLlmPromptConversationReadWithBlankSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPlanLlmPromptStartNextLessonModel") {
        const dialogPrompt = new DialogPlanLlmPromptStartNextLesson(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesButtonText ? yesButtonText : "",
            noButtonText ? noButtonText : "",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
        );
        return dialogPrompt
    }
    throw new Error("Unknown dialog plan llm prompt class " + className);
}
