import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface IDialog {
    id: number;
    lesson_id: number;
    order_index: number;
    title: string;
    subtitle?: string;
    is_hidden: boolean;
    expanded?: boolean;
    dialogPromptCount?: number;
    userDialogPromptCount?: number;
}

export const DialogApi = {
    getAll: function (lessonId?: number) {
        return axiosInstance.request<IDialog[]>({
            method: "GET",
            url: `/dialog${lessonId ? `?lesson_id=${lessonId}` : ''}`
        });
    },
    getById: function (dialogId: number) {
        return axiosInstance.request<IDialog>({
            method: "GET",
            url: `/dialog/${dialogId}`
        });
    },
    create: function (dialog: IDialog) {
        return axiosInstance.request<IDialog>({
            method: "POST",
            url: `/dialog`,
            data: dialog
        });
    },
    update: function (dialog: IDialog) {
        return axiosInstance.request<string>({
            method: "PUT",
            url: `/dialog`,
            data: dialog
        });
    },
    delete: function (dialogId: number) {
        return axiosInstance.request<string>({
            method: "DELETE",
            url: `/dialog/${dialogId}`,
        });
    },
}
