import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export enum ChineseTeacher {
    Male = "male",
    Female = "female",
    Female2 = "female2"
}

export enum ScoringLevel {
    Hard = "hard",
    Medium = "medium",
    Easy = "easy",
}

export interface IUser {
    first_name?: string | null;
    last_name?: string | null;
    email?: string;
    current_password?: string | null;
    password?: string | null;
    avatar?: string;
    learn_chinese_character?: boolean | null;
    chinese_teacher?: ChineseTeacher | null;
    read_only_email_validated?: boolean | null;
    only_required_audio?: boolean | null;
    scoring_level?: ScoringLevel | null;
}

export const UserApi = {
    get: function () {
        return axiosInstance.request<IUser>({
            method: "GET",
            url: `/user`
        });
    },
    update: function (user: IUser) {
        return axiosInstance.request<IUser>({
            method: "PUT",
            url: `/user`,
            data: user
        });
    },
    sendValidateEmail: function () {
        return axiosInstance.request<boolean>({
            method: 'GET',
            url: `/send_validate_email`,
        });
    },
    getReferrerList: function () {
        return axiosInstance.request<[]>({
            method: 'GET',
            url: `/referrer_list`,
        });
    },
    getReferrerLink: function () {
        return axiosInstance.request<string>({
            method: 'GET',
            url: `/referrer_link`,
        });
    },
}
