import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Badge, Button, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';

import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';

import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CourseApi, ICourse } from '../api/CourseApi';
import { DialogApi, IDialog } from '../api/DialogApi';
import { DialogPrompt, DialogPromptApi } from '../api/DialogPromptApi';
import { ILesson, LessonApi } from '../api/LessonApi';
import { SentenceApi } from '../api/SentenceApi';
import CourseForm from '../component/CourseForm';
import DialogForm from '../component/DialogForm';
import LessonForm from '../component/LessonForm';
import DialogPromptForm, { getClassNameDescription as getDialogPromptDescription } from '../component/dialogPrompt/DialogPromptForm';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';

export default function CreateLesson() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [courseList, setCourseList] = React.useState<ICourse[]>([]);
    const [selectedCourse, setSelectedCourse] = React.useState<ICourse | null>(null);

    const [lessonList, setLessonList] = React.useState<ILesson[]>([]);
    const [selectedLesson, setSelectedLesson] = React.useState<ILesson | null>(null);

    const [dialogList, setDialogList] = React.useState<IDialog[]>([]);
    const [selectedDialog, setSelectedDialog] = React.useState<IDialog | null>(null);

    const [dialogPromptList, setDialogPromptList] = React.useState<DialogPrompt[]>([]);
    const [selectedDialogPrompt, setSelectedDialogPrompt] = React.useState<DialogPrompt | null>(null);

    const [totalProcessingQueue, setTotalProcessingQueue] = React.useState(0);

    // Use URLSearchParams to parse query parameters
    const [locationLeftNavInitialized, setLocationLeftNavInitialized] = React.useState(false)


    React.useEffect(() => {

        CourseApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to CourseApi.getAll() " + response.status)
            })
            .then(list => {
                setCourseList(list);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        LessonApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to LessonApi.getAll() " + response.status)
            })
            .then(list => {
                setLessonList(list);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        DialogApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogApi.getAll() " + response.status)
            })
            .then(list => {
                setDialogList(list);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        DialogPromptApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getAll() " + response.status)
            })
            .then(list => {
                setDialogPromptList(list)
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })

        const fetchMaxProcessingQueue = () => {
            SentenceApi.getMaxProcessingQueue()
                .then(maxProcessingQueue => {
                    setTotalProcessingQueue(maxProcessingQueue);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history, location);
                });
        };

        // Fetch immediately on page load
        fetchMaxProcessingQueue();

        // Set interval to refresh every 10 seconds
        const intervalId = setInterval(fetchMaxProcessingQueue, 10000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);

    }, []);

    React.useEffect(() => {
        if (!locationLeftNavInitialized && courseList.length > 0 && lessonList.length > 0 && dialogList.length > 0) {
            setLocationLeftNavInitialized(true);
            const searchParams = new URLSearchParams(location.search);
            const dialogIdQueryParameter = Number(searchParams.get("dialogId"));
            if (dialogIdQueryParameter) {
                const dialogQueryParameter = dialogList.find(dialog => dialog.id === dialogIdQueryParameter);
                if (dialogQueryParameter) {
                    setSelectedCourse(null)
                    setSelectedLesson(null)
                    setSelectedDialog(dialogQueryParameter)
                    setSelectedDialogPrompt(null)
                    setDialogList((prevDialogList) =>
                        prevDialogList.map((dialog) =>
                            dialog.id === dialogQueryParameter.id ? { ...dialog, expanded: true } : dialog
                        )
                    );

                    const lessonQueryParameter = lessonList.find(lesson => lesson.id == dialogQueryParameter.lesson_id);
                    if (lessonQueryParameter) {
                        setLessonList((prevLessonList) =>
                            prevLessonList.map((lesson) =>
                                lesson.id === lessonQueryParameter.id ? { ...lesson, expanded: true } : lesson
                            )
                        );
                        setCourseList((prevCourseList) =>
                            prevCourseList.map((course) =>
                                course.id === lessonQueryParameter.course_id ? { ...course, expanded: true } : course
                            )
                        );
                    }
                }
            }
        }
    }, [courseList, lessonList, dialogList])

    /*
        COURSE HANDLERS
     */

    const handleExpandCourse = (courseId: number) => {
        setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
                course.id === courseId ? { ...course, expanded: !course.expanded } : course
            )
        );
        getSentenceProcessingQueueForLesson(courseId);
    };

    const handleAddCourse = async () => {
        const largestOrderIndex = courseList.reduce((maxOrderIndex, course) => {
            return Math.max(maxOrderIndex, course.order_index);
        }, 0);
        const newCourse: ICourse = {
            id: 0,
            order_index: Math.ceil(largestOrderIndex + 1),
            name: 'New Course Name',
            is_hidden: false
        };
        CourseApi.create(newCourse)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return null
            })
            .then(newCourseWithId => {
                setSelectedCourse(newCourseWithId)
                setSelectedLesson(null)
                setSelectedDialog(null)
                setSelectedDialogPrompt(null)
                CourseApi.getAll().then(response => {
                    setCourseList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleCourseMenuClick = (course: ICourse) => {
        setSelectedCourse(course)
        setSelectedLesson(null)
        setSelectedDialog(null)
        setSelectedDialogPrompt(null)
        handleExpandCourse(course.id)
    };

    const handleCourseFormSubmit = async (updatedCourse: ICourse) => {
        CourseApi.update(updatedCourse)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return null
            })
            .then(response => {
                CourseApi.getAll().then(response => {
                    setCourseList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleDeleteCourse = async (courseId: number) => {
        CourseApi.delete(courseId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return []
            })
            .then(response => {
                setSelectedCourse(null)
                CourseApi.getAll().then(response => {
                    setCourseList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }

    /*
        LESSON HANDLERS
     */

    const handleExpandLesson = (lessonId: number) => {
        setLessonList((prevLessonList) =>
            prevLessonList.map((lesson) =>
                lesson.id === lessonId ? { ...lesson, expanded: !lesson.expanded } : lesson
            )
        );
        getSentenceProcessingQueueForDialog(lessonId);
    };

    const handleAddLesson = async (courseId: number) => {
        const largestOrderIndex = lessonList.reduce((maxOrderIndex, lesson) => {
            return Math.max(maxOrderIndex, lesson.order_index);
        }, 0);
        const newLesson: ILesson = {
            id: 0,
            course_id: courseId,
            order_index: Math.ceil(largestOrderIndex + 1),
            name: 'New Lesson Name',
            description: null,
            is_hidden: false
        };
        LessonApi.create(newLesson)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return null
            })
            .then(newLessonWithId => {
                setSelectedCourse(null)
                setSelectedLesson(newLessonWithId)
                setSelectedDialog(null)
                setSelectedDialogPrompt(null)
                LessonApi.getAll().then(response => {
                    setLessonList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleLessonMenuClick = (lesson: ILesson) => {
        setSelectedCourse(null)
        setSelectedLesson(lesson)
        setSelectedDialog(null)
        setSelectedDialogPrompt(null)
        handleExpandLesson(lesson.id)
    };


    const handleLessonFormSubmit = async (updatedLesson: ILesson) => {
        LessonApi.update(updatedLesson)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return []
            })
            .then(response => {
                LessonApi.getAll().then(response => {
                    setLessonList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleDeleteLesson = async (lessonId: number) => {
        LessonApi.delete(lessonId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return null
            })
            .then(response => {
                setSelectedLesson(null)
                LessonApi.getAll().then(response => {
                    setLessonList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }

    /*
        DIALOG HANDLERS
     */

    const handleExpandDialog = (dialogId: number) => {
        setDialogList((prevDialogList) =>
            prevDialogList.map((dialog) =>
                dialog.id === dialogId ? { ...dialog, expanded: !dialog.expanded } : dialog
            )
        );
        getSentenceProcessingQueueForDialogPrompt(dialogId);
    };

    const handleAddDialog = async (lessonId: number) => {
        const largestOrderIndex = dialogList.reduce((maxOrderIndex, dialog) => {
            return Math.max(maxOrderIndex, dialog.order_index);
        }, 0);
        const newDialog: IDialog = {
            id: 0,
            lesson_id: lessonId,
            order_index: Math.ceil(largestOrderIndex + 1),
            title: 'New Dialog Title',
            is_hidden: false
        };
        DialogApi.create(newDialog)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return null
            })
            .then(newDialogWithId => {
                setSelectedCourse(null)
                setSelectedLesson(null)
                setSelectedDialog(newDialogWithId)
                setSelectedDialogPrompt(null)
                DialogApi.getAll().then(response => {
                    setDialogList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleDialogMenuClick = (dialog: IDialog) => {
        setSelectedCourse(null)
        setSelectedLesson(null)
        setSelectedDialog(dialog)
        setSelectedDialogPrompt(null)
        handleExpandDialog(dialog.id)
    };

    const handleDialogFormSubmit = async (updatedDialog: IDialog) => {
        DialogApi.update(updatedDialog)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return []
            })
            .then(response => {
                DialogApi.getAll().then(response => {
                    setDialogList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleDeleteDialog = async (dialogId: number) => {
        DialogApi.delete(dialogId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return []
            })
            .then(response => {
                setSelectedDialog(null)
                DialogApi.getAll().then(response => {
                    setDialogList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }

    /*
        DIALOG PROMPT HANDLERS
     */

    const handleAddDialogPrompt = async (dialogId: number) => {
        const largestOrderIndex = dialogPromptList.reduce((maxOrderIndex, dialogPrompt) => {
            return Math.max(maxOrderIndex, dialogPrompt.order_index);
        }, 0);
        const newDialogPrompt: DialogPrompt = {
            id: 0,
            dialog_id: dialogId,
            order_index: Math.ceil(largestOrderIndex + 1),
            is_teacher: true,
            prompt: 'New prompt',
            is_note_to_student: false,
            is_hidden: false,
            class_name: "DialogPromptModel",
            has_user_input: false
        };
        DialogPromptApi.create(newDialogPrompt)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return null
            })
            .then(newDialogPromptWithId => {
                setSelectedCourse(null)
                setSelectedLesson(null)
                setSelectedDialog(null)
                setSelectedDialogPrompt(newDialogPromptWithId)
                DialogPromptApi.getAll().then(response => {
                    setDialogPromptList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleDialogPromptMenuClick = (dialogPrompt: DialogPrompt) => {
        setSelectedCourse(null)
        setSelectedLesson(null)
        setSelectedDialog(null)
        setSelectedDialogPrompt(dialogPrompt)
    };

    const handleDialogPromptFormSubmit = async (updatedDialogPrompt: DialogPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => {
        // const updateId = dialogPromptUpdateIdSequence
        // setDialogPromptUpdateIdSequence((updateId) => updateId + 1);
        // setDialogPromptUpdateQueue((prevQueue) => [...prevQueue, { id: updateId, dialogPrompt: updatedDialogPrompt }])
        // setIsSaving(false)

        DialogPromptApi.update(updatedDialogPrompt)
            .then(responseList => {
                const response = responseList[0]
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
            })
            .then(response => {
                DialogPromptApi.getAll().then(response => {
                    const list = response.data
                    setDialogPromptList(list)
                    const newlyUpdatedDialogPrompt = list.find((dialogPrompt: DialogPrompt) => dialogPrompt.id === updatedDialogPrompt.id);
                    if (newlyUpdatedDialogPrompt) {
                        getSentenceProcessingQueueForDialogPrompt(newlyUpdatedDialogPrompt.dialog_id);
                    }
                    setIsSaving(false)
                })
                    .finally(() => {
                        setIsSaving(false)
                    })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    };

    const handleDeleteDialogPrompt = async (dialogPromptId: number) => {
        DialogPromptApi.delete(dialogPromptId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history, location);
                return []
            })
            .then(response => {
                setSelectedDialogPrompt(null)
                DialogPromptApi.getAll().then(response => {
                    setDialogPromptList(response.data)
                })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
    }

    const [processingQueueStatusForDialogPrompt, setProcessingQueueStatusForDialogPrompt] = React.useState<{ [key: number]: number }>({});
    const [processingQueueStatusForDialog, setProcessingQueueStatusForDialog] = React.useState<{ [key: number]: number }>({});
    const [processingQueueStatusForLesson, setProcessingQueueStatusForLesson] = React.useState<{ [key: number]: number }>({});

    React.useEffect(() => {
        if (selectedDialog) {
            getSentenceProcessingQueueForDialogPrompt(selectedDialog.id)
        }
    }, [selectedDialog]);

    React.useEffect(() => {
        if (selectedLesson) {
            getSentenceProcessingQueueForDialog(selectedLesson.id)
        }
    }, [selectedLesson]);

    React.useEffect(() => {
        if (selectedCourse) {
            getSentenceProcessingQueueForLesson(selectedCourse.id)
        }
    }, [selectedCourse]);

    const getSentenceProcessingQueueForLesson = (courseId: number) => {
        SentenceApi.getMaxProcessingQueueForLesson(courseId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getMaxProcessingQueueForLesson() " + response.status);
            })
            .then(maxProcessingQueue => {
                setProcessingQueueStatusForLesson((prev) => ({
                    ...prev, // Keep existing entries
                    ...maxProcessingQueue, // Add/merge new entries from the response
                }));
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            });
    }
    const getSentenceProcessingQueueForDialog = (lessonId: number) => {
        SentenceApi.getMaxProcessingQueueForDialog(lessonId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getMaxProcessingQueueForDialog() " + response.status);
            })
            .then(maxProcessingQueue => {
                setProcessingQueueStatusForDialog((prev) => ({
                    ...prev, // Keep existing entries
                    ...maxProcessingQueue, // Add/merge new entries from the response
                }));
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            });
    }

    const getSentenceProcessingQueueForDialogPrompt = (dialogId: number) => {
        SentenceApi.getMaxProcessingQueueForDialogPrompt(dialogId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getMaxProcessingQueueForDialogPrompt() " + response.status);
            })
            .then(maxProcessingQueue => {
                setProcessingQueueStatusForDialogPrompt((prev) => ({
                    ...prev, // Keep existing entries
                    ...maxProcessingQueue, // Add/merge new entries from the response
                }));
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            });
    }

    return (
        <Container sx={{ mx: 2, my: 2 }} maxWidth={false}>
            <Grid container justifyContent="center">
                {/* <Grid item sm={12} md={6} alignItems="center" justifyContent="center" sx={{ marginTop: 2 }}> */}
                {/* Left Grid with fixed width */}
                <Grid item sx={{ width: '480px', marginTop: 2 }}>
                    <Stack spacing={2}>
                        {totalProcessingQueue > 0 && <Stack>
                            <Typography>
                                {totalProcessingQueue} sentences to be processed<br />
                                Includes more sentences not shown below
                            </Typography>
                        </Stack>}
                        {courseList.map(course => (<div key={course.id}>
                            <Stack direction="row" alignItems="center">
                                <IconButton onClick={() => handleExpandCourse(course.id)} sx={{ opacity: course.is_hidden ? 0.4 : 1 }}>
                                    {course.expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                                </IconButton>
                                <MenuBookRoundedIcon sx={{
                                    mr: 2,
                                    opacity: course.is_hidden ? 0.4 : 1,
                                    color: selectedCourse && selectedCourse.id === course.id ? '#FFBE19' : 'inherit'
                                }} />
                                <Typography onClick={() => handleCourseMenuClick(course)} sx={{ fontWeight: course.is_hidden ? 200 : 400, opacity: course.is_hidden ? 0.4 : 1 }}>
                                    {course.name}
                                </Typography>
                            </Stack>
                            {course.expanded == true ? (
                                <Stack>
                                    {lessonList
                                        .filter(lesson => lesson.course_id === course.id)
                                        .map(lesson => {
                                            const processingQueueLesson = processingQueueStatusForLesson[lesson.id] || -1;

                                            return (<div key={lesson.id}>
                                                <Stack direction="row" alignItems="center" sx={{ ml: 4 }}>
                                                    <IconButton onClick={() => handleExpandLesson(lesson.id)} sx={{ opacity: lesson.is_hidden ? 0.4 : 1 }}>
                                                        {lesson.expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                                                    </IconButton>
                                                    {processingQueueLesson > 0 ? (
                                                        <Badge badgeContent={processingQueueLesson} max={999} color="secondary"
                                                            sx={{
                                                                '.MuiBadge-badge': {
                                                                    backgroundColor: selectedLesson && selectedLesson.id === lesson.id ? '#FFBE19' : "#1556BF",
                                                                    color: selectedLesson && selectedLesson.id === lesson.id ? "#1E1E1E" : "#FFFFFF"
                                                                }, ml: 2, mr: 3, mt: 0, opacity: lesson.is_hidden ? 0.4 : 1,
                                                            }}>
                                                            {/* Empty div as a placeholder */}
                                                            <div></div>
                                                        </Badge>
                                                    ) : (
                                                        <ArticleRoundedIcon sx={{
                                                            mr: 2,
                                                            opacity: lesson.is_hidden ? 0.4 : 1,
                                                            color: selectedLesson && selectedLesson.id === lesson.id ? '#FFBE19' : 'inherit'
                                                        }} />
                                                    )}
                                                    <Typography onClick={() => handleLessonMenuClick(lesson)} sx={{ fontWeight: lesson.is_hidden ? 200 : 400, opacity: lesson.is_hidden ? 0.4 : 1 }}>
                                                        {lesson.name}
                                                    </Typography>
                                                </Stack>
                                                {lesson.expanded == true ? (
                                                    <Stack>
                                                        {dialogList
                                                            .filter(dialog => dialog.lesson_id === lesson.id)
                                                            .map(dialog => {
                                                                const processingQueueDialog = processingQueueStatusForDialog[dialog.id] || -1;
                                                                return (<div key={dialog.id} >
                                                                    <Stack direction="row" alignItems="center" sx={{ ml: 8 }}>
                                                                        <IconButton onClick={() => handleExpandDialog(dialog.id)} sx={{ opacity: dialog.is_hidden ? 0.4 : 1 }}>
                                                                            {dialog.expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                                                                        </IconButton>
                                                                        {processingQueueDialog > 0 ? (
                                                                            <Badge badgeContent={processingQueueDialog} max={999} color="secondary"
                                                                                sx={{
                                                                                    '.MuiBadge-badge': {
                                                                                        backgroundColor: selectedDialog && selectedDialog.id === dialog.id ? '#FFBE19' : "#1556BF",
                                                                                        color: selectedDialog && selectedDialog.id === dialog.id ? "#1E1E1E" : "#FFFFFF"
                                                                                    }, ml: 2, mr: 3, mt: 0, opacity: dialog.is_hidden ? 0.4 : 1,
                                                                                }}>
                                                                                {/* Empty div as a placeholder */}
                                                                                <div></div>
                                                                            </Badge>

                                                                        ) : (
                                                                            <QuestionAnswerRoundedIcon sx={{
                                                                                mr: 2,
                                                                                opacity: dialog.is_hidden ? 0.4 : 1,
                                                                                color: selectedDialog && selectedDialog.id === dialog.id ? '#FFBE19' : 'inherit'
                                                                            }} />
                                                                        )}
                                                                        <Stack direction="column" spacing={0}>
                                                                            <Typography onClick={() => handleDialogMenuClick(dialog)} sx={{ fontWeight: dialog.is_hidden ? 200 : 400, opacity: dialog.is_hidden ? 0.4 : 1 }}>
                                                                                {dialog.title}
                                                                            </Typography>
                                                                            <Typography onClick={() => handleDialogMenuClick(dialog)} color="primary" sx={{ fontWeight: dialog.is_hidden ? 200 : 400, opacity: dialog.is_hidden ? 0.3 : 0.5 }}>
                                                                                {dialog.subtitle}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </Stack>
                                                                    {dialog.expanded == true ? (
                                                                        <Stack>
                                                                            {dialogPromptList
                                                                                .filter(dialogPrompt => dialogPrompt.dialog_id === dialog.id)
                                                                                .map(dialogPrompt => {
                                                                                    const processingQueueDialogPrompt = processingQueueStatusForDialogPrompt[dialogPrompt.id] || -1;
                                                                                    // const inUpdateQueue = false;
                                                                                    // const inUpdateQueue = isDialogPromptInQueue(dialogPrompt.id);
                                                                                    return (<div key={dialogPrompt.id}>
                                                                                        <Stack direction="row" alignItems="top" sx={{ ml: 12 }}>
                                                                                            {processingQueueDialogPrompt > 0 ? (
                                                                                                <Badge badgeContent={processingQueueDialogPrompt} max={999} color="secondary"
                                                                                                    sx={{
                                                                                                        '.MuiBadge-badge': {
                                                                                                            backgroundColor: selectedDialogPrompt && selectedDialogPrompt.id === dialogPrompt.id ? '#FFBE19' : "#1556BF",
                                                                                                            color: selectedDialogPrompt && selectedDialogPrompt.id === dialogPrompt.id ? "#1E1E1E" : "#FFFFFF"
                                                                                                        }, ml: 7, mr: 3, mt: 1, opacity: dialogPrompt.is_hidden ? 0.4 : 1,
                                                                                                    }}>
                                                                                                    {/* Empty div as a placeholder */}
                                                                                                    <div></div>
                                                                                                </Badge>
                                                                                            ) : (
                                                                                                <SmsRoundedIcon sx={{
                                                                                                    fontSize: 18, ml: 6, mr: 2, mt: 1,
                                                                                                    opacity: dialogPrompt.is_hidden ? 0.4 : 1,
                                                                                                    color: selectedDialogPrompt && selectedDialogPrompt.id === dialogPrompt.id ? '#FFBE19' : 'inherit'
                                                                                                }} />
                                                                                            )}
                                                                                            <div>
                                                                                                <Typography onClick={() => handleDialogPromptMenuClick(dialogPrompt)} sx={{ fontWeight: dialogPrompt.is_hidden ? 200 : 400, opacity: dialogPrompt.is_hidden ? 0.4 : 1 }}>
                                                                                                    {dialogPrompt.prompt && dialogPrompt.prompt.length > 20
                                                                                                        ? `${dialogPrompt.prompt.substring(0, 30)} ...`
                                                                                                        : dialogPrompt.prompt && dialogPrompt.prompt.length > 0
                                                                                                            ? dialogPrompt.prompt
                                                                                                            : dialogPrompt.class_name}
                                                                                                </Typography>
                                                                                                <Typography onClick={() => handleDialogPromptMenuClick(dialogPrompt)} color="primary" sx={{ fontWeight: dialogPrompt.is_hidden ? 200 : 400, opacity: dialogPrompt.is_hidden ? 0.3 : 0.5 }} >
                                                                                                    {getDialogPromptDescription(dialogPrompt.class_name)}
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </Stack>
                                                                                    </div>)
                                                                                })
                                                                            }
                                                                            <Button variant="outlined" onClick={() => handleAddDialogPrompt(dialog.id)} sx={{ width: 'fit-content', mt: 2, ml: 16 }}>
                                                                                Add Prompt
                                                                            </Button>
                                                                        </Stack>
                                                                    ) : null}
                                                                </div>)
                                                            })
                                                        }
                                                        <Button variant="outlined" onClick={() => handleAddDialog(lesson.id)} sx={{ width: 'fit-content', mt: 2, ml: 12 }}>
                                                            Add Dialog
                                                        </Button>
                                                    </Stack>
                                                ) : null}
                                            </div>)
                                        })
                                    }
                                    <Button variant="outlined" onClick={() => handleAddLesson(course.id)} sx={{ width: 'fit-content', mt: 2, ml: 8 }}>
                                        Add Lesson
                                    </Button>
                                </Stack>
                            ) : null}
                        </div>))}
                        <Button variant="outlined" onClick={handleAddCourse} sx={{ width: 'fit-content', ml: 4 }}>
                            Add Course
                        </Button>
                    </Stack>
                </Grid>
                {/* <Grid item sm={12} md={6} alignItems="center" justifyContent="center" component={Paper} elevation={6}> */}
                {/* Right Grid that takes the remaining space */}
                <Grid item sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }} component={Paper} elevation={6}>
                    {selectedCourse != null ? (
                        <CourseForm courseId={selectedCourse.id} onSubmit={handleCourseFormSubmit} onDelete={handleDeleteCourse} />
                    ) : null}
                    {selectedLesson != null ? (
                        <LessonForm lessonId={selectedLesson.id} onSubmit={handleLessonFormSubmit} onDelete={handleDeleteLesson} />
                    ) : null}
                    {selectedDialog != null ? (
                        <DialogForm dialogId={selectedDialog.id} onSubmit={handleDialogFormSubmit} onDelete={handleDeleteDialog} />
                    ) : null}
                    {selectedDialogPrompt != null ? (
                        <DialogPromptForm dialogPromptId={selectedDialogPrompt.id} onSubmit={handleDialogPromptFormSubmit} onDelete={handleDeleteDialogPrompt} />
                    ) : null}
                </Grid>
            </Grid>
        </Container>
    );
}
