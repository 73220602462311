import { Stack, TextField } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptQuizWord } from "../../api/DialogPromptApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPromptQuizWordFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    isHidden: boolean;
    newWord: string | null;
    yesResponse: string | null;
    noResponse: string | null;
}


export const DialogPromptQuizWordForm: React.FC<DialogPromptQuizWordFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, newWord, yesResponse, noResponse }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [wrongWord1, setWrongWord1] = React.useState("");
    const [wrongWord2, setWrongWord2] = React.useState("");
    const [wrongWord3, setWrongWord3] = React.useState("");
    const [wrongWord4, setWrongWord4] = React.useState("");
    const [wrongWord5, setWrongWord5] = React.useState("");

    const handleWrongWord1Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord1(e.target.value); };
    const handleWrongWord2Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord2(e.target.value); };
    const handleWrongWord3Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord3(e.target.value); };
    const handleWrongWord4Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord4(e.target.value); };
    const handleWrongWord5Change = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWord5(e.target.value); };

    React.useEffect(() => {
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
        DialogPromptApi.getById(oldDialogPromptId)
            .then((response) => {
                const oldDialogPrompt = response.data as DialogPromptQuizWord;
                setDialogPromptFields(oldDialogPrompt);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptQuizWord)
        // }
    }, [oldDialogPromptId])

    function setDialogPromptFields(dialogPrompt: DialogPromptQuizWord) {
        if (dialogPrompt.class_name == "DialogPromptQuizWordModel") {
            setWrongWord1(dialogPrompt.wrong_word_1 ? dialogPrompt.wrong_word_1 : "")
            setWrongWord2(dialogPrompt.wrong_word_2 ? dialogPrompt.wrong_word_2 : "")
            setWrongWord3(dialogPrompt.wrong_word_3 ? dialogPrompt.wrong_word_3 : "")
            setWrongWord4(dialogPrompt.wrong_word_4 ? dialogPrompt.wrong_word_4 : "")
            setWrongWord5(dialogPrompt.wrong_word_5 ? dialogPrompt.wrong_word_5 : "")
        } else {
            setWrongWord1('')
            setWrongWord2('')
            setWrongWord3('')
            setWrongWord4('')
            setWrongWord5('')
        }
    }

    React.useEffect(() => {
        const dialogPrompt = new DialogPromptQuizWord(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            isHidden,
            newWord as string | "ERROR",
            yesResponse as string | "ERROR",
            noResponse as string | "ERROR",
            wrongWord1 as string | "ERROR",
            wrongWord2 as string | "ERROR",
            wrongWord3 as string | "ERROR",
            wrongWord4 as string | "ERROR",
            wrongWord5 as string | "ERROR"
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden,
        newWord, yesResponse, noResponse,
        wrongWord1, wrongWord2, wrongWord3, wrongWord4, wrongWord5])

    return (
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>

            <TextField
                label="Incorrect Word 1"
                value={wrongWord1}
                onChange={handleWrongWord1Change}
                required
            />
            <TextField
                label="Incorrect Word 2"
                value={wrongWord2}
                onChange={handleWrongWord2Change}
            />
            <TextField
                label="Incorrect Word 3"
                value={wrongWord3}
                onChange={handleWrongWord3Change}
            />
            <TextField
                label="Incorrect Word 4"
                value={wrongWord4}
                onChange={handleWrongWord4Change}
            />
            <TextField
                label="Incorrect Word 5"
                value={wrongWord5}
                onChange={handleWrongWord5Change}
            />

        </Stack>
    )
}