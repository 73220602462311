import { Button, Stack } from '@mui/material';
import React from 'react';
import { DialogPromptSelectTeacher } from '../../api/DialogPromptApi';
import ChatBubble from '../ChatBubble';

import { useHistory, useLocation } from 'react-router-dom';
import { ChineseTeacher, IUser, UserApi } from '../../api/UserApi';
import female_avatar_1 from '../../asset/female_avatar_1.jpg';
import female_avatar_2 from '../../asset/female_avatar_2.jpg';
import male_avatar from '../../asset/male_avatar.jpg';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import { TeacherIntroductionFemale, TeacherIntroductionFemale2, TeacherIntroductionMale, getAudioByText } from './DialogPromptComponent';

interface DialogPromptSelectTeacherComponentProps {
    dialogPrompt: DialogPromptSelectTeacher;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

const DialogPromptSelectTeacherComponent: React.FC<DialogPromptSelectTeacherComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings = false }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    // const [response, setResponse] = React.useState<string | null>(null);
    // const [responseAudio, setResponseAudio] = React.useState<Blob | null>(null);

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [maleAudio, setMaleAudio] = React.useState<Blob | null | undefined>(undefined);
    const [femaleAudio, setFemaleAudio] = React.useState<Blob | null | undefined>(undefined);
    const [female2Audio, setFemale2Audio] = React.useState<Blob | null | undefined>(undefined);

    const [isTeacherSelected, setIsTeacherSelected] = React.useState<boolean>(false);


    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);

        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio, isSettings);
        getAudioByText(history, setErrorMessage, TeacherIntroductionMale, setMaleAudio, isSettings, "male")
        getAudioByText(history, setErrorMessage, TeacherIntroductionFemale, setFemaleAudio, isSettings, "female")
        getAudioByText(history, setErrorMessage, TeacherIntroductionFemale2, setFemale2Audio, isSettings, "female2")
    }, []);


    function playTeacherIntroductionAudio(teacher: string) {
        if (teacher == ChineseTeacher.Male && maleAudio) {
            const audio = new Audio(URL.createObjectURL(maleAudio));
            audio.play();
        } else if (teacher == ChineseTeacher.Female && femaleAudio) {
            const audio = new Audio(URL.createObjectURL(femaleAudio));
            audio.play();
        } else if (teacher == ChineseTeacher.Female2 && female2Audio) {
            const audio = new Audio(URL.createObjectURL(female2Audio));
            audio.play();
        } else {
            const storedOnlyRequiredAudio = localStorage.getItem('onlyRequiredAudio');
            const onlyRequiredAudio = storedOnlyRequiredAudio ? JSON.parse(storedOnlyRequiredAudio) : true;
            if (onlyRequiredAudio == false) {
                throw Error("unknown selected teacher name in local storage " + teacher);
            }
        }
    };

    const handleMale = async () => {
        setIsTeacherSelected(true);
        localStorage.setItem('teacher', ChineseTeacher.Male);
        playTeacherIntroductionAudio(ChineseTeacher.Male)
    }

    const handleFemale = async () => {
        setIsTeacherSelected(true);
        localStorage.setItem('teacher', ChineseTeacher.Female);
        playTeacherIntroductionAudio(ChineseTeacher.Female)
    }
    const handleFemale2 = async () => {
        setIsTeacherSelected(true);
        localStorage.setItem('teacher', ChineseTeacher.Female2);
        playTeacherIntroductionAudio(ChineseTeacher.Female2)
    }

    const handleConfirm = () => {
        setDialogPromptComplete(dialogPrompt.id);
        const selectedTeacher = localStorage.getItem('teacher');
        if (selectedTeacher) {
            updateDbTeacher(selectedTeacher as ChineseTeacher)
        }
    };

    const updateDbTeacher = (teacher: ChineseTeacher) => {
        const updatedUser: IUser = {
            chinese_teacher: teacher,
        };
        // Call the update function
        UserApi.update(updatedUser)
            .then(response => {
                // Handle the response
                // console.log('User updated:', response.data);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            });
    }

    React.useEffect(() => {
        if (chatBubbleDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center' }}>
                    <img src={male_avatar} alt="Male" onClick={() => handleMale()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <img src={female_avatar_1} alt="Female 1" onClick={() => handleFemale()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <img src={female_avatar_2} alt="Female 2" onClick={() => handleFemale2()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <Button variant="contained" onClick={handleConfirm} disabled={!isTeacherSelected} style={{ marginLeft: '1em' }}>
                        Confirm
                    </Button>
                </Stack>
            )
        }
    }, [chatBubbleDone, isTeacherSelected]);

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                messageAudio={promptAudio ? promptAudio : null}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {/** this is not changing as the teacher selection is changing */}
            {/* {response == null ? null : (
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={response}
                    messageAudio={responseAudio ? responseAudio : null}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            )} */}
        </Stack>
    );
};

export default DialogPromptSelectTeacherComponent;
