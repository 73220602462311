import { ISentenceScore } from "./api/ScoreApi";

// Individual exports
export enum ScoreGrade {
    Good = "good",
    OK = "ok",
    Bad = "bad"
}

export function scoreColor(scoreGrade: ScoreGrade) {
    if (scoreGrade == ScoreGrade.Good) return "#28A164"
    if (scoreGrade == ScoreGrade.OK) return "#FCCD56"
    return "#F25C5C"
}


export function sentenceScoreAllGood(sentenceScore: ISentenceScore) {
    return sentenceScore.passing_grade == ScoreGrade.Good
}

export function sentenceScoreAllOkAtLeastOk(sentenceScore: ISentenceScore) {
    return sentenceScore.passing_grade == ScoreGrade.OK
}

export function sentenceScoreAnyFailed(sentenceScore: ISentenceScore) {
    return sentenceScore.passing_grade == ScoreGrade.Bad
}

export function hexToRgbaColorString(hex: string, alpha: number): string {
    // Remove the hash at the start if it's there
    hex = hex.replace('#', '');

    // Parse the hex string into RGB values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the RGBA color string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}