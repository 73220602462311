import axios from "axios";
import React, { ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface IErrorMessageContextType {
    errorMessage: string;
    setErrorMessage: (newErrorMessage: string) => void;
};

export const ErrorMessageInitialContext = { errorMessage: "", setErrorMessage: () => null };

const ErrorMessageContext = React.createContext<IErrorMessageContextType>(ErrorMessageInitialContext);

export default ErrorMessageContext;

interface Props {
    children?: ReactNode;
}

export async function handleErrorMessage(error: any, setErrorMessage: (message: string) => void, history: ReturnType<typeof useHistory>, location: ReturnType<typeof useLocation>) {
    console.error(error)
    if (typeof error === "string") {
        return setErrorMessage(error);
    } else if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 401) {
            history.push('/login', { from: location });
        } else if (error.response) {
            const { status, data } = error.response;

            // If the response is a Blob, convert it to text and parse as JSON
            if (data instanceof Blob) {
                try {
                    const text = await data.text(); // Convert Blob to text
                    if (data.type === "application/json") {
                        const json = JSON.parse(text); // Parse text as JSON
                        console.error("Parsed JSON Error Response:", json);
                        // setErrorMessage(json.message || "Unknown error occurred.");
                        setErrorMessage(json.message)
                    } else {
                        // setErrorMessage(json.message || "Unknown error occurred.");
                        setErrorMessage(text)
                    }
                } catch (parseError) {
                    console.error("Failed to parse JSON Error from Blob:", parseError);
                    // setErrorMessage("An error occurred, but the response could not be parsed.");
                    setErrorMessage(error.message)
                }
            }
        } else {
            setErrorMessage(error.message)
        }
    } else {
        throw error
    }
}