import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptSpeakWithGrade, IDialogPromptSentence } from "../../api/DialogPromptApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPromptSpeakWithGradeFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    isHidden: boolean;
    yesResponse: string | null;
    noResponse: string | null;
    imageSource: string | null;
    imageSourceUrl: string | null;
}

export const DialogPromptSpeakWithGradeForm: React.FC<DialogPromptSpeakWithGradeFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, yesResponse, noResponse, imageSource, imageSourceUrl }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState("");
    const [studentResponsePrompt, setStudentResponsePrompt] = React.useState("<Answer/>");
    const [teacherHintPrompt, setTeacherHintPrompt] = React.useState("");
    const [studentResponseList, setStudentResponseList] = React.useState("");
    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    const handleStudentResponsePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponsePrompt(e.target.value); };
    const handleTeacherHintPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherHintPrompt(e.target.value); };
    const handleStudentResponseListChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponseList(e.target.value); };

    React.useEffect(() => {
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
        DialogPromptApi.getById(oldDialogPromptId)
            .then((response) => {
                const oldDialogPrompt = response.data as DialogPromptSpeakWithGrade;
                setDialogPromptFields(oldDialogPrompt);
                // const dialogPromptInQueue = dialogPromptUpdateQueue.some((dialogPrompt) => dialogPrompt.dialogPrompt.id === oldDialogPromptId);
                // setIsSaving(dialogPromptInQueue)
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history, location);
            })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptSpeakWithGrade)
        //     // setIsSaving(true)
        // }
    }, [oldDialogPromptId])

    function setDialogPromptFields(dialogPrompt: DialogPromptSpeakWithGrade) {
        if (dialogPrompt.class_name == "DialogPromptSpeakWithGradeModel") {
            setTeacherChinesePrompt(dialogPrompt.teacher_chinese_prompt ? dialogPrompt.teacher_chinese_prompt : "")
            setStudentResponsePrompt(dialogPrompt.student_response_prompt ? dialogPrompt.student_response_prompt : "")
            setTeacherHintPrompt(dialogPrompt.teacher_hint_prompt ? dialogPrompt.teacher_hint_prompt : "")
            setStudentResponseList(dialogPrompt.dialog_prompt_sentence_list
                ? dialogPrompt.dialog_prompt_sentence_list
                    .map(item => item.student_response)
                    .join("\n") // Convert array to newline-separated string
                : ""
            )
        } else {
            setTeacherChinesePrompt("");
            setStudentResponsePrompt("<Answer/>");
            setTeacherHintPrompt("");
            setStudentResponseList("")
        }
    }

    React.useEffect(() => {
        // Convert newline-separated string to array
        const dialogPromptSentenceList: IDialogPromptSentence[] = studentResponseList.split("\n").map((response: string, index) => ({
            id: -1,
            dialog_prompt_id: -1,
            sentence_id: -1,
            student_response: response,
            order_index: -1
        }));

        const dialogPrompt = new DialogPromptSpeakWithGrade(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            isHidden,
            yesResponse as string | "ERROR",
            noResponse as string | "ERROR",
            imageSource,
            imageSourceUrl,
            teacherChinesePrompt,
            null, // sentence_id is null
            studentResponsePrompt,
            teacherHintPrompt,
            dialogPromptSentenceList
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, isHidden, yesResponse, noResponse, imageSource, imageSourceUrl,
        teacherChinesePrompt, studentResponsePrompt, teacherHintPrompt, studentResponseList])

    return (
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>

            <TextField
                label="Teacher Chinese Prompt"
                value={teacherChinesePrompt}
                onChange={handleTeacherChinesePromptChange}
            />
            <div>
                <TextField
                    label="Student Chinese Prompt"
                    value={studentResponsePrompt ? studentResponsePrompt : "<Answer/>"}
                    onChange={handleStudentResponsePromptChange}
                    required
                    sx={{ width: '100%' }}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted.<br />
                    The prompt will be skipped and not displayed, if this field is only &lt;Answer/&gt;
                </Typography>
            </div>
            <TextField
                label="Teacher Hint Prompt"
                value={teacherHintPrompt}
                onChange={handleTeacherHintPromptChange}
            />
            <TextField
                label="Allowed Student Prompt Responses"
                value={studentResponseList}
                onChange={handleStudentResponseListChange}
                multiline
                rows={6}
                sx={{ width: '100%' }}
                required
            />
        </Stack>
    )
}