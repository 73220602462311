import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Input, Stack, Typography } from '@mui/material';
import React from 'react';
import { DialogPromptSelectAvatar } from '../../api/DialogPromptApi';
import { IUser, UserApi } from '../../api/UserApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';
import { getAudioByText } from './DialogPromptComponent';

import { useHistory, useLocation } from 'react-router-dom';
import female_shilhouette from '../../asset/female_silhouette.png';
import male_shilhouette from '../../asset/male_silhouette.png';

interface DialogPromptSelectAvatarComponentProps {
    dialogPrompt: DialogPromptSelectAvatar;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

const DialogPromptSelectAvatarComponent: React.FC<DialogPromptSelectAvatarComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings = false }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const location = useLocation();        

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);

    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [fileSizeError, setFileSizeError] = React.useState(false);

    const [studentAvatar, setStudentAvatar] = React.useState<string | null>(null);
    const avatarOptions = [male_shilhouette, female_shilhouette];
    const avatarRef = React.useRef<HTMLDivElement>(null);

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const [isUploading, setIsUploading] = React.useState(false);
    const [isConfirming, setIsConfirming] = React.useState(false);
    const [confirmEnabled, setConfirmEnabled] = React.useState(false);

    const handleAvatarClick = (avatar: string) => {
        localStorage.setItem("studentAvatar", avatar);
        setStudentAvatar(avatar);
    };

    const handleFileUpload = () => {
        if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
            setIsUploading(true);
            const file = fileInputRef.current.files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    if (event.target) {
                        const fileData = event.target.result; // This can be a Blob or data URL

                        if (fileData) {
                            const fileSizeInBytes = file.size;
                            const maxSizeInBytes = 3 * 1024 * 1024; // Maximum file size in bytes (e.g., 128k)

                            if (fileSizeInBytes > maxSizeInBytes) {
                                setFileSizeError(true); // Set the error state to display the dialog
                            } else {
                                // Save the file data in local storage
                                const avatarFileData = fileData.toString()
                                localStorage.setItem('studentAvatar', avatarFileData);
                                setStudentAvatar(avatarFileData);
                            }
                        }
                    }
                } finally {
                    setIsUploading(false); // ✅ always runs
                }
            }

            reader.readAsDataURL(file); // Read the file data            
        }
    };

    const handleCloseDialog = () => {
        setFileSizeError(false);
    };

    const handleConfirm = () => {
        setIsConfirming(true); // disable + show spinner

        const selectedAvatar = localStorage.getItem("studentAvatar"); // Get the latest value
        // console.log("Confirm clicked, current avatar:", selectedAvatar); // Debugging

        if (selectedAvatar) {
            // Update user in database
            const updatedUser: IUser = {
                avatar: selectedAvatar,
            };
            // Call the update function
            UserApi.update(updatedUser)
                .then(response => {
                    setDialogPromptComplete(dialogPrompt.id);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history, location);
                });
        }
    };

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio, isSettings)
    }, []);

    React.useEffect(() => {
        if (studentAvatar && studentAvatar.trim().length > 0) {
            setConfirmEnabled(true);
        }
    }, [studentAvatar])

    React.useEffect(() => {
        if (chatBubbleDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {avatarOptions.map((src) => (
                        <Box
                            key={src}
                            component="img"
                            src={src}
                            alt="Avatar"
                            sx={{
                                width: 50,
                                height: 50,
                                borderRadius: "50%",
                                cursor: "pointer",
                                border: studentAvatar === src ? "3px solid blue" : "3px solid transparent",
                                mr: 2
                            }}
                            onClick={() => handleAvatarClick(src)}
                        />
                    ))}

                    <Input
                        type="file"
                        inputRef={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        id="file-upload"
                        inputProps={{ accept: 'image/*' }}
                    />
                    <label htmlFor="file-upload">
                        <Button variant="contained" component="span" disabled={isUploading}>
                            {isUploading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Upload Avatar'
                            )}
                        </Button>
                    </label>
                    <Button variant="contained" onClick={handleConfirm} disabled={(function () {
                        const result = isConfirming || !confirmEnabled;
                        console.log(
                            "[RENDER] isConfirming:",
                            isConfirming,
                            "confirmEnabled:",
                            confirmEnabled,
                            "=> disabled:",
                            result
                        );
                        return result;
                    })()}
                        style={{ marginLeft: '1em' }}>
                        {isConfirming ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Confirm'
                        )}
                    </Button>
                </Stack>
            )
        }
    }, [chatBubbleDone, isConfirming, confirmEnabled]);

    console.log("isConfirming:", isConfirming, "confirmEnabled:", confirmEnabled);
    console.log("disabled evaluated to:", isConfirming || !confirmEnabled);

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                messageAudio={promptAudio ? promptAudio : null}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {studentAvatar == null ? null : (
                <div ref={avatarRef} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img
                        src={studentAvatar || ''}
                        style={{ maxHeight: '4em', maxWidth: '4em' }}
                        onLoad={() => {
                            if (avatarRef.current) {
                                avatarRef.current.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}
                    />
                </div>
            )}
            <Dialog open={fileSizeError} onClose={handleCloseDialog}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Error</Typography></DialogTitle>
                <DialogContent>
                    <Typography sx={{ color: '#8D8D8D' }}>The file size exceeds the maximum allowed size of 3MB.</Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleCloseDialog} sx={{ color: '#787878' }}>OK</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default DialogPromptSelectAvatarComponent;
